import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Scrollbar } from '../index';
import { getRefundHistory } from '../../_actions_';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import { formatDateToTimezone, getColorBalance } from '../../_helpers_';
import { PAGINATE_ARR } from '../../_constants_';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

const RefundHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const balanceHistory = useSelector((state) => state?.payment?.balanceHistory);
  const user = useSelector((state) => state?.authentication?.user);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: 50,
    team_id: user?.team_id?.id
  });

  useEffect(() => {
    if (!balanceHistory) {
      dispatch(getRefundHistory(queryObject));
    }
  }, []);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getRefundHistory({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prev) => ({
      ...prev,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getRefundHistory({ ...queryObject, page: 1, per_page: event.target.value }));
  };

  return (
    <Box>
      <Scrollbar>
        <div className="mt-2" style={{ border: '1px solid #ccc', borderRadius: 6 }}>
          <Paper
            className={classes.root}
            style={{ position: 'relative', borderRadius: '6px 6px 0px 0px' }}
          >
            <TableContainer style={{ maxHeight: '50vh' }} className={classes.table}>
              <Table
                stickyHeader
                className={clsx(
                  classes.dynamic_col,
                  classes.dynamic_sticky,
                  classes.table,
                  'dynamic_col'
                )}
              >
                <TableHead className={classes.head_white}>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Refund ID'} arrow placement="top">
                        <span className="line-clamp-1">Refund ID</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Refund Time'} arrow placement="top">
                        <span className="line-clamp-1">Refund Time</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '9rem' }}
                    >
                      <Tooltip title={'Amount USD'} arrow placement="top">
                        <span className="line-clamp-1">Amount USD</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '15rem' }}
                    >
                      <Tooltip title={'Refund Note'} arrow placement="top">
                        <span className="line-clamp-1"> Refund Note</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '13rem' }}
                    >
                      <Tooltip title={'Operator'} arrow placement="top">
                        <span className="line-clamp-1">Operator</span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balanceHistory?.items?.map((item) => (
                    <TableRow key={item.id} onClick={() => {}}>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {item.id}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {formatDateToTimezone(item.create_date)}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                -
                                {item.amount?.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                  maximumFractionDigits: 2
                                })}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {item.note}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {item.create_user?.email}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {balanceHistory && (
            <TablePagination
              className="list-pagination"
              style={{
                borderTop: '1px solid #D8D8D8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
              component="div"
              count={balanceHistory?.total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={queryObject.page - 1}
              rowsPerPage={queryObject.per_page}
              rowsPerPageOptions={PAGINATE_ARR}
            />
          )}
        </div>
      </Scrollbar>
    </Box>
  );
};

export default RefundHistory;
