export const ERROR = 'ERROR';
// PAYMENT
export const DATA_WALLET = 'DATA_WALLET';
export const DATA_TRANSACTION_HISTORY = 'DATA_TRANSACTION_HISTORY';
export const DATA_ADD_FUND_ADVERTISER = 'DATA_ADD_FUND_ADVERTISER';
export const DATA_WITHDRAW_ADVERTISER = 'DATA_WITHDRAW_ADVERTISER';
export const CREATE_REFUND = 'CREATE_REFUND';
export const DATA_REFUND_HISTORY = 'DATA_REFUND_HISTORY';
export const DATA_TIKTOK_FINANCE = 'DATA_TIKTOK_FINANCE';

// Request tiktok account
export const CREATE_REQUEST_TIKTOK_ACC = 'CREATE_REQUEST_TIKTOK_ACC';
export const LIST_REQUEST = 'LIST_REQUEST';
export const DATA_REQUEST_CREATE = 'DATA_REQUEST_CREATE';
export const LAST_REQUEST_AD = 'LAST_REQUEST_AD';

// Product links
export const LIST_PRODUCT_LINK = 'LIST_PRODUCT_LINK';
export const CREATE_PRODUCT_LINK = 'CREATE_PRODUCT_LINK';
export const EDIT_PRODUCT_LINK = 'EDIT_PRODUCT_LINK';

// User
export const TEAM_OPTIONS = 'TEAM_OPTIONS';

// CLEAR
export const CLEAR_DATA = 'CLEAR_DATA';
