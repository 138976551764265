import React from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { COLOR_WHITE } from '../../_constants_';

const TextareaField = ({
  title,
  placeholder,
  value,
  onChange,
  required = false,
  disabled = false,
  fullWidth = true,
  className = '',
  error,
  rows = 3,
  maxRows = 7
}) => {
  return (
    <FormControl fullWidth={fullWidth} className={`${className}`}>
      {title && (
        <Box display="flex" gap={0.5} alignItems="center">
          <Typography fontWeight={600}>{title}</Typography>
          {required && <Typography sx={{ color: 'red' }}>*</Typography>}
        </Box>
      )}

      <TextField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        variant="outlined"
        disabled={disabled}
        multiline
        rows={rows}
        maxRows={maxRows}
        InputProps={{
          sx: {
            backgroundColor: disabled ? '#ececec' : COLOR_WHITE,
            color: 'black'
          }
        }}
        error={error ? true : false}
        helperText={error ? error : ''}
      />
    </FormControl>
  );
};

export default TextareaField;
