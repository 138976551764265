/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  Button,
  Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomCircularProgress, Scrollbar } from '../index';
import { getListRequest } from '../../_actions_';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { COLOR_WHITE, PAGINATE_ARR, AD_FILTER, STATUS_ENABLED_ACTION } from '../../_constants_';
import ModalAdFund from '../ad-accounts/modal-add-fund';
import ModalWithdraw from '../ad-accounts/modal-withdraw';
import { formatNumberWithCommas, getButtonColor, isManager } from 'src/_helpers_';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

const BalanceAllocation = ({ statusAd }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [actionModal, setActionModal] = useState({ add_fund: false, withdraw: false });
  const [selectedAccount, setSelectedAccount] = useState(null);
  const listRequests = useSelector((state) => state?.adAccount?.listRequests);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state?.authentication?.user);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: 50,
    team_id: user?.team_id?.id,
    status: AD_FILTER[0].value
  });

  useEffect(() => {
    if (statusAd || statusAd === 0) {
      dispatch(getListRequest({ ...queryObject, page: 1, status: statusAd }));
    }
  }, [statusAd]);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getListRequest({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prev) => ({
      ...prev,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getListRequest({ ...queryObject, per_page: event.target.value }));
  };

  const onChangeActionModal = useCallback((key, value) => {
    setActionModal((prev) => ({
      ...prev,
      [key]: value
    }));
    setSelectedAccount(null);
  }, []);

  return (
    <Box>
      <Scrollbar>
        <div className="mt-2" style={{ border: '1px solid #ccc', borderRadius: 6 }}>
          <Paper
            className={classes.root}
            style={{ position: 'relative', borderRadius: '6px 6px 0px 0px' }}
          >
            <TableContainer style={{ maxHeight: '50vh' }} className={classes.table}>
              <Table
                stickyHeader
                className={clsx(
                  classes.dynamic_col,
                  classes.dynamic_sticky,
                  classes.table,
                  'dynamic_col'
                )}
              >
                <TableHead className={classes.head_white}>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Ad account'} arrow placement="top">
                        <span className="line-clamp-1">Ad account</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '14rem' }}
                    >
                      <Tooltip title={'Ad account status'} arrow placement="top">
                        <span className="line-clamp-1">Ad account status</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Ad account balance'} arrow placement="top">
                        <span className="line-clamp-1">Ad account balance</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '8rem' }}
                    >
                      <Tooltip title={'Free credits'} arrow placement="top">
                        <span className="line-clamp-1">Free credits</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '8rem' }}
                    >
                      <Tooltip title={'Discounts'} arrow placement="top">
                        <span className="line-clamp-1">Discounts</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Action'} arrow placement="top">
                        <span className="line-clamp-1">Action</span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listRequests?.items?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography>{item.name}</Typography>
                                <Typography>ID: {item.advertiser_id}</Typography>
                              </Box>
                            </Box>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Button
                            sx={{
                              cursor: 'unset !important',
                              borderRadius: '12px',
                              fontSize: 14,
                              color: COLOR_WHITE,
                              borderColor: COLOR_WHITE,
                              width: '150px',
                              textTransform: 'none',
                              backgroundColor: `${getButtonColor(item.status_display)}!important`
                            }}
                            variant="outlined"
                          >
                            <Box className={classes.content_inline}>
                              <Box className="text_2">
                                <Box className={classes.text_ellipsis}>{item.status_display}</Box>
                              </Box>
                            </Box>
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div className={classes.text_ellipsis}>
                                {formatNumberWithCommas(item.balance, true)}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">--</span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">--</span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        {isManager(user?.role) && (
                          <div
                            className="fnu-view"
                            style={{ position: 'relative', overflow: 'hidden' }}
                          >
                            {item.status === STATUS_ENABLED_ACTION && (
                              <Box className={classes.content_inline}>
                                <Box display={'flex'} gap={0.5} flexDirection={'column'} p={1}>
                                  <Box>
                                    <Button
                                      variant="outlined"
                                      startIcon={<AddIcon />}
                                      size="small"
                                      onClick={() => {
                                        onChangeActionModal('add_fund', true);
                                        setSelectedAccount(item);
                                      }}
                                    >
                                      Add Fund
                                    </Button>
                                  </Box>
                                  <Box>
                                    <Button
                                      variant="outlined"
                                      startIcon={<RemoveIcon />}
                                      size="small"
                                      color="secondary"
                                      onClick={() => {
                                        onChangeActionModal('withdraw', true);
                                        setSelectedAccount(item);
                                      }}
                                    >
                                      Withdraw
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255,255,255,0.8)'
                }}
              >
                <div className="p-3 d-flex align-items-center justify-content-center">
                  <CustomCircularProgress />
                </div>
              </div>
            )}
          </Paper>
          {listRequests && (
            <TablePagination
              className="list-pagination"
              style={{
                borderTop: '1px solid #D8D8D8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
              component="div"
              count={listRequests?.total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={queryObject.page - 1}
              rowsPerPage={queryObject.per_page}
              rowsPerPageOptions={PAGINATE_ARR}
            />
          )}
        </div>
      </Scrollbar>
      {selectedAccount && (
        <>
          {actionModal.add_fund && (
            <ModalAdFund
              onClose={() => onChangeActionModal('add_fund', false)}
              account={selectedAccount}
            />
          )}
          {actionModal.withdraw && (
            <ModalWithdraw
              onClose={() => onChangeActionModal('withdraw', false)}
              account={selectedAccount}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default BalanceAllocation;
