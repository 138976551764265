import * as t from '../_constants_/redux';

const initialState = {
  dataWallet: null,
  dataRequestTiktokAcc: null,
  dataTransactionHistories: null,
  dataAddFundAdvertiser: null,
  dataWithdrawAdvertiser: null,
  addFundForAdvertiserErrors: null,
  withdrawForAdvertiserErrors: null,
  createRefundSuccess: null,
  createRefundError: null,
  balanceHistory: null,
  dataTiktokFinance: null,
};

export function payment(state = initialState, action) {
  switch (action.type) {
    case t.DATA_WALLET: {
      return {
        ...state,
        dataWallet: action.payload
      };
    }
    case t.DATA_TRANSACTION_HISTORY: {
      return {
        ...state,
        dataTransactionHistories: action.payload
      };
    }
    case t.DATA_ADD_FUND_ADVERTISER: {
      return {
        ...state,
        dataAddFundAdvertiser: action.payload
      };
    }
    case t.DATA_WITHDRAW_ADVERTISER: {
      return {
        ...state,
        dataWithdrawAdvertiser: action.payload
      };
    }
    case t.CREATE_REQUEST_TIKTOK_ACC: {
      return {
        ...state,
        dataRequestTiktokAcc: action.payload
      };
    }
    case t.CREATE_REFUND: {
      return {
        ...state,
        createRefundSuccess: action.payload
      };
    }
    case t.DATA_REFUND_HISTORY: {
      return {
        ...state,
        balanceHistory: action.payload
      };
    }
    case t.DATA_TIKTOK_FINANCE: {
      return {
        ...state,
        dataTiktokFinance: action.payload
      };
    }
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null
      };
    case t.ERROR:
      return {
        ...state,
        [action.payload.key]: action.payload.errors
      };
    default:
      return state;
  }
}
