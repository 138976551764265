import React from "react";
import {
  Dialog, DialogContent, DialogTitle, DialogContentText, Button, DialogActions,
  Checkbox, FormControlLabel
} from '@mui/material'
import { withRouter, toastr } from "src/_helpers_";
import { request } from "src/_services_";
import { userConstants } from "src/_constants_";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles'
import { connect } from "react-redux";

const FormControlLabelStyle = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.8rem',
    fontWeight: 700
  }
})

const Reglement = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(true);
  const open = Boolean(anchorEl);
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleAgreeContract = () => {
    const data = {
      read_term: checked == true && 1 ,
    }
    const { dispatch, user } = props;
    request.put(`/api/v1/user/${user.public_id}`, data).then(res => {
      if (res.data.success) {
        const read_term = res.data.data.read_term
        const user_drebest = Object.assign({}, JSON.parse(localStorage.getItem('user_drebest') || "{}"))
        user_drebest.read_term = read_term
        localStorage.setItem('user_drebest', JSON.stringify(user_drebest))
        dispatch({ type: userConstants.LOGIN_SUCCESS, user: user_drebest })
        navigate(`/profile/${user.public_id}`)
      } else {
        toastr.error(res.data.msg)
      }
    })
    setAnchorEl(false)
  }

  return(
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"SpendAds terms of service"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
          {`Accounts may be suspended if we find violations of our policies or the Terms and Conditions.

            If we detect an egregious violation your account will be suspended immediately and without prior warning. 
            An egregious violation of the Google Ads policies is a violation so serious that it is unlawful or poses 
            significant harm to our users or our digital advertising ecosystem. Egregious violations often reflect that 
            the advertiser's overall business does not adhere to Google Ads policies or that one violation is so severe 
            that we cannot risk future exposure to our users. Given that egregious violations will result in immediate 
            account suspension, upon detection and without prior warning, we limit these to cases when such action is the 
            only effective method to adequately prevent illegal activity and/or significant user harm.

            For other violations that lead to account suspension, we will send you a warning to outline the nature of the 
            policy violation and any remedial action that can be taken, in order to comply. This notification will be sent 
            at least 7 days prior to suspension action.

            For repeat violations of a policy, we issue strikes to your Google ads account and penalties progressively 
            increase with each subsequent strike leading up to account suspension. You will receive a maximum of three strikes 
            per policy on your Google Ads account.  Learn more about enforcement procedures for repeat violations. 

            If we suspend your account, all ads in the suspended account will stop running, and we will no longer accept advertising 
            from you, unless successfully appealed. Any related accounts (for example, accounts using the same payment method) 
            will also be suspended, and any new accounts you create will be automatically suspended. Learn more about suspended accounts.`}
          </div>
          <FormControlLabelStyle
            control={
              <Checkbox checked={checked} onChange={handleCheckBox} name="term" />
            }
            label="I have read and accept the Terms of Service and Privacy Policy"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions >
        { checked == true && 
        <>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleAgreeContract} autoFocus>Agree</Button>
        </> } 
      </DialogActions>
    </Dialog>
  )
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
      user,
  };
}

export default connect(mapStateToProps)(Reglement)