import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SelectSearch from '../elements/select-search';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getDataRequestCreate } from '../../_actions_';
import InputField from '../elements/input';
import { targetMarketOption } from '../../_constants_';
import { createProductLinks, getProductLink } from '../../_actions_/product_link.actions';

const INIT_DATA_FORM = {
  industry: '',
  url: '',
  target_market: ''
};

export default function AddProductLink(props) {
  const { modal, onClose, team_id } = props;
  const [dataForm, setDataForm] = useState(INIT_DATA_FORM);
  const [errorForm, setErrorForm] = useState('');
  const dataCreate = useSelector((state) => state?.adAccount?.dataCreate);
  const dataCreateSuccess = useSelector((state) => state?.productLink?.dataCreateSuccess);
  const createErrors = useSelector((state) => state?.productLink?.createErrors);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataCreate) {
      dispatch(getDataRequestCreate());
    }
  }, []);

  useEffect(() => {
    if (dataCreateSuccess) {
      dispatch(
        getProductLink({
          page: 1,
          limit: 50,
          team_id: team_id
        })
      );
      onClose();
      dispatch(clearData('dataCreateSuccess'));
    }
  }, [dataCreateSuccess]);

  useEffect(() => {
    if (createErrors) {
      setErrorForm(createErrors.msg.errors);
      dispatch(clearData('createErrors'));
    }
  }, [createErrors]);

  const handleSubmit = () => {
    dispatch(createProductLinks({ ...dataForm, team_id: team_id }));
  };

  const handleClose = () => {
    onClose();
    setDataForm(INIT_DATA_FORM);
  };

  const handleChangeDataForm = (key, value) => {
    setDataForm({ ...dataForm, [key]: value });
  };

  const btnSubmit = useMemo(() => {
    const valid = dataForm.industry && dataForm.url && dataForm.target_market;
    return (
      <Button
        variant="contained"
        disabled={!valid}
        onClick={valid ? handleSubmit : null}
        style={{ borderRadius: 4 }}
      >
        Save
      </Button>
    );
  }, [dataForm]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="modal-form-dialog"
      open={modal}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: '16px 32px' }}>
        <Box display="flex" gap={1} justifyContent={'space-between'}>
          <Box>New Product Link</Box>
          <Box
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.5
              },
              mr: -2
            }}
          >
            <CloseIcon />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2} p={1}>
          <SelectSearch
            title={'Product Type'}
            placeholder={'Select a product type'}
            value={dataForm.industry}
            onChange={(newValue) => {
              handleChangeDataForm('industry', newValue ? newValue.value : '');
            }}
            options={dataCreate?.industry ?? []}
            required
            error={errorForm.industry}
          />

          <InputField
            title="Product URLs"
            placeholder="Enter your product url"
            value={dataForm.url}
            onChange={(event) => handleChangeDataForm('url', event.target.value)}
            required
            error={errorForm.url}
          />

          <SelectSearch
            title={'Target Market'}
            placeholder={'Select a target market'}
            value={dataForm.target_market}
            onChange={(newValue) => {
              handleChangeDataForm('target_market', newValue ? newValue.value : '');
            }}
            options={targetMarketOption}
            required
            error={errorForm.target_market}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ mr: 1 }}>
        <Button
          className="mr-2"
          color="secondary"
          variant="outlined"
          onClick={handleClose}
          style={{ borderRadius: 4 }}
        >
          Cancel
        </Button>
        {btnSubmit}
      </DialogActions>
    </Dialog>
  );
}
