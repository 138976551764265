import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InputField from '../elements/input';
import SelectSearch from '../elements/select-search';
import { useDispatch, useSelector } from 'react-redux';
import TextareaField from '../elements/textarea';
import {
  BG_COLOR_BLUE,
  BG_COLOR_GRAY,
  receiveMethodOption,
  refundReasonOptions
} from '../../_constants_';
import { getLabelByCode, getRecipientMethodMessage } from '../../_helpers_';
import { clearData, createRefund } from '../../_actions_';

export default function ModalRefund(props) {
  const { modal, onClose, onOpen, maxAmount } = props;
  const [isDiscontinuationModal, setIsDiscontinuationModal] = useState(false);
  const [modalAction, setModalAction] = useState({
    confirm: false,
    success: false
  });
  const user = useSelector((state) => state?.authentication?.user);
  const INIT_DATA_FORM = {
    amount: '',
    refund_reason: refundReasonOptions[0].value,
    detail_reason: '',
    receive_method: receiveMethodOption[0].value,
    receiving_info: '',
    team_id: user?.team_id?.id
  };
  const createRefundSuccess = useSelector((state) => state?.payment?.createRefundSuccess);
  const createRefundError = useSelector((state) => state?.payment?.createRefundError);
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = useState(INIT_DATA_FORM);
  const [errorForm, setErrorForm] = useState('');

  // Check open close modal refund success or error
  useEffect(() => {
    if (createRefundSuccess) {
      setModalAction({ confirm: false, success: true });
    }
  }, [createRefundSuccess]);
  useEffect(() => {
    if (createRefundError) {
      setErrorForm(createRefundError.msg.errors);
      dispatch(clearData('createRefundError'));
      setModalAction({ confirm: false, success: false });
      onOpen();
    }
  }, [createRefundError]);

  const handleChangeDataForm = (key, value) => {
    setDataForm({ ...dataForm, [key]: value });
  };
  const handleClose = () => {
    onClose();
    setDataForm(INIT_DATA_FORM);
    if (errorForm) setErrorForm('');
  };

  const handNext = () => {
    onClose();
    if (dataForm.refund_reason === refundReasonOptions[0].value) {
      setIsDiscontinuationModal(true);
    } else {
      setModalAction({
        confirm: true,
        success: false
      });
    }
  };

  const btnNext = useMemo(() => {
    const valid =
      dataForm?.amount &&
      dataForm?.refund_reason &&
      dataForm?.receive_method &&
      dataForm.detail_reason &&
      dataForm.receiving_info;

    return (
      <Button
        variant="contained"
        disabled={!valid}
        onClick={valid ? handNext : null}
        style={{ borderRadius: 4 }}
      >
        Next
      </Button>
    );
  }, [dataForm]);

  const handleSubmit = () => {
    if (user?.team_id?.id) dispatch(createRefund(dataForm));
  };

  return (
    <Box>
      {/*--- Form Refund request ---*/}
      <Dialog aria-labelledby="modal-form-dialog" open={modal} maxWidth={'sm'} fullWidth>
        <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: 2 }}>
          <Box>
            <Typography variant={'h4'} pb={1}>
              Refund request form
            </Typography>
            <Typography variant={'body2'}>
              Submit your refund request easily! Follow the instructions below. A customer service
              representative will be in touch if needer. Thank you!
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <InputField
              type={'number'}
              title="Requested amount"
              placeholder="Please enter the refund amount"
              value={dataForm.amount}
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                if (value > 0 && value <= maxAmount) {
                  handleChangeDataForm('amount', value);
                  setErrorForm((pre) => ({ ...pre, amount: '' }));
                } else {
                  handleChangeDataForm('amount', '');
                  setErrorForm((pre) => ({
                    ...pre,
                    amount: `The refund amount must be between $0 and $${maxAmount}.`
                  }));
                }
              }}
              required
              error={errorForm.amount}
              isMoneyInput
            />

            <SelectSearch
              title={'Refund reason'}
              placeholder={'Please choose your reason'}
              value={dataForm.refund_reason}
              onChange={(newValue) => {
                handleChangeDataForm('refund_reason', newValue ? newValue.value : '');
              }}
              options={refundReasonOptions}
              required
              error={errorForm.refund_reason}
            />

            <TextareaField
              required
              title="Detail reason"
              placeholder="Please enter your reason"
              value={dataForm.detail_reason}
              onChange={(event) => {
                handleChangeDataForm('detail_reason', event.target?.value ?? '');
              }}
              error={errorForm.detail_reason}
            />

            <SelectSearch
              title={'Receiving method '}
              placeholder={'Please choose your receiving method'}
              value={dataForm.receive_method}
              onChange={(newValue) => {
                handleChangeDataForm('receive_method', newValue ? newValue.value : '');
              }}
              options={receiveMethodOption}
              required
              error={errorForm.receive_method}
            />

            <TextareaField
              required
              title="Recipient Information"
              placeholder={getRecipientMethodMessage(dataForm.receive_method)}
              value={dataForm.receiving_info}
              onChange={(event) => {
                handleChangeDataForm('receiving_info', event.target?.value ?? '');
              }}
              error={errorForm.receiving_info}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2"
            color="secondary"
            variant="outlined"
            onClick={handleClose}
            style={{ borderRadius: 4 }}
          >
            Close
          </Button>
          {btnNext}
        </DialogActions>
      </Dialog>

      {/*--- Form Permanent service discontinuation ---*/}
      <Dialog
        aria-labelledby="modal-form-discontinuation"
        open={isDiscontinuationModal}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            p={2}
            justifyContent="center"
            textAlign="center"
          >
            <Box
              component="img"
              textAlign={'center'}
              src="/static/refund.png"
              sx={{ margin: 'auto' }}
            />
            <Typography variant={'h5'} p={2}>
              You still have funds remaining in your advertising accounts
            </Typography>
            <Typography variant={'body1'}>
              Your advertisement account still has remaining funds. Do you wish to withdraw the
              balance for a refund before discontinuation of our service permanently?
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, p: 3 }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setModalAction((pre) => ({ ...pre, confirm: true }));
                setIsDiscontinuationModal(false);
              }}
              style={{ flex: 1, p: 2, textTransform: 'none' }}
            >
              Skip
            </Button>
            <Button
              variant="contained"
              style={{ flex: 1, p: 3, textTransform: 'none' }}
              onClick={() => {
                setIsDiscontinuationModal(false);
                handleClose();
              }}
            >
              Go to withdraw money
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/*--- Form confirm ---*/}
      <Dialog
        aria-labelledby="modal-form-confirm"
        open={modalAction.confirm}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant={'h5'}>Refund request form</Typography>
            <Box
              sx={{
                borderRadius: '4px',
                backgroundColor: BG_COLOR_BLUE,
                boxShadow: '2px 0px 8px rgba(0,0,0,0.1)',
                p: 2
              }}
            >
              Here is a summary of your refund request. Please review the information carefully
              before confirming.
              <strong> We will process your refund within 1-5 business days.</strong>
              It will then take an additional
              <strong> 10-15 days for the refund to be reflected in your account.</strong>
            </Box>
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: BG_COLOR_GRAY,
                boxShadow: '2px 0px 8px rgba(0,0,0,0.1)',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}
            >
              <Box>
                <Box>Requested amount</Box>
                <Box sx={{ fontSize: 18, fontWeight: 600 }}>${dataForm.amount}</Box>
              </Box>
              <Box>
                <Box>Refund reason</Box>
                <Box sx={{ fontSize: 18, fontWeight: 600 }}>{dataForm.refund_reason}</Box>
              </Box>
              <Box>
                <Box>Detail reason</Box>
                <Box sx={{ fontSize: 18, fontWeight: 600 }}>{dataForm.detail_reason}</Box>
              </Box>
              <Box>
                <Box>Receiving method</Box>
                <Box sx={{ fontSize: 18, fontWeight: 600 }}>
                  {getLabelByCode(dataForm.receive_method, receiveMethodOption)}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setModalAction({ confirm: false, success: false });
                  onOpen();
                }}
                style={{ borderRadius: 4 }}
              >
                Edit
              </Button>
              <Button variant="contained" onClick={handleSubmit} style={{ borderRadius: 4 }}>
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/*--- Form success ---*/}
      <Dialog
        aria-labelledby="modal-form-success"
        open={modalAction.success}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            p={2}
            justifyContent="center"
            textAlign="center"
          >
            <Box
              component="img"
              textAlign={'center'}
              src="/static/send.png"
              sx={{ margin: 'auto' }}
            />
            <Typography variant={'h5'} p={2}>
              Your refund request has been sent successfully!
            </Typography>
            <Typography variant={'body1'}>
              To ensure a quick and secure refund, we will temporarily hold your funds until the
              process is complete. This request will be processed within the next 1-5 business days.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, p: 3 }}>
            <Button
              variant="contained"
              style={{ p: 3, textTransform: 'none' }}
              onClick={() => {
                setModalAction({ confirm: false, success: false });
                handleClose();
              }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
