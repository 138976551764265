import * as t from '../_constants_/redux';
import { request } from '../_services_';
import { toastr } from '../_helpers_';

export const getProductLink = (query) => async (dispatch) => {
  try {
    const res = await request.get('/api/v1/product_links', query);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.LIST_PRODUCT_LINK,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};

export const createProductLinks = (data) => async (dispatch) => {
  try {
    const res = await request.post_form_data('/api/v1/product_links', data);
    if (res.data.success) {
      dispatch({
        type: t.CREATE_PRODUCT_LINK,
        payload: res.data.data
      });
      toastr.success('Create a product links successful!');
    }
  } catch (err) {
    toastr.error(err);
    dispatch({
      type: t.ERROR,
      payload: {
        key: 'createErrors',
        errors: err.response.data
      }
    });
  }
};

export const updateProductLinks = (id, data) => async (dispatch) => {
  try {
    const res = await request.put_form_data(`/api/v1/product_links/${id}`, data);
    if (res.data.success) {
      dispatch({
        type: t.EDIT_PRODUCT_LINK,
        payload: res.data.data
      });
      toastr.success('Edit a product links successful!');
    }
  } catch (err) {
    toastr.error(err);
    dispatch({
      type: t.ERROR,
      payload: {
        key: 'editErrors',
        errors: err.response.data
      }
    });
  }
};
