import React from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { COLOR_WHITE } from '../../_constants_';
import CustomNumberFormat from '../CustomNumberFormat';

const InputNumberFormatField = ({
  title,
  placeholder,
  value,
  onChange,
  required = false,
  disabled = false,
  fullWidth = true,
  className = '',
  error,
  onKeyDown,
  isMoneyInput = false
}) => {
  return (
    <FormControl fullWidth={fullWidth} className={`${className}`}>
      {title && (
        <Box display="flex" gap={0.5} alignItems="center">
          <Typography fontWeight={600}>{title}</Typography>
          {required && <Typography sx={{ color: 'red' }}>*</Typography>}
        </Box>
      )}

      <TextField
        fullWidth
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        variant="outlined"
        disabled={disabled}
        InputProps={{
          startAdornment: isMoneyInput && (
            <InputAdornment position="start" sx={{ pl: 1, mr: 0 }}>
              $
            </InputAdornment>
          ),
          classes: {
            notchedOutline: 'notchedOutline'
          },
          inputComponent: CustomNumberFormat
        }}
        sx={{
          backgroundColor: disabled ? '#ececec' : COLOR_WHITE,
          color: 'black'
        }}
        error={error ? true : false}
        helperText={error ? error : ''}
      />
    </FormControl>
  );
};

export default InputNumberFormatField;
