import { Box, Link, Typography } from '@mui/material';
import Page from '../../components/Page';
import LayoutStatic from '../../components/static/layout';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

export default function TermsOfServices() {
  return (
    <LayoutStatic>
      <Page title="Spendads | Terms of Services">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
          <Typography variant="h3">Terms of Services</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography>
                This is an agreement between you and SpendAds, including but not limited to SpendAds
                Pte. Ltd. and its affiliates who are operating SpendAds.com (“SpendAds” or “we”).
                Please read these Terms of Services, and the other applicable rules, policies, and
                terms available at the SpendAds.com website. By using the Services, you agree to be
                bound by the terms of this Agreement on behalf of yourself and your company and
                others who use Services under your account. If you do not accept the terms of this
                Agreement, then you may not use the Services. Your use of any of the Services is
                subject to any additional rules, policies, and terms that apply to those Services.
              </Typography>
              <Typography>For the purpose of these Terms of Services:</Typography>
              <Typography>
                “Services” means the provision of TikTok Software, Digital Content, and support and
                other available resources that TikTok and SpendAds provide to you. SpendAds will
                help you to create advertising accounts under SpendAds’s Agency account and provide
                related support that allow you to run such advertising accounts for your business
                purpose.
              </Typography>
              <Typography>“Website” means SpendAds.com</Typography>
              <Typography>
                “TikTok Software” means all software TikTok makes available to you for use and you
                must comply with all policies of TikTok, and SpendAds shall not be responsible for
                any resources when you are using TikTok as advertising channels.
              </Typography>
              <Typography>
                “Digital Content” means digitized content (including third-party content), such as
                videos, music, apps, games, skills, photos, audio, books, newspapers, magazines, and
                related features and functionality.
              </Typography>
              <Typography>
                We reserve the right to update and change the Terms of Services by posting updates
                and changes to the Website. You are advised to check the Terms of Services from time
                to time for any updates or changes that may impact you, and if you do not accept
                such amendments, you must cease using the services. Your continued use of or access
                to the website following the posting of any changes constitutes acceptance of those
                changes.
              </Typography>
              <Typography>
                You must read, agree with and accept all of the terms and conditions contained or
                expressly referenced in these Terms of Services before you access the Website and
                use our Services.
              </Typography>
              <Typography></Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 1 - Use of Services</Typography>
              <Typography>
                To use Services, you must follow with the following requirements and process:
              </Typography>
              <Typography variant="h6">a. Requirements</Typography>
              <Typography>
                By agreeing to these Terms of Services, you represent that you are at least the age
                of majority in your state or province of residence, or that you are the age of
                majority in your state or province of residence and you have given us your consent
                to allow any of your minor dependents to use the Services.
              </Typography>
              <Typography>
                You shall not use our Services for any illegal or unauthorized purpose nor may you,
                in the use of the Services, violate any laws in your jurisdiction (including but not
                limited to copyright laws).
              </Typography>
              <Typography>
                You must not transmit any malwares or viruses or any code of a destructive nature.
              </Typography>
              <Typography>
                Website does not contain discrimination content on the basis of age, gender, race,
                ethnicity, nationality, religion, sexual orientation, or any other protected status.
              </Typography>

              <Typography variant="h6">b. Our working process to use Services</Typography>
              <Typography>
                Step 1. You must have a TikTok account first by registering at the website:{' '}
                <a href="https://www.tiktok.com/signup ">https://www.tiktok.com/signup </a>
              </Typography>
              <Typography>
                Step 2. SpendAds will create advertising accounts under SpendAds’s Agency account,
                and then SpendAds will link your TikTok Account to such advertising accounts. You
                may be allowed to have more than one advertising accounts.
              </Typography>
              <Typography>
                Step 3. To use advertising accounts, you must transfer the advertising budget to our
                Payment Account, and then we shall top-up the advertising budget to your advertising
                accounts.
              </Typography>
              <Typography>
                Step 4. You can use advertising accounts for your business purpose as long as your
                activities will comply with the Terms of Services, TikTok’s policies and applicable
                Laws.
              </Typography>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  backgroundColor: '#f9f9f9',
                  margin: 2,
                  borderLeft: '5px solid #4a4a4a',
                  boxShadow: '2px 0px 8px rgba(0,0,0,0.1)',
                  fontStyle: 'italic'
                }}
              >
                <Box display="flex" alignItems="center" mb={2}>
                  <InfoIcon sx={{ mr: 1 }} />
                  <Typography variant="h6" fontWeight="bold">
                    NOTE
                  </Typography>
                </Box>
                <Typography variant="body1" gutterBottom>
                  SpendAds will not be held responsible if customers intentionally violate the
                  following rules:
                </Typography>
                <Typography variant="body2" component="div" gutterBottom textTransform={'italic'}>
                  - Deploying products or services without obtaining prior approval from SpendAds.
                  <br />
                  - Running prohibited products, such as weight loss, endocrine, sexual health,
                  breast augmentation, MLM, financial, gambling, etc.
                  <br />
                  - Advertisers engaging in spam actor/bad actor activities.
                  <br />
                  - Altering landing page content, running landing pages featuring individuals
                  wearing badges, doctors, third-party logos, or having redirect links that violate
                  unauthorized product guidelines.
                  <br />
                  - Users associated with ad accounts that have a history of policy violations,
                  engage in system evasion, or intentionally fail to pay advertising expenses will
                  have their accounts suspended without refunds.
                  <br />- Violating TikTok's policies:{' '}
                  <Link
                    href="https://ads.tiktok.com/help/article/advertising-on-tiktok-first-things-to-note?lang=en#anchor-0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://ads.tiktok.com/help/article/advertising-on-tiktok-first-things-to-note?lang=en#anchor-0
                  </Link>
                </Typography>
              </Box>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 2 - General Conditions</Typography>
              <Typography>
                We reserve the right to refuse to anyone for any reason at any time.
              </Typography>
              <Typography>
                By using the Services, you agree, without limitation or qualification, to be bound
                by, and to comply with, TikTok’s policies and these Terms of Services and any other
                posted guidelines or rules applicable to any website where this Terms of Services is
                found. We may make improvements and/or changes to the Website at any time. Although
                we attempt to periodically update information on the Website, the information,
                materials and services provided on or through the Website may occasionally be
                inaccurate, incomplete or out of date. We do not have a duty to update information
                contained in the Website, and we will not be liable for any failure to update such
                information.
              </Typography>
              <Typography>
                We understand that your TikTok account and all activities of the advertising
                accounts, including but not limited to spending advertising budget, content, photo,
                video, music and products, are owned and managed by you, thus you must be solely
                responsible for all activities happening to your account and advertising accounts.
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 3 - Right and Responsibility</Typography>
              <Typography variant="h6">a. SpendAds’s right and responsibility.</Typography>
              <Typography>i. Receive service fee full as mentioned in Payment Policy.</Typography>
              <Typography>
                ii. Support you to handle all issues regarding to provided advertising accounts.
              </Typography>
              <Typography>
                iii. Suspend or close any advertising accounts in case you do not comply with
                TikTok’s policies and violate any terms as listed down in the Website. Any signs of
                violating TikTok’s policies include but not limited to rejection of campaign or
                receiving of warning letters from TikTok or advertising accounts are locked
                (TikTok’s policies as specified in this link{' '}
                <a href="https://ads.tiktok.com/help/article?aid=9550">
                  https://ads.tiktok.com/help/article?aid=9550
                </a>
                ). In case TikTok or SpendAds give you a time period to adjust any issues incurred
                by your mistakes, and if you still fail to comply with it, SpendAds has the right to
                stop providing Services to you.
              </Typography>
              <Typography>
                iv. Top-up to Advertising Accounts within 6 working hours when SpendAds receive
                advertising budget.
              </Typography>
              <Typography>
                v. Consult and support you to set up TikTok Account and advertising accounts.
              </Typography>
              <Typography>vi. Update trending songs in market</Typography>
              <Typography>vii. Active beta function to you.</Typography>
              <Typography>
                viii. Review campaigns and appeal campaigns if they meet appealed requirements.
              </Typography>
              <Typography>
                ix. Refund the balance in advertising accounts to you within 2 business days if you
                stop using advertising accounts. (please visit Payment Policy and Refund Policy to
                know about refunding the balance).
              </Typography>
              <Typography>
                x. Transfer balances between advertising accounts in accordance with TikTok’s
                policy.
              </Typography>
              <Typography>
                xi. Keep confidential information in accordance with the TikTok’s policy and in
                accordance with the applicable Laws.
              </Typography>
              <Typography>
                xii. SpendAds has the right to disable and remove the ads in case of violating
                SpendAds and TikTok Ad Policy.
              </Typography>
              <Typography>
                xiii. If you intentionally create multiple accounts to profit from the trial period
                on the system, SpendAds reserves the right to block all violating accounts without
                prior notice.
              </Typography>
              <Typography>
                xiv. SpendAds has the right to revoke inactive accounts that have had no spending
                activity for 30 days.
              </Typography>

              <Typography variant="h6">b. Your right and responsibility</Typography>
              <Typography>i. Be provided advertising accounts by SpendAds.</Typography>
              <Typography>
                ii. Be received all supports from SpendAds when you use Services, including but not
                limited to top up to advertising accounts, active functions and other support to
                manage advertising accounts stably.
              </Typography>
              <Typography>
                iii. Be refunded the balance if stopping to use advertising accounts.
              </Typography>
              <Typography>
                iv. Comply with TikTok’s policies ( https://ads.TikTok.com/help/article?aid=9552 and
                https://ads.TikTok.com/help/article?aid=9550 ) and all terms as set out in the
                Website
              </Typography>
              <Typography>
                v. Transfer advertising budget and service fee to SpendAds’s payment account to
                top-up advertising accounts.
              </Typography>
              <Typography>
                vi. Shall not share or transfer or disclose the provided TikTok Account and
                advertising accounts information for any purpose to any third party, and you must be
                solely responsible for your activities happening with your accounts and other
                accounts managed by SpendAds because of your fault.
              </Typography>
              <Typography>
                vii. Keep confidential information in accordance with the TikTok’s policy and the
                applicable Laws.
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 4 - Termination</Typography>
              <Typography>
                Your rights under this Agreement will automatically be terminated without notice if
                you fail to comply with any of its terms. In case of such termination, SpendAds may
                immediately revoke your access to the advertising accounts. For this circumstance,
                in accordance with case by case, you may not receive any balance from SpendAds.
              </Typography>
              <Typography>
                In case you request to stop using one or all advertising accounts,the remaining
                balance shall be refunded to you or transferred to active advertising accounts
                within 2 business days if there are no issues incurred from your activities.
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 5 - Dispute</Typography>
              <Typography>
                Any dispute or claim arising from or relating to this Agreement is subject to the
                dispute resolution, governing law, disclaimer of warranties, limitation of
                liability, and all other terms in the Website.
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">
                Section 6 - Disclaimer of warranties and limitation of liability
              </Typography>
              <Typography>
                Disclaimer of Warranties and Limitation of Liability. All products or resources are
                developed and manufactured by TikTok and third parties , and we have no
                responsibility or liability for any aspect of your activities except our providing
                Services. Without limiting the Disclaimer of Warranties and Limitation of Liability
                terms in the Website, unless otherwise required by applicable law, in no event will
                our or our licensors' aggregate liability with respect to any claim arising from or
                related to this Agreement or your use of the our Services exceed fifty dollars
                ($50.00).
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 7 - Governing law</Typography>
              <Typography>
                These Terms of Services and any separate agreements whereby we provide you Services
                shall be governed by and construed in accordance with the laws of Singapore.
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 8 - Changes to terms of service</Typography>
              <Typography>
                You can review the most current version of the Terms of Services at any time at this
                page.
              </Typography>
              <Typography>
                We reserve the right, at our sole discretion, to update, change or replace any part
                of these Terms of Services by posting updates and changes to our Website. It is your
                responsibility to check our website periodically for changes. Your continued use of
                or access to our website or the Service following the posting of any changes to
                these Terms of Service constitutes acceptance of those changes."
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 9 - Personal database management</Typography>
              <Typography>
                Please be aware that for accounts created before Jan 17, 2023, your ad account will
                be recalled after 3 days if there are no transactions after the 14-day trial period
                has expired. After that, If you want to keep using SpendAds's services, please sign
                in to SpendAds and top up your account.
              </Typography>
              <Typography>Please visit Privacy Policy to be updated our policy</Typography>
            </Box>

            <Box display={'flex'} flexDirection="column" gap={1.5}>
              <Typography variant="h4">Section 11 - Contact information</Typography>
              <Typography>
                For help with the Services or resolving other related issues, please contact
                Customer Service at email:{' '}
                <a href="mailto:support@SpendAds.com">support@SpendAds.com</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Page>
    </LayoutStatic>
  );
}
