import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { CustomNumberFormat, ModalForm, ResourceList, DrawerForm } from '../../../components';
import { fields, formFields } from './constants';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';

class FUDList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  getFeeOfPack = (team, _package) => {
    const type_acc = team.type_acc
    if (!type_acc) {
      return 0
    }
    const total_topup = _package.total_topup_30days
    const fee_percent = _package[type_acc] || 0
    const fee = total_topup * fee_percent / 100
    return fee
  }

  newFUD = (ctx) => {
    const schema = {
      level: {
        presence: {message: '^Required'},
      },
    }
    if (this.props.user?.admin || this.props.user?.is_owner) {
      schema['team_id'] = {presence: {message: '^Required'}}
    }
    ModalForm.instance.current.openForm({
      title: "New",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Team ID <span style={{ color: 'red' }}>*</span>
            </Box>
            {(this.props.user?.admin || this.props.user.is_owner) ? 
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/teams', {search: inputValue}).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({id: e.team_id, name: `Team_${e.team_id}`, type_acc: e.type_acc}) ))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name={"team_id"}
                onChange={(value) => {
                  var e = {
                    target: {
                      name: "team_id",
                      value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"Team"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData?.values.team_id || null}
                styles={submitData.errors?.team_id ? errorStyles : customStyles}
              />
              : 
              <TextField
                disabled
                fullWidth
                margin='dense'
                name="email"
                value={`Team_${this.props.user.team_id?.team_id}`}
                variant="outlined"
                type="email"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
              />
            }
            {submitData.errors.team_id && <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>{submitData.errors.team_id[0]}</span>}
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Team_Seller Username
            </Box>
            {(this.props.user?.admin || this.props.user.is_owner) ? 
              <TextField 
                fullWidth
                margin='dense'
                disabled
                name="role"
                value={submitData.values.team_id ? `Team_${submitData.values.team_id?.id} ${this.props.user.user_name}` : ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
              />
              : 
              <TextField 
                fullWidth
                margin='dense'
                disabled
                name="role"
                value={`Team_${this.props.user.team_id?.team_id} ${this.props.user.user_name}`}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
              />
            }
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Type Acc
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              disabled
              name="type_acc"
              value={submitData.values.team_id ? submitData.values.team_id.type_acc : ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Level <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/rate_ex', {search: inputValue, no_limit: 1, hidden: 0}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"level"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "level",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Level"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ package_id }) => package_id}
              valueKey={"package_id"}
              value={submitData?.values.level || null}
              styles={submitData.errors?.level ? errorStyles : customStyles}
            />
            {submitData.errors.level && <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>{submitData.errors.level[0]}</span>}
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Fee of package
            </Box>
            <TextField 
              fullWidth
              disabled
              margin='dense'
              name="fee_of_pack"
              value={submitData.values.team_id && submitData.values.level ? this.getFeeOfPack(submitData.values.team_id,submitData.values.level) : 0}
              placeholder="Package fee..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Note
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="note"
              value={submitData.values.note}
              multiline
              placeholder="Note..."
              rows={3}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema,
        onAction: (submitData) => {
          const query = {
            level: submitData.values.level.name,
            package_id: submitData.values.level.package_id,
            note: submitData.values.note
          }
          if (submitData.values.team_id) {
            query['team_id'] = submitData.values.team_id.id
          }
          return new Promise((resolve) => {
            request.post('/api/v1/fuds', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create FUD successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  render () {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          route="/api/v1/fuds"
          titlePage="SpendAds | FUD"
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newFUD(ctx),
              visible: false
            }
          ]}
          title="FUD ID"
          fields={fields}
          valueKey="fud_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.fud_id })}
          invisibleCheckbox={true}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/fud/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="FUD Detail"
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(FUDList)