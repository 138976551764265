import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import {
  Box, TextField,
} from '@mui/material';
import { request } from '../../../_services_';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils';
import { fields, formFields } from './fields';
import { ModalForm, ResourceList, DrawerForm } from '../../../components';
import { isManager, toastr } from 'src/_helpers_';
import { ROLE } from '../../../_constants_';

const roles = [
  { value: 'teamlead', label: 'Teamlead' },
  { value: 'seller', label: 'Seller' },
]

class TeamUserList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mode: 'view',
      objectId: null
    }
  }

  newUser = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New user",
      data: {
        role: roles[0]
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Email Address <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="email"
              onChange={handleChange}
              value={submitData.values.email}
              variant="outlined"
              placeholder='Email'
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.email}
              helperText={submitData.errors?.email ? submitData.errors.email[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Password <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="password"
              autoComplete='new-password'
              onChange={handleChange}
              value={submitData.values.password || ""}
              variant="outlined"
              type="password"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.password}
              helperText={submitData.errors?.password ? submitData.errors.password[0] : ''}
            />
          </div>
          {this.props.user?.is_owner &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Team
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/teams', { search: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={this.handleInputChange}
                isSearchable
                name="team_id"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'team_id',
                      value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Team"}
                menuPortalTarget={document.body}
                getOptionLabel={({ team_id, company_id }) => `Team_${team_id} ${company_id.code ||''}`}
                getOptionValue={({ team_id }) => team_id}
                valueKey={"team_id"}
                value={submitData.values.team_id}
                styles={submitData.errors?.team_id ? errorStyles : customStyles}
              />
              {submitData.errors?.team_id && <span style={{ color: 'red', fontSize: 11, marginLeft:10 }}>{submitData.errors.team_id[0]}</span>}
            </div>
          }
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User Role
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/groups', { name: inputValue }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({ value: e.code, label: e.name })))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="role"
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'role',
                    value
                  }
                }
                handleChange(event)
              }}
              placeholder={"User role"}
              menuPortalTarget={document.body}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
              valueKey={"value"}
              value={submitData.values.role}
              styles={submitData.errors?.role ? errorStyles : customStyles}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User Fullname <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="display_name"
              onChange={handleChange}
              value={submitData.values.display_name}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.display_name}
              helperText={submitData.errors?.display_name ? submitData.errors.display_name[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User Shortname <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="username"
              onChange={handleChange}
              value={submitData.values.username}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.username}
              helperText={submitData.errors?.username ? submitData.errors.username[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User FB profile name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="facebook_profile_name"
              onChange={handleChange}
              value={submitData.values.facebook_profile_name}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User FB link <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="facebook_link"
              onChange={handleChange}
              value={submitData.values.facebook_link}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.facebook_link}
              helperText={submitData.errors?.facebook_link ? submitData.errors.facebook_link[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User - Phone number
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="phone"
              onChange={handleChange}
              value={submitData.values.phone}
              variant="outlined"
              error={submitData.errors?.phone}
              helperText={submitData.errors?.phone ? submitData.errors.phone[0] : ''}
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: (submitData) => {
          const schema = {
            email: { 
              presence: { message: '^Required' },
              email: {
                message: "^Your email is invalid"
              }
            },
            password: { presence: { message: '^Required' } },
            display_name: { presence: { message: '^Required' } },
            role: { presence: { message: '^Required' } },
            username: {
              presence: { message: '^Required' },
              format: {
                pattern: /^[a-zA-Z0-9]+$/,
                message: "^Shortname contain only latin"
              }
            },
            facebook_link: {
              presence: { message: '^Required' },
              format: {
                pattern: /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i,
                message: "^Enter correct facebook link!"
              }
            },
          }
          if (this.props.user?.is_owner) {
            schema['team_id'] = { presence: { message: '^Required' } }
          }
          if (submitData.values.phone) {
            schema['phone'] = {
              format: {
                pattern: /^\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                message: "^Not a valid phone number"
              },
              length: {
                minimum: 8,
                maximum: 15,
                message: '^Phone needs to have 8-15 digits.'
              }
            }
          }
          return schema
        },
        onAction: (submitData) => {
          const query = {
            ...submitData.values,
            role: submitData.values.role.value
          }
          if (submitData.values.team_id) {
            query['team_id'] = submitData.values.team_id.team_id
          }
          return new Promise((resolve) => {
            request.post('/api/v1/team_users', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create user successful")
                const items = Object.assign([], ctx.state.items)
                items.push(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/team_user/${formState.values.uid}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view' })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  render() {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          route="/api/v1/team_users"
          titlePage="SpendAds | Team Users"
          actions={[
            {
              text: 'New User',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newUser(ctx),
              visible: isManager(this.props.user?.role)
            }
          ]}
          title="All user in Team"o
          fields={fields}
          valueKey="uid"
          onClickRow={(item) => this.setState({ open: true, objectId: item.uid })}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/team_user/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Team User Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TeamUserList);