import { Navigate, useRoutes, useNavigate } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import RecoveryPassword from './pages/RecoveryPassword';
import NotFound from './pages/Page404';
import { RegisterCompany } from './pages/Customer/RegisterCompany';
import { RegisterTeam } from './pages/Customer/RegisterTeam';
import { TeamUserList, TeamUserDetail } from './pages/Customer/TeamUser';
import { TeamSummary, TeamSummaryDetail } from './pages/Customer/TeamSummary';
import { TopupWallet, TopupWalletForm } from './pages/Customer/TopupWallet';
import { FudList, FudForm } from './pages/Customer/FUD';
import { SellerAccount, SellerAccountForm } from './pages/Customer/SellerAccount';
import { RequestSupport, RequestSupportForm } from './pages/Customer/RequestSupport';
import { WebPageInfo, WebpageForm } from './pages/Customer/WebPage';
import navigationService from './_services_/navigation';
import { ReceivingCommission, ReceivingCommissionForm } from './pages/Customer/ReceiveCommission';
import { MyWalletForm } from './pages/Customer/MyWallet';
import { PayoneerIntegration } from './pages/Customer/Payoneer';
import { ServiceForm, ServiceList } from './pages/Customer/Services';
import { TransactionHistoryList } from './pages/Customer/TransactionHistory';
import MailSuccess from './pages/MailSuccess';
import { ShortLink } from './pages/Customer/ShortLink';

import Profile from './pages/Customer/Profile';
import { TotalFundList } from './pages/Customer/TotalFund';
import ConfirmPassWord from './pages/ConfirmPassWord';
import DashboardDefault from './pages/Customer/Dashboard/DashboardDefault';
import NotificationAll from './pages/Customer/Notification/NotificationList';
import {
  ADS_ACCOUNT,
  OVERVIEW,
  PAYMENT,
  PRIVACY_POLICY,
  PRODUCT_LINKS,
  TERMS_SERVICE
} from './_constants_';
import Overview from './pages/Customer/Overview/Overview';
import PaymentPage from './pages/Customer/MyWallet/payment';
import MyAdAccountsPage from './pages/Customer/MyAdAccounts';
import PrivacyPolicy from './pages/statics/privacy-policy';
import TermsOfServices from './pages/statics/terms-of-service';
import ProductLinkPage from './pages/Customer/ProductLink';

// ----------------------------------------------------------------------

export default function Router(props) {
  // console.log(props,'2')
  let navigate = useNavigate();
  navigationService.navigate = navigate;
  return useRoutes([
    // {
    //   path: '/dashboard',
    //   element: <DashboardLayout navigate = {navigate}/>,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" replace navigate = {navigate}/> },
    //     { path: 'app', element: <DashboardApp  navigate = {navigate}/> },
    //     { path: 'user', element: <User  navigate = {navigate}/> },
    //     { path: 'products', element: <Products  navigate = {navigate}/> },
    //     { path: 'blog', element: <Blog  navigate = {navigate}/> },
    //   ]
    // },
    localStorage.getItem('user_drebest') && JSON.parse(localStorage.getItem('user_drebest')).token
      ? {
          path: '/',
          element: <DashboardLayout navigate={navigate} {...props} />,
          children: [
            // { path: 'dashboard', element: <DashboardDefault navigate={navigate} /> },
            { path: OVERVIEW, element: <Overview navigate={navigate} /> },
            // { path: 'team_summary', element: <TeamSummary navigate={navigate} /> },
            { path: 'team_summary/:id', element: <TeamSummaryDetail navigate={navigate} /> },
            { path: 'team_user', element: <TeamUserList navigate={navigate} /> },
            { path: 'team_user/:id', element: <TeamUserDetail navigate={navigate} /> },
            { path: 'topup_wallet', element: <TopupWallet navigate={navigate} /> },
            // { path: 'fud', element: <FudList navigate={navigate} /> },
            // { path: 'fud/:id', element: <FudForm navigate={navigate} /> },
            { path: 'topup/:id', element: <TopupWalletForm navigate={navigate} /> },
            // { path: 'seller_accounts', element: <SellerAccount navigate={navigate} /> },
            // { path: 'seller_account/:id', element: <SellerAccountForm navigate={navigate} /> },
            // { path: 'request_supports', element: <RequestSupport navigate={navigate} /> },
            { path: 'request_support/:id', element: <RequestSupportForm navigate={navigate} /> },
            // { path: 'webpage_info', element: <WebPageInfo navigate={navigate} /> },
            { path: 'webpage/:id', element: <WebpageForm navigate={navigate} /> },
            // { path: 'user_commissions', element: <ReceivingCommission navigate={navigate} /> },
            // { path: 'user_commission', element: <ReceivingCommissionForm navigate={navigate} /> },
            { path: 'register', element: <RegisterTeam navigate={navigate} /> },
            { path: 'profile/:public_id', element: <Profile navigate={navigate} /> },
            // { path: 'my_wallet2', element: <MyWalletForm navigate={navigate} /> },
            { path: PAYMENT, element: <PaymentPage /> },
            { path: ADS_ACCOUNT, element: <MyAdAccountsPage /> },
            { path: PRODUCT_LINKS, element: <ProductLinkPage /> },
            // {
            //   path: 'payment_gateway/:gateway',
            //   element: <PayoneerIntegration navigate={navigate} />
            // },
            { path: 'services', element: <ServiceList navigate={navigate} /> },
            { path: 'service/:id', element: <ServiceForm navigate={navigate} /> },
            {
              path: 'transaction_histories',
              element: <TransactionHistoryList navigate={navigate} />
            },
            { path: 'total_fund', element: <TotalFundList navigate={navigate} /> },
            { path: '/notifications', element: <NotificationAll navigate={navigate} /> },
            { path: '404', element: <NotFound navigate={navigate} /> },
            { path: '/', element: <Navigate to={OVERVIEW} navigate={navigate} /> },
            { path: '*', element: <Navigate to="/404" replace navigate={navigate} /> }
          ]
        }
      : {
          path: '/',
          element: <LogoOnlyLayout navigate={navigate} />,
          children: [
            { path: 'login', element: <Login navigate={navigate} /> },
            { path: 'recovery_password/:token', element: <RecoveryPassword navigate={navigate} /> },
            { path: 'register_company', element: <RegisterCompany navigate={navigate} /> },
            { path: 's/:code', element: <ShortLink navigate={navigate} /> },
            { path: '404', element: <NotFound navigate={navigate} /> },
            { path: '/', element: <Navigate to="/login" navigate={navigate} /> },
            { path: '*', element: <Navigate to="/404" navigate={navigate} /> }
          ]
        },
    // public route
    {
      path: '/',
      element: <LogoOnlyLayout navigate={navigate} />,
      children: [
        { path: TERMS_SERVICE, element: <TermsOfServices navigate={navigate} /> },
        { path: PRIVACY_POLICY, element: <PrivacyPolicy navigate={navigate} /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace navigate={navigate} /> },
    { path: '/mail_success', element: <MailSuccess navigate={navigate} /> },
    { path: '/user/confirm/:token', element: <ConfirmPassWord navigate={navigate} /> }
  ]);
}
