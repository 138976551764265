import * as t from '../_constants_/redux';
import { request } from '../_services_';
import { toastr } from '../_helpers_';

export const createRequestAd = (data) => async (dispatch) => {
  try {
    const res = await request.post_form_data('/api/v1/request_ad_account_tiktok', data);
    if (res.data.success) {
      dispatch({
        type: t.CREATE_REQUEST_TIKTOK_ACC,
        payload: res.data.data
      });
      toastr.success('Create an account successful!');
    }
  } catch (err) {
    toastr.error(err);
    dispatch({
      type: t.ERROR,
      payload: {
        key: 'createAdAccountErrors',
        errors: err.response.data
      }
    });
  }
};

export const getListRequest = (query) => async (dispatch) => {
  try {
    const res = await request.get('/api/v1/list_ad_account_tiktok', query);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.LIST_REQUEST,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};

export const getDataRequestCreate = () => async (dispatch) => {
  try {
    const res = await request.get('/api/v1/request_ad_account_tiktok');
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.DATA_REQUEST_CREATE,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};

export const getLastRequest = (team_id) => async (dispatch) => {
  try {
    const res = await request.get(`/api/v1/request_ad_account_tiktok/${team_id}`);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.LAST_REQUEST_AD,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};
