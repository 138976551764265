import * as t from '../_constants_/redux';

const initialState = {
  dataTeamOption: null
};

export function user(state = initialState, action) {
  switch (action.type) {
    case t.TEAM_OPTIONS: {
      const data = action?.payload?.items.map((e) => ({
        value: e.team_id,
        label: `Team_${e.team_id}`
      }));

      return {
        ...state,
        dataTeamOption: data
      };
    }
    default:
      return state;
  }
}
