import React, { useState } from 'react';
import Page from '../../../components/Page';
import { Box, Button, Container, Typography } from '@mui/material';
import YourProductLinks from '../../../components/product-links/product-link';
import AddIcon from '@mui/icons-material/Add';
import AddProductLink from '../../../components/product-links/modal-create';
import { useSelector } from 'react-redux';

export default function ProductLinkPage() {
  const [modalCreate, setModalCreate] = useState(false);
  const user = useSelector((state) => state?.authentication?.user);

  return (
    <Page title="SpendAds | Product Links">
      <Container maxWidth="xl">
        <Box>
          <Box className="d-flex align-items-center justify-content-between mt-1 mb-4">
            <Typography variant="h4">Product Links</Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setModalCreate(true);
                }}
              >
                New
              </Button>
              {modalCreate && user && (
                <AddProductLink
                  team_id={user.team_id.id}
                  modal={modalCreate}
                  onClose={() => setModalCreate(false)}
                />
              )}
            </Box>
          </Box>
          <Box mt={3}>
            <YourProductLinks />
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
