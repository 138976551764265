import toastr from 'toastr'
import '../../node_modules/toastr/build/toastr.min.css'

toastr.options = {
  "debug": false,
  "positionClass": "toast-bottom-right",
  // "onclick": null,
  "fadeIn": 300,
  "fadeOut": 1000,
  "timeOut": 3000,
  "extendedTimeOut": 1000
}

const Toastr = {
  success (data) {
    if (data.message) {
      toastr.success(data.message)
    } else {
      toastr.success(data)
    }
  },
  error (data) {
    if (data && data.response && data.response.data.msg) {
      if (data.response.status === 401) {
        if (localStorage.getItem('user_drebest')) {
          localStorage.removeItem('user_drebest')
          window.location.href = '/login'
        } else {
          toastr.error(data.response.data.msg)
        }
      } else if (data.response.status === 422) {
        toastr.error('Invalid input. Please check your data and try again.');
      } else {
        toastr.error(data.response.data.msg)
      }
    } else if (data && data.message) {
      toastr.error(data.message)
    } else {
      toastr.error(data)
    }
  }, 
  info (data) {
    if (data.message) {
      toastr.info(data.message)
    } else {
      toastr.info(data)
    }
  },
}

export default Toastr
