import React, { useEffect, useState, useCallback } from 'react';
import scriptLoader from 'react-async-script-loader';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { request } from 'src/_services_';
import { MIN_TRANSFER_AMOUNT } from 'src/_constants_';
import { toastr } from 'src/_helpers_';
import { parseJSON } from 'date-fns';

const PaypalButton = ({
  currency,
  amount,
  isScriptLoaded,
  isScriptLoadSucceed,
  height = 40,
  onSuccess,
  onCancel,
  onError,
  onDataProcess,
  onValidate,
}) => {
  const [showButton, setShowButton] = useState(false);
  const [env] = useState('sandbox');
  const [client] = useState({
    sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
    production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
  });

  useEffect(() => {
    window.React = React;
    window.ReactDOM = ReactDOM;

    if (isScriptLoaded && isScriptLoadSucceed && amount >= MIN_TRANSFER_AMOUNT) {
      setShowButton(true);
    } else if (isScriptLoaded && !isScriptLoadSucceed) {
      console.error('Cannot load Paypal script!');
      onError();
    }
  }, [isScriptLoaded, isScriptLoadSucceed, onError, amount]);

  useEffect(() => {
    if (amount < MIN_TRANSFER_AMOUNT)  setShowButton(false);
  }, [amount])

  const payment = useCallback(() => {
    return new Promise((resolve, reject) => {
      const validateAmountFormat = /^[0-9]+(\.[0-9]+)?$/; // Regex to validate positive integers and decimals
      if (!validateAmountFormat.test(amount.toString())) {
        const messageValidate =
          'Please enter the Transaction Amount ($) as a positive integer or a decimal number.';
        onValidate && onValidate(messageValidate);
        return reject(new Error(messageValidate));
      }

      const messageValidate = `Please enter a minimum amount of $${MIN_TRANSFER_AMOUNT}.`;
      if (amount < MIN_TRANSFER_AMOUNT) {
        onValidate && onValidate(messageValidate);
        return reject(new Error(messageValidate));
      }
      request.get('/api/v1/all_configs').then((res) => {
        if (res.data.success) {
          const paypal_fee = res.data.data['paypal_fee_topup']? Number.parseFloat(res.data.data['paypal_fee_topup']) : 0;
          const fee_per_transaction = res.data.data['paypal_fee_per_transaction'] ? Number.parseFloat(res.data.data['paypal_fee_per_transaction']) : 0;
          const taxFee = res.data.data['service_fee'] ? Number.parseFloat(res.data.data['service_fee']) : 0;
          const taxAmount = (amount * taxFee) / 100;
          const totalAmount =
            amount + (amount * paypal_fee) / 100 +
            taxAmount +
            fee_per_transaction;

          if (onDataProcess) onDataProcess(totalAmount);

          paypal.rest.payment
            .create(env, client, {
              transactions: [
                {
                  amount: { total: totalAmount, currency }
                }
              ]
            })
            .then((payment) => {
              resolve(payment);
            })
            .catch(() => {
              let errorMessage = 'Failed to create PayPal payment Api Invalid.';
              toastr.error(errorMessage);
              reject(new Error(errorMessage));
            });
        }
      }).catch((err) => {
        toastr.error(err);
        reject(err);
      });
    });
  }, [amount, client, currency, env, onDataProcess, onValidate]);

  const onAuthorize = useCallback(
    (data, actions) =>
      actions.payment.execute().then(() => {
        const paymentDetails = {
          paid: true,
          cancelled: false,
          payerID: data.payerID,
          paymentID: data.paymentID,
          paymentToken: data.paymentToken,
          returnUrl: data.returnUrl,
        };
        onSuccess(paymentDetails);
      }),
    [onSuccess]
  );

  return (
    <div>
      {showButton && (
        <paypal.Button.react
          env={env}
          client={client}
          payment={payment}
          fundingSource="paylater"
          commit
          style={{
            shape: 'rect',
            maxbuttons: 1,
            tagline: false,
            size: 'responsive',
            color: 'gold',
            height,
          }}
          onAuthorize={onAuthorize}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

PaypalButton.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  height: PropTypes.number,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onDataProcess: PropTypes.func,
  onValidate: PropTypes.func,
};

PaypalButton.defaultProps = {
  isScriptLoaded: false,
  isScriptLoadSucceed: false,
  height: 40,
  onSuccess: (payment) => {
    console.log('The payment was succeeded!', payment);
  },
  onCancel: (data) => {
    console.log('The payment was cancelled!', data);
  },
  onError: (err) => {
    console.error('Error loading Paypal script!', err);
  },
};

export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);
