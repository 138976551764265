/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/styles';
import validate from 'validate.js';
import AsyncSelect from 'react-select/async';
import { hideLoading, showLoading, updatePayment } from '../../_actions_';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../utils';
import { toastr } from '../../_helpers_';
import { request } from '../../_services_';
import { PaypalButton, CustomNumberFormat } from '../index';
import { COLOR_WHITE, MIN_TRANSFER_AMOUNT, PaymentMethod, PaymentTabs } from '../../_constants_';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { formatNumberWithCommas, renderQRCode } from '../../_helpers_/helper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BalanceSummary from './BlanceSummary';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BalanceForm = (props) => {
  const { dataFormSetUp, handleOnCloseCallBack } = props;
  const navigate = useNavigate();
  const user = useSelector((state) => state?.authentication?.user);
  const dispatch = useDispatch();
  const [config, setConfig] = useState(null);
  const [preTotalFund, setPreTotalFund] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErrorValidateAmount, setMessageErrorValidateAmount] = useState(false);
  let totalFund = 0;

  const queryObject = {
    page: 1,
    limit: 50
  };
  const [openTopup, setOpenTopup] = useState(false);
  const [modalBank, setModalBank] = useState({
    success: false,
    error: false
  });
  const [formTopup, setFormTopup] = useState({
    values: {
      payment_method: dataFormSetUp?.paymentMethod || PaymentMethod.paypal,
      transaction_amount: '',
      transfer_content: ''
    },
    errors: {}
  });
  const [transactionCode, setTransactionCode] = useState(null);

  useEffect(() => {
    if (dataFormSetUp.showTopUpModal) {
      openTopupModal();
      handleOnCloseCallBack();
    }
  }, [dataFormSetUp.showTopUpModal]);

  const schema = {
    transaction_amount: {
      presence: { allowEmpty: false, message: '^Required' },
      numericality: {
        greaterThan: 0,
        message: '^Invalid amount'
      }
    },
    team_id: {
      presence: { allowEmpty: false, message: '^Required' }
    }
  };

  const getDataTotalFund = async (data, isFirst = false) => {
    dispatch(showLoading());
    try {
      const res = await request.get('/api/v1/my_wallet', data);
      const { success, data: walletData } = res.data;
      if (success) {
        if (isFirst) {
          setPreTotalFund(walletData.total_fund);
        }
        dispatch(updatePayment(walletData));
        totalFund = walletData.total_fund;
      } else {
        toastr.error('Error fetching data total fund');
      }
    } catch (err) {
      toastr.error('Error: ' + err.message);
    } finally {
      dispatch(hideLoading());
    }
  };

  const validateForm = () => {
    const errors = validate(formTopup.values, schema);
    const isValid = !errors;
    return {
      ...formTopup,
      isValid,
      errors: errors || {}
    };
  };

  const openTopupModal = async () => {
    if (!user) return;
    dispatch(showLoading());
    try {
      const res = await request.get('/api/v1/all_configs');
      const { success, data, msg } = res.data;

      if (success) {
        const teamInfo = user.team_id
          ? { id: user.team_id.team_id, name: user.team_id.team_search }
          : null;

        const paymentMethod = dataFormSetUp?.paymentMethod || PaymentMethod.paypal;

        const initialValues = {
          ...formTopup.values,
          payment_method: paymentMethod,
          transaction_amount: '',
          team_id: teamInfo
        };

        setFormTopup((prevState) => ({
          ...prevState,
          values: {
            ...initialValues,
            ...(paymentMethod === PaymentMethod.bank_vnd && {
              transfer_content: `Team ${teamInfo?.id} User ${user.user_id} ${user.shortname}`
            })
          }
        }));

        setOpenTopup(true);
        setConfig(data);
        await getDataTotalFund(queryObject, true);
      } else {
        toastr.error(msg);
      }
    } catch (err) {
      toastr.error(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const onClose = () => {
    setOpenTopup(false);
    handleOnCloseCallBack();
  };

  const onChangeBankModal = (updateModalBank = {}) => {
    setModalBank((prevState) => ({
      ...prevState,
      ...updateModalBank
    }));
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === 'transaction_amount') {
      let transactionAmount = value ? Number(value) : '';
      setMessageErrorValidateAmount('');
      if (
        !transactionAmount ||
        transactionAmount < 0 ||
        transactionAmount === '' ||
        isNaN(transactionAmount)
      ) {
        value = '';
      } else if (transactionAmount < MIN_TRANSFER_AMOUNT) {
        const messageValidate = `Please enter a minimum amount of $${MIN_TRANSFER_AMOUNT}.`;
        setMessageErrorValidateAmount(messageValidate);
      }
    }

    setFormTopup((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value
      }
    }));
    if ((name === 'payment_method' && value === PaymentMethod.bank_vnd) || name === 'team_id') {
      setFormTopup((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          transfer_content: `Team ${prevState.values.team_id?.id} User ${user.user_id} ${user.shortname}`
        }
      }));
    }
  };

  const onDataProcess = async (amount) => {
    dispatch(showLoading());
    try {
      const formTopupValidated = validateForm();
      if (formTopupValidated.isValid) {
        const query = {
          transaction_amount: amount,
          team_id: formTopupValidated.values.team_id.id
        };
        const res = await request.post('/api/v1/wallet_transactions', query);
        if (res.data.success) {
          setTransactionCode(res.data.data);
          return true;
        } else {
          toastr.error(res.data.msg);
          return false;
        }
      } else {
        setFormTopup(formTopupValidated);
        return false;
      }
    } catch (error) {
      toastr.error(error?.response?.data?.msg || error.toString());
      return false;
    } finally {
      dispatch(hideLoading());
    }
  };

  const onSuccess = async (payment) => {
    const query = {
      paymentId: payment.paymentID,
      paymentToken: payment.paymentToken,
      code: transactionCode
    };
    dispatch(showLoading());
    try {
      const res = await request.put('/api/v1/authorize_topup', query);
      if (res.data.success) {
        onClose();
        toastr.success('Topup successfully');
        await getDataTotalFund(queryObject);
      } else {
        toastr.error('Topup unsuccessful');
        onClose();
      }
    } catch (err) {
      toastr.error(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const onValidate = (message) => {
    setMessageErrorValidateAmount(message);
  };

  const handlePaymentBankCompletion = async () => {
    try {
      setIsLoading(true);
      // TIME WAIT
      await new Promise((resolve) => setTimeout(resolve, process.env.REACT_APP_BANK_TIME_WAIT));
      onClose();

      await getDataTotalFund(queryObject);

      // Check condition
      const isPayment = +formTopup?.values?.transaction_amount === totalFund - preTotalFund;

      // update modal display
      onChangeBankModal(isPayment ? { success: true } : { error: true });
    } catch (error) {
      toastr.error('Payment unsuccessful');
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalAmountVnd = () => {
    const { bank_vnd_fee_topup: paymentFee = 0, service_fee: taxFee = 0 } = config || {};
    const amount = parseFloat(formTopup?.values?.transaction_amount) || 0;
    const paymentFeeAmount = (amount * paymentFee) / 100;
    const taxAmount = (amount * taxFee) / 100;
    return amount + paymentFeeAmount + taxAmount;
  };

  const renderFeeMessage = (paymentMethod, feeConfig) => {
    const { topupFee, perTransactionFee } = feeConfig;
    const hasFee =
      Number.parseFloat(topupFee || 0) !== 0 || Number.parseFloat(perTransactionFee || 0) !== 0;

    if (hasFee) {
      return (
        <Box fontSize={12} color="#979CA1">
          {`${paymentMethod} transactions will incur a ${topupFee}% ${
            perTransactionFee ? `+ $${perTransactionFee}` : ''
          } processor fee per transaction.`}
        </Box>
      );
    }

    return (
      <Box fontSize={12} color="#979CA1">
        {`${paymentMethod} transactions will not incur processor fee.`}
      </Box>
    );
  };

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="modal-form-dialog"
        open={openTopup}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: 2 }}>
          <span>Topup to Fund</span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="p-2">
            <div>
              <Box fontSize={15} fontWeight="bold">
                Team ID <span style={{ color: 'red' }}>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                isDisabled
                loadOptions={(inputValue) =>
                  loadOptions(inputValue, function loadingData(inputValue) {
                    return new Promise((resolve) => {
                      request.get('/api/v1/teams', { search: inputValue }).then((res) => {
                        if (res.data.success) {
                          resolve(
                            res.data.data.items.map((e) => ({
                              id: e.team_id,
                              name: `Team_${e.team_id}`,
                              type_acc: e.type_acc
                            }))
                          );
                        }
                      });
                    });
                  })
                }
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name={'team_id'}
                onChange={(value) => {
                  handleChange({
                    target: {
                      name: 'team_id',
                      value
                    }
                  });
                }}
                placeholder={'Team'}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={'id'}
                value={formTopup?.values.team_id || null}
                styles={formTopup.errors?.team_id ? errorStyles : customStyles}
              />
              {formTopup.errors?.team_id && (
                <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>
                  {formTopup.errors.team_id[0]}
                </span>
              )}
            </div>
            {!dataFormSetUp?.choosePayment && (
              <div className="mt-2">
                <Box fontSize={15} fontWeight="bold">
                  Payment Method
                </Box>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="payment_method"
                  value={formTopup.values.payment_method}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="paypal"
                    control={<Radio />}
                    disabled={!formTopup.values.team_id}
                    label="Paypal"
                  />
                  <FormControlLabel value="po" control={<Radio />} label="Payoneer" />
                  <FormControlLabel
                    value="bank_vnd"
                    control={<Radio />}
                    disabled={!formTopup.values.team_id}
                    label="Bank VND"
                  />
                  <FormControlLabel
                    value="pingpong"
                    control={<Radio />}
                    disabled={!formTopup.values.team_id}
                    label="Pingpong"
                  />
                </RadioGroup>
              </div>
            )}
            <div className="mt-2">
              <Box fontSize={15} fontWeight="bold">
                Transaction Amount ($)
              </Box>
              <TextField
                fullWidth
                margin="dense"
                value={formTopup.values.transaction_amount}
                name="transaction_amount"
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  },
                  inputComponent: CustomNumberFormat
                }}
                error={messageErrorValidateAmount ? true : false}
                helperText={messageErrorValidateAmount}
              />
            </div>
            {/* Paypal */}
            {formTopup.values.payment_method === PaymentMethod.paypal &&
              renderFeeMessage('Paypal', {
                topupFee: config?.paypal_fee_topup,
                perTransactionFee: config?.paypal_fee_per_transaction
              })}
            {/* Payoneer */}
            {formTopup.values.payment_method === PaymentMethod.payoneer &&
              renderFeeMessage('Payoneer', {
                topupFee: config?.payoneer_fee_topup,
                perTransactionFee: config?.payoneer_fee_per_transaction
              })}

            {/* Bank VND */}
            {formTopup.values.payment_method === PaymentMethod.bank_vnd &&
              renderFeeMessage('Bank VND', {
                topupFee: config?.bank_vnd_fee_topup,
                perTransactionFee: null
              })}
            {formTopup.values.payment_method === PaymentMethod.bank_vnd &&
              formTopup.values.transaction_amount >= MIN_TRANSFER_AMOUNT && (
                <>
                  <div className="mt-2">
                    <Box fontSize={15} fontWeight="bold">
                      Rate
                    </Box>
                    <TextField
                      fullWidth
                      margin="dense"
                      value={config?.rate_vnd}
                      disabled
                      name="rate_vnd"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        },
                        inputComponent: CustomNumberFormat
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <Box fontSize={15} fontWeight="bold">
                      Transaction Amount (VND)
                    </Box>
                    <TextField
                      fullWidth
                      margin="dense"
                      value={formTopup.values.transaction_amount * config.rate_vnd}
                      disabled
                      name="transaction_amount_vnd"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        },
                        inputComponent: CustomNumberFormat
                      }}
                    />
                  </div>
                </>
              )}
            {formTopup.values.payment_method === PaymentMethod.bank_vnd && (
              <div className="mt-2">
                <Box fontSize={15} fontWeight="bold">
                  Transfer Content
                </Box>
                <TextField
                  fullWidth
                  margin="dense"
                  value={formTopup.values.transfer_content}
                  disabled
                  name="transfer_content"
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                />
              </div>
            )}
            {formTopup.values.payment_method === PaymentMethod.bank_vnd &&
              formTopup.values.transaction_amount >= MIN_TRANSFER_AMOUNT && (
                <div className="mt-2">
                  <img
                    src={renderQRCode({
                      amount: getTotalAmountVnd() * config.rate_vnd,
                      content: formTopup.values.transfer_content
                    })}
                    style={{ width: '100%', height: 'auto' }}
                    alt={'QR code'}
                  />
                </div>
              )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2"
            color="secondary"
            variant="outlined"
            onClick={onClose}
            style={{ borderRadius: 4 }}
          >
            Close
          </Button>
          {/* paypal */}
          {formTopup.values.payment_method === PaymentMethod.paypal && (
            <PaypalButton
              currency={'USD'}
              height={36}
              amount={Number.parseFloat(formTopup.values.transaction_amount) || 0}
              onSuccess={onSuccess}
              onDataProcess={onDataProcess}
              onValidate={onValidate}
            />
          )}
          {/* payoneer */}
          {formTopup.values.payment_method === PaymentMethod.payoneer &&
            formTopup.values.transaction_amount >= MIN_TRANSFER_AMOUNT && (
              <Button
                variant="outlined"
                sx={{ color: 'black', borderColor: 'gold', backgroundColor: 'gold' }}
                startIcon={
                  <img
                    src="/static/icons/payoneer.png"
                    alt={'icon payoneer'}
                    width={20}
                    height={20}
                  />
                }
              >
                Payoneer
              </Button>
            )}

          {/* bank vnd */}
          {formTopup.values.payment_method === PaymentMethod.bank_vnd &&
            formTopup.values.transaction_amount >= MIN_TRANSFER_AMOUNT && (
              <Button
                className="mr-2"
                variant="contained"
                onClick={handlePaymentBankCompletion}
                style={{ borderRadius: 4 }}
                disabled={isLoading}
              >
                I Have Completed the Payment
                {isLoading && <CircularProgress size={15} />}
              </Button>
            )}

          {/* pingpong */}
          {formTopup.values.payment_method === PaymentMethod.pingpong &&
            formTopup.values.transaction_amount >= MIN_TRANSFER_AMOUNT && (
              <Button
                variant="outlined"
                sx={{ color: 'black', borderColor: 'gold', backgroundColor: 'gold' }}
                startIcon={
                  <img
                    src="/static/icons/pingpong.ico"
                    alt={'icon pingpong'}
                    width={20}
                    height={20}
                  />
                }
              >
                Pingpong
              </Button>
            )}
        </DialogActions>
        {/* summary */}
        {config &&
          formTopup.values.payment_method !== PaymentMethod.bank_vnd &&
          !!Number(formTopup.values.transaction_amount) &&
          formTopup.values.transaction_amount >= MIN_TRANSFER_AMOUNT && (
            <BalanceSummary
              paymentMethod={formTopup.values.payment_method}
              transactionAmount={formTopup.values.transaction_amount}
              config={config}
            />
          )}
      </BootstrapDialog>

      {/* Modal Bank */}
      {/*success*/}
      <BootstrapDialog
        onClose={() => {
          onChangeBankModal({ success: false });
        }}
        aria-labelledby="modal-bank-success"
        open={modalBank.success}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogContent
          dividers
          sx={{
            backgroundColor: '#F5F4F6'
          }}
        >
          <div className="p-2">
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" flexDirection="column" gap={1} alignItems={'center'}>
                <CheckCircleIcon color="primary" sx={{ fontSize: 50 }} />
                <Typography variant="h4" gutterBottom>
                  Top-up Success
                </Typography>
                <Typography>
                  Thank you for your purchase. Please check your balance again
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: COLOR_WHITE,
                  borderRadius: 2,
                  padding: 2,
                  mt: 1
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Summary
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" gutterBottom>
                    Client:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" gutterBottom>
                    {formTopup.values.transfer_content}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" gutterBottom>
                    Order amount:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" gutterBottom>
                    {formTopup?.values?.transaction_amount} USD
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" gutterBottom>
                    Payment method:
                  </Typography>
                  <Box display={'flex'} gap={1}>
                    <AccountBalanceIcon fontSize={'small'} />
                    <Typography fontWeight={600} variant="body2" gutterBottom>
                      Bank VND
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" gutterBottom>
                    Bank VND fee topup:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" gutterBottom>
                    {formatNumberWithCommas(
                      (formTopup?.values?.transaction_amount * config?.bank_vnd_fee_topup) / 100
                    )}
                    USD
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" gutterBottom>
                    Tax:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" gutterBottom>
                    {formatNumberWithCommas(
                      (formTopup?.values?.transaction_amount * config?.service_fee) / 100
                    )}
                    USD
                  </Typography>
                </Box>
                <hr />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" gutterBottom>
                    Total Amount = Amount + Bank VND fee topup + Tax
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6" gutterBottom fontWeight={400}>
                    Total Amount:
                  </Typography>
                  <Typography fontWeight={600} variant="h6" gutterBottom>
                    {formatNumberWithCommas(getTotalAmountVnd())} USD
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" gutterBottom>
                    Converted Amount:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" gutterBottom>
                    {formatNumberWithCommas(getTotalAmountVnd() * config?.rate_vnd)}
                    VNĐ
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
          <Box display={'flex'} flexDirection="column" gap={1} alignItems={'center'} mt={2}>
            <Box display={'flex'} justifyContent={'center'} gap={1}>
              <Button
                className="mr-2"
                color="secondary"
                variant="outlined"
                onClick={() => {
                  onChangeBankModal({ success: false });
                  navigate(`/my_wallet?tab=${PaymentTabs.balance_history}`);
                }}
                style={{ borderRadius: 4 }}
              >
                OK
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                onClick={() => {
                  onChangeBankModal({ success: false });
                  openTopupModal();
                }}
                style={{ borderRadius: 4 }}
              >
                Rechange
              </Button>
            </Box>
            <Box>
              <Typography variant="body2" gutterBottom>
                You can download the invoice <Link href={'#'}>here</Link>
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>

      {/*error*/}
      <BootstrapDialog
        aria-labelledby="modal-bank-error"
        open={modalBank.error}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle
          id="modal-bank-error"
          sx={{ m: 0, p: 2, color: 'red', display: 'flex', gap: 1 }}
        >
          <ErrorOutlineIcon />
          <Typography sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
            ERROR!
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              onChangeBankModal({ error: false });
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="p-2">
            An error occurred while processing your bank transfer. Please contact the admin to
            resolve this issue.
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default BalanceForm;
