import { Box, Button, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import React, { useCallback, useState } from 'react';
import BalanceForm from '../balance/BalanceForm';
import { formatNumberWithCommas, isManager } from '../../_helpers_';
import ModalRefund from './modal-refund';
import AddIcon from '@mui/icons-material/Add';
import { PaymentMethod } from 'src/_constants_';
import { useSelector } from 'react-redux';

export default function PaymentHeader(props) {
  const { totalFund } = props;
  const [modalRefund, setModalRefund] = useState(false);
  const [modalFormBalance, setModalFormBalance] = useState({
    paymentMethod: PaymentMethod.paypal,
    showTopUpModal: false,
    choosePayment: false
  });
  const user = useSelector((state) => state?.authentication?.user);

  const handleOnClose = useCallback(() => {
    setModalFormBalance((prevState) => ({
      ...prevState,
      showTopUpModal: false
    }));
  }, []);

  return (
    <Box sx={{ p: 1, display: 'flex', gap: 2, border: '1px solid gray', borderRadius: 2, mt: 2 }}>
      <Box p={2} borderRight={'1px solid gray'}>
        <Typography textAlign={'center'} variant={'body1'}>
          SPENDADS BALANCE
        </Typography>
        <Typography variant="h5" textAlign={'center'} mt={1}>
          ${formatNumberWithCommas(totalFund ?? 0)}
        </Typography>
        <Box display="flex" justifyContent="space-between" gap={1} flexWrap={'wrap'}>
          {isManager(user?.role) && (<>
            <Box p={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setModalRefund(true);
                }}
              >
                Refund
              </Button>
              <ModalRefund
                modal={modalRefund}
                onClose={() => setModalRefund(false)}
                onOpen={() => setModalRefund(true)}
                maxAmount={totalFund}
              />
            </Box>
            <Box>
              <Box flex={1} p={2}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setModalFormBalance((prevState) => ({
                      ...prevState,
                      paymentMethod: PaymentMethod.paypal,
                      showTopUpModal: true,
                      choosePayment: false
                    }));
                  }}
                >
                  Add balance
                </Button>
              </Box>
              <BalanceForm dataFormSetUp={modalFormBalance} handleOnCloseCallBack={handleOnClose} />
            </Box>
          </>)}
        </Box>
      </Box>
      <Box p={2}>
        <Typography variant={'body1'}>Or choose another payment gate to start</Typography>
        {isManager(user?.role) && (
          <Box display="flex" justifyContent="space-between" gap={2} mt={2} flexWrap={'wrap'}>
            <Button
              variant="outlined"
              sx={{ color: 'black', borderColor: 'gray' }}
              startIcon={
                <img src="/static/icons/paypal.ico" alt={'icon paypal'} width={20} height={20} />
              }
              onClick={() => {
                setModalFormBalance((prevState) => ({
                  ...prevState,
                  paymentMethod: PaymentMethod.paypal,
                  showTopUpModal: true,
                  choosePayment: true
                }));
              }}
            >
              Paypal
            </Button>
            <Button
              variant="outlined"
              sx={{ color: 'black', borderColor: 'gray' }}
              startIcon={
                <img src="/static/icons/payoneer.png" alt={'icon payoneer'} width={20} height={20} />
              }
              onClick={() => {
                setModalFormBalance((prevState) => ({
                  ...prevState,
                  paymentMethod: PaymentMethod.payoneer,
                  showTopUpModal: true,
                  choosePayment: true
                }));
              }}
            >
              Payoneer
            </Button>
            <Button
              variant="outlined"
              sx={{ color: 'black', borderColor: 'gray' }}
              startIcon={<AccountBalanceIcon fontSize={'small'} />}
              onClick={() => {
                setModalFormBalance((prevState) => ({
                  ...prevState,
                  paymentMethod: PaymentMethod.bank_vnd,
                  showTopUpModal: true,
                  choosePayment: true
                }));
              }}
            >
              Bank VND
            </Button>
            <Button
              variant="outlined"
              sx={{ color: 'black', borderColor: 'gray' }}
              startIcon={
                <img src="/static/icons/pingpong.ico" alt={'icon PingPong'} width={20} height={20} />
              }
              onClick={() => {
                setModalFormBalance((prevState) => ({
                  ...prevState,
                  paymentMethod: PaymentMethod.pingpong,
                  showTopUpModal: true,
                  choosePayment: true
                }));
              }}
            >
              PingPong
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
