/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import axios from 'axios';
import { userConstants } from '../_constants_';
import { toastr } from '../_helpers_';
import navigationService from '../_services_/navigation';
import { request } from '../_services_';
import * as t from '../_constants_/redux';

export const userActions = {
  login,
  logout
};

function login(query, callback) {
  return dispatch => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/auth/signin`,
      data: query,
      headers: {
        'Content-Type': 'application/json',
        "Accept": 'application/json',
        'Access-Control-Allow-Origin': '*',
        "app":"customer"
      }
    }).then(res => {
      if (res.data.success) {
        localStorage.setItem('user_drebest', JSON.stringify(res.data.data))
        dispatch(login_success(res.data.data))
        callback(true)
        toastr.success('Login successfully')
        window.OneSignal = window.OneSignal || [];
        OneSignal.push(function() {
          OneSignal.init({
            appId: "c1cc661c-a02d-4478-8889-ae631787a326",
            notifyButton: {
              enable: true,
            },
          });
        });
      } else {
        toastr.error(res.data.msg)
      }
    }, error => {
      dispatch(login_failure(error.toString()))
      toastr.error(error?.response?.data?.msg || error.toString())
      callback()
    })
  }

  function login_request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function login_success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function login_failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  localStorage.removeItem('user_drebest')
  navigationService.navigate('/login');
  return { type: userConstants.LOGOUT }
}

export const getTeams = () => async (dispatch) => {
  request
    .get('/api/v1/teams')
    .then((res) => {
      const { success, data, msg } = res.data;
      if (success) {
        dispatch({
          type: t.TEAM_OPTIONS,
          payload: data
        });
      } else {
        toastr.error(msg);
      }
    })
    .catch((err) => {
      toastr.error(err);
    });
};

