import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { fields, formFields } from './constant';
import {
  Box, Button, Grid, TextField, IconButton
} from '@mui/material'
import { ModalForm, ResourceList, DrawerForm } from '../../../components';
import { toastr } from 'src/_helpers_';
import { request } from '../../../_services_/request';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils';
import { IconTrash } from '@tabler/icons';
import { validate } from 'validate.js';

const roles = [
  { value: 'teamlead', label: 'Teamlead' },
  { value: 'seller', label: 'Seller' },
];

const usersSchema = {
  email: { 
    presence: { message: '^Required' },
    email: {
      message: "^Your email is invalid"
    }
  }
}

class TeamSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mode: 'view',
      objectId: null
    }
  }
  registerNewTeam = (ctx) => {
    const { dispatch } = this.props
    ModalForm.instance.current.openForm({
      title: "Register New Team",
      data: {
        users: [
          {
            email: '',
            role: roles[0],
          }
        ]
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Platform
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/team_platforms', {name: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="platform_id"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "platform_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Platform..."}
              menuPortalTarget={document.body}
              getOptionLabel={({ title }) => title}
              getOptionValue={({ id }) => id}
              valueKey={"platform_id"}
              value={submitData?.values.platform_id || null}
              styles={submitData.errors?.platform_id ? errorStyles : customStyles}
            />
            {submitData.errors?.platform_id && <span style={{ color: 'red', fontSize: 11, marginLeft:10 }}>{submitData.errors.platform_id[0]}</span>}
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Products URL
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="product_url"
              onChange={(e) => handleChange(e)}
              value={submitData.values.product_url}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.product_url}
              helperText={submitData.errors?.product_url ? submitData.errors.product_url[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User
            </Box>
            { submitData.values.users && submitData.values.users?.length > 0 && submitData.values.users.map((item, index) => (
              <Grid key={index} container spacing={1} sx={{alignItems: 'center'}}>
                <Grid item xs={7}>
                  <TextField
                    fullWidth
                    margin='dense'
                    name="email"
                    onChange={(e) => {
                      item.email = e.target.value
                      const errors = validate(item, usersSchema);
                      item.isValid = errors ? false : true;
                      item.errors = errors || {}; 
                      const event = {
                        target: {
                          name: 'users',
                          value: [...submitData.values.users]
                        }
                      }

                      handleChange(event)
                    }}
                    value={item?.email}
                    variant="outlined"
                    placeholder='Email'
                    type="email"
                    InputProps={{
                      classes: {
                        notchedOutline: 'notchedOutline'
                      }
                    }}
                    error={item.errors?.email}
                    helperText={item.errors?.email || ''}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AsyncSelect
                    className="MuiFormControl-marginDense"
                    cacheOptions
                    loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                      return new Promise(resolve => {
                        request.get('/api/v1/groups', { name: inputValue }).then(res => {
                          if (res.data.success) {
                            resolve(res.data.data.items.map(e => ({ value: e.code, label: e.name })))
                          }
                        })
                      })
                    })}
                    defaultOptions
                    onInputChange={handleInputChange}
                    isSearchable
                    name="role"
                    onChange={(value) => {
                      item.role = value
                      const event = {
                        target: {
                          name: 'users',
                          value: [...submitData.values.users]
                        }
                      }
                      handleChange(event)
                    }}
                    placeholder={"User role"}
                    menuPortalTarget={document.body}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    valueKey={"value"}
                    value={item?.role}
                    styles={submitData.errors?.role ? errorStyles : customStyles}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton color="primary" aria-label="upload picture" component="label" 
                    onClick={() => {
                        const users = Object.assign([], submitData.values.users)
                        users.splice(index, 1)
                        
                        const event = {
                          target: {
                            name: 'users',
                            value: users
                          }
                        }
                        handleChange(event)
                      }}
                    >
                    <IconTrash color="red" width={16} height={16} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button onClick={() => {
                const users = Object.assign([], submitData.values.users)
                const emails = []
                let isDup = false

                if (users.length > 1) {
                  users.forEach((user => {
                    if (emails.includes(user.email)){
                      user.isValid = false
                      user.errors = {email: 'Email is duplicate'}
                      isDup = true
                    }
                    emails.push(user.email)
                  }))
                }
                
                if (isDup){
                  handleChange({ target: { name: 'users', value: users } })
                  return
                }

                users.push({
                  email: '',
                  role: roles[0]
                })

                handleChange({ target: { name: 'users', value: users } })
              }}
              disabled={ 
                submitData.values.users && submitData.values.users.length > 0
                && (submitData.values.users[submitData.values.users.length-1]?.email == '' ?
                true : (submitData.values.users[submitData.values.users.length-1]?.errors.email  ? true : false))
              }
            >
              Add
            </Button>
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: (submitData) => {
          const schema = {
            platform_id: { presence: { message: '^Required' } },
          }
          if(submitData.values.platform && submitData.values.platform.value === 'tiktok'){
            schema['product_url'] = {
              presence: { message: '^Required' }
            }
          }

          return schema
        },
        onAction: (submitData, context) => {

          const query = {...submitData.values}

          if(submitData.values.platform_id){
            query['platform_id'] = submitData.values.platform_id.id
          }
          query.users = query.users.map((user) => {
            if(user.email == ''){
              const errors = validate(user.email, usersSchema)
              user.isValid = errors ? false : true;
              user.errors = errors || {};
              return
            } else {
              return ({ email: user.email, role: user.role.value })
            }
          })

          return new Promise((resolve) => {
            request.post('/api/v1/quick_create_team', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create team successful")
                const dataTeam = res.data.data.list_team
                dispatch({ type: 'LOAD_LIST_TEAM', dataTeam })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }


  render () {
    const { user, navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          route="/api/v1/team_summary"
          titlePage="SpendAds | Team Summary"
          actions={[
            {
              text: 'Register new team',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.registerNewTeam(ctx),
              visible: this.props.user?.is_owner || this.props.user?.group?.map(e => e.code).includes('teamlead')
            }
          ]}
          title="Team summary"
          fields={fields}
          valueKey="team_id"
          onClickRow={(item) => this.setState({ open: true, mode: 'view', objectId: item.team_id })}
          invisiblePagination={true}
          invisibleCheckbox={true}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/team_summary/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Team Summary Detail"
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TeamSummary);