import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Page from '../../components/Page';
import LayoutStatic from '../../components/static/layout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const BulletPointList = ({ items }) => {
  return (
    <List>
      {items.map((item, index) => (
        <ListItem key={index} sx={{ pt: 0, alignItems: 'flex-start' }}>
          <ListItemIcon sx={{ pt: 0.7 }}>
            <FiberManualRecordIcon sx={{ fontSize: '12px' }} />
          </ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
};

export default function PrivacyPolicy() {
  return (
    <LayoutStatic>
      <Page title="Spendads | Privacy Policy">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
          <Typography variant="h3">Privacy Policy</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <Box>
              <Typography>
                We know that you care how information about you is used and shared, and we
                appreciate your trust that we will do so carefully and sensibly. This Privacy Policy
                describes how SpendAds and its affiliates (collectively “SpendAds”) collect and
                process your personal information through Websites, devices, products, services that
                reference this Privacy Policy. By using SpendAds’s Services, you are consenting to
                the practices described in this Privacy Privacy.
              </Typography>
              <BulletPointList
                items={[
                  'What Information Does SpendAds Collect?',
                  'For What Purposes Does SpendAds Use Your Information?',
                  'What Information Do You Receive From Us?',
                  'Does SpendAds Share Your Information?',
                  'How Secure Is Information About Me?'
                ]}
              />
            </Box>

            <Box>
              <Typography variant="h6" mb={1}>
                What Information Does SpendAds Collect?
              </Typography>
              <Typography mb={1}>
                We collect your information in order to support you to use the TikTok Platform.
                Moreover, your information also allows us to support you while you are using the
                TikTok Platform.
              </Typography>
              <Typography mb={1}>Here are the types of information we collect:</Typography>
              <Typography>Your TikTok account information</Typography>
              <BulletPointList items={['Your payment information', 'Your campaigns']} />
              <ListItem sx={{ pt: 0, alignItems: 'flex-start' }}>
                <ListItemIcon sx={{ pt: 0.7 }}>
                  <FiberManualRecordIcon sx={{ fontSize: '12px' }} />
                </ListItemIcon>
                <Typography>
                  All information we received from you is for the purpose of your stable business
                  operation. You also understand that we only provide your information to TikTok and
                  TikTok shall use your information in accordance with TikTok’s Privacy Policy as{' '}
                  <a href="https://www.tiktok.com/legal/privacy-policy?lang=en">
                    https://www.tiktok.com/legal/privacy-policy?lang=en{' '}
                  </a>
                </Typography>
              </ListItem>
            </Box>

            <Box>
              <Typography variant="h6" mb={1}>
                For What Purposes Does SpendAds Use Your Information?
              </Typography>
              <Typography>
                We use your information to operate, provide, develop, and improve the Services that
                we offer you. These purposes include:
              </Typography>
              <BulletPointList
                items={[
                  'Support you to use TikTok Platform under the scope of Services that SpendAds providing (visit Terms of Services to know more about Services)',
                  'Provide to TikTok in accordance with the Framework Agreement between SpendAds and TikTok',
                  'Comply with legal obligations. In certain cases, we collect and use your information to comply with laws.',
                  'Communicate with you. We use your information to communicate with you in relation to SpendAds via different channels (e.g., by phone, e-mail, chat).'
                ]}
              />
            </Box>

            <Box>
              <Typography variant="h6" mb={1}>
                What Information Do You Receive From Us?
              </Typography>
              <Typography>
                To help you use our Services, you will receive the certain information:
              </Typography>
              <BulletPointList
                items={[
                  'Our pricing policy applied for you',
                  'Our payment information',
                  'Consultancy to help you use the TikTok platform that we do not publish to any channels.',
                  'You understand that you have not been permitted to share and provide any information you received from us for any purpose to any third party.'
                ]}
              />
            </Box>

            <Box>
              <Typography variant="h6" mb={1}>
                Does SpendAds Share Your Information?
              </Typography>
              <Typography>
                Your information is an important part of our business, and we are not in the
                business of selling your information to others. We share your information only as
                described below and with subsidiaries of SpendAds controls that either are subject
                to this Privacy Privacy or follow practices at least as protective as those
                described in this Privacy Privacy.
              </Typography>
              <BulletPointList
                items={[
                  'Third-Party Service Providers: We employ other companies and individuals to perform functions on our behalf.',
                  'Business Transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, your information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Policy (unless, of course, the customer consents otherwise). Also, in the unlikely event that SpendAds Pte. Ltd. or substantially all of its assets are acquired, customer information will of course be one of the transferred assets.\n',
                  'Protection of SpendAds and Others: We release accounts and other information when we believe release is appropriate to comply with the law; enforce or apply our Terms of Services and other agreements; or protect the rights, property, or safety of SpendAds, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.'
                ]}
              />
            </Box>

            <Box>
              <Typography variant="h6" mb={1}>
                How Secure Is Information About Me?
              </Typography>
              <Typography mb={1}>
                We design our systems with your security and privacy in mind.
              </Typography>
              <Typography mb={1}>
                We work to protect the security of your information during transmission by using
                encryption protocols and software.
              </Typography>
              <Typography mb={1}>
                We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling
                credit card data.
              </Typography>
              <Typography mb={1}>
                We maintain physical, electronic, and procedural safeguards in connection with the
                collection, storage, and disclosure of personal customer information. Our security
                procedures mean that we may occasionally request proof of identity before we
                disclose personal information to you.
              </Typography>
              <Typography mb={1}>
                Our devices offer security features to protect them against unauthorized access and
                loss of data. You can control these features and configure them based on your
                needs."
              </Typography>
            </Box>
          </Box>
        </Box>
      </Page>
    </LayoutStatic>
  );
}
