import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResourceList, DrawerForm } from '../../../components'
import { fields, formFields } from './fields'
import { request } from '../../../_services_';


class SellerAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      mode: 'view',
      objectId: null
    }
  }
  render () {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList 
          rowActive={objectId}
          route="/api/v1/accounts"
          titlePage="SpendAds | Seller Account"
          title="Acc of Seller"
          fields={fields}
          valueKey="account_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.account_id })}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(public_id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/account/${public_id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Seller Account Detail"
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(SellerAccount);