import React, { useState } from 'react';
import { Box, Button, Typography, Link, FormHelperText, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FILE_ALLOW, MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MB, MAX_FILES } from '../../_constants_/validate';
import DeleteIcon from '@mui/icons-material/Delete';
import { getImageUrl } from 'src/_helpers_';

const UploadFile = ({
  title,
  description,
  required = false,
  onChange,
  fileAllow = FILE_ALLOW,
  error,
  className = '',
  defaultFiles,
  onDeleteFileDefault,
}) => {
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState('');

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    event.target.value = null;

    // Check if the total number of files exceeds the limit
    if (files.length + newFiles.length > MAX_FILES) {
      setFileError(`You can only upload a maximum of ${MAX_FILES} files.`);
      return;
    }

    // Validate file types and size
    const validFiles = [];
    let errorMessages = [];

    newFiles.forEach((file) => {
      if (!fileAllow.includes(file.type)) {
        errorMessages.push(`File type not allowed: ${file.name}`);
      } else if (file.size > MAX_FILE_SIZE_BYTES) {
        errorMessages.push(`File size exceeds ${MAX_FILE_SIZE_MB}MB: ${file.name}`);
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessages.length > 0) {
      setFileError(errorMessages.join(', '));
    } else {
      setFileError('');
    }

    const updatedFiles = [...files, ...validFiles];
    setFiles(updatedFiles);

    // Call onChange with current files
    if (onChange) {
      onChange(updatedFiles);
    }
  };

  const handleFileDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);

    if (onChange) {
      onChange(updatedFiles);
    }
  };

  return (
    <Box className={className}>
      <Box display="flex" gap={0.5} alignItems="center">
        <Typography fontWeight={600}>{title}</Typography>
        {required ? (
          <Typography sx={{ color: 'red' }}>*</Typography>
        ) : (
          <Typography>(Optional)</Typography>
        )}
      </Box>

      {description && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>
      )}

      <Button
        variant="outlined"
        component="label"
        startIcon={<AttachFileIcon />}
        sx={{ marginBottom: 1 }}
        disabled={files.length >= MAX_FILES} // Disable if max files reached
      >
        Choose file
        <input
          type="file"
          hidden
          multiple
          onChange={handleFileUpload}
          accept={fileAllow.map((ext) => `.${ext.split('/').pop()}`).join(', ')}
        />
      </Button>

      <Box mt={1}>
        {files.map((file, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ justifyContent: 'space-between' }}
          >
            <Link
              href={URL.createObjectURL(file)}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'blue' }}
            >
              {file.name}
            </Link>

            <IconButton onClick={() => handleFileDelete(index)} aria-label="delete file">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        {defaultFiles.map((file, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ justifyContent: 'space-between' }}
          >
            <Link
              href={getImageUrl(file.image_path)}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'blue' }}
            >
              {file.image_path}
            </Link>

            <IconButton onClick={() => onDeleteFileDefault(file)} aria-label="delete file">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>

      <FormHelperText className='field-error'>{fileError || error}</FormHelperText>
    </Box>
  );
};

export default UploadFile;
