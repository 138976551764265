import axios from 'axios';
import { store, authHeaders, headers } from '../_helpers_';
import { userActions } from '../_actions_';

export const request = {
  post,
  get,
  put,
  delete: _delete,
  post_form,
  post_form_data,
  put_form_data
};

function post(url, data) {
  // const data = Object.keys(queryObject).map((key) => {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]);
  // }).join('&');
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL + url,
    data,
    headers: authHeaders()
  })
    .then((res) => res)
    .catch((err) => {
      if (err.response != null && err.response.status === 401) {
        store.dispatch(userActions.logout());
      }
      throw err;
    });
}

function get(url, queryObject) {
  const searchParams =
    '?' +
    Object.keys(queryObject || {})
      .map((key) => key + '=' + queryObject[key])
      .join('&');
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL + url + searchParams,
    headers: authHeaders()
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}

      throw err;
    });
}

function put(url, data) {
  // const data = Object.keys(queryObject).map((key) => {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]);
  // }).join('&');
  return axios({
    method: 'PUT',
    url: process.env.REACT_APP_API_URL + url,
    headers: authHeaders(),
    data
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}

      throw err;
    });
}

function _delete(url, data) {
  // const data = Object.keys(queryObject).map((key) => {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]);
  // }).join('&');
  return axios({
    method: 'DELETE',
    url: process.env.REACT_APP_API_URL + url,
    headers: authHeaders(),
    data
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}
      throw err;
    });
}

function post_form(url, queryObject) {
  if (!queryObject) queryObject = {};
  var formData = new FormData();
  var arrKeys = Object.keys(queryObject);
  arrKeys.map((key) => {
    formData.append(key, queryObject[key]);
  });
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}${url}`,
    headers: authHeaders(),
    data: formData
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status == 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}
      throw err;
    });
}

function post_form_data(url, data) {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL + url,
    data: createFormData(data),
    headers: headers()
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}
      throw err;
    });
}

function put_form_data(url, data) {
  return axios({
    method: 'PUT',
    url: process.env.REACT_APP_API_URL + url,
    data: createFormData(data),
    headers: headers()
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}
      throw err;
    });
}

export const createFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (Array.isArray(value)) {
      value.forEach((file) => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};
