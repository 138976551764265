import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { COLOR_GRAY_BLUE, PaymentMethod } from 'src/_constants_';
import { formatNumberWithCommas } from 'src/_helpers_';

const BalanceSummary = ({ transactionAmount = 0, paymentMethod, config }) => {
  const {
    paypal_fee_per_transaction: paypalFeePerTransaction = 0,
    paypal_fee_topup: paypalPaymentFee = 0,
    payoneer_fee_per_transaction: payoneerFeePerTransaction = 0,
    payoneer_fee_topup: payoneerPaymentFee = 0,
    service_fee: taxFee = 0
  } = config || {};

  let paymentFee = 0
  let feePerTransaction = 0;
  if (paymentMethod === PaymentMethod.paypal) {
    feePerTransaction = Number.parseFloat(paypalFeePerTransaction);
    paymentFee = Number.parseFloat(paypalPaymentFee);
  } else if (paymentMethod === PaymentMethod.payoneer) {
    feePerTransaction = Number.parseFloat(payoneerFeePerTransaction);
    paymentFee = Number.parseFloat(payoneerPaymentFee)
  }

  const amount = parseFloat(transactionAmount) || 0;
  const paymentFeeAmount = (amount * paymentFee) / 100;
  const taxAmount = (amount * taxFee) / 100;
  const totalAmount = amount + paymentFeeAmount + taxAmount + feePerTransaction;

  const getIcon = (method) => {
    switch (method) {
      case PaymentMethod.paypal:
        return '/static/icons/paypal.ico';
      case PaymentMethod.payoneer:
        return '/static/icons/payoneer.png';
      case PaymentMethod.bank_vnd:
        return '/static/icons/bank_vnd.ico';
      case PaymentMethod.pingpong:
        return '/static/icons/pingpong.ico';
      default:
        return null;
    }
  };

  const getPaymentMethod = (method) => {
    switch (method) {
      case PaymentMethod.paypal:
        return 'Paypal';
      case PaymentMethod.payoneer:
        return 'Payoneer';
      case PaymentMethod.bank_vnd:
        return 'Bank Vnd';
      case PaymentMethod.pingpong:
        return 'PingPong';
      default:
        return null;
    }
  };

  const summaryItems = [
    { label: 'Amount', value: `${formatNumberWithCommas(amount)} USD` },
    {
      label: 'Payment Method',
      value: getPaymentMethod(paymentMethod),
      icon: getIcon(paymentMethod)
    },
    {
      label: `${getPaymentMethod(paymentMethod)} Fee: (${paymentFee}%)`,
      value: `${formatNumberWithCommas(paymentFeeAmount)} USD`
    },
    {
      label: 'Processor fee per transaction',
      value: `${formatNumberWithCommas(feePerTransaction)} USD`
    },
    { label: `Tax: (${taxFee}%)`, value: `${formatNumberWithCommas(taxAmount)} USD` },
  ];

  return (
    <Box p={2}>
      <Box sx={{ backgroundColor: '#f3f3f6', borderRadius: '8px' }}>
        <Typography
          sx={{
            color: COLOR_GRAY_BLUE,
            fontSize: '16px',
            padding: '10px 12px',
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }}
        >
          Summary
        </Typography>
        <Box sx={{ padding: '10px 12px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {summaryItems.map(({ label, value, icon }) => (
            <Box
              key={label}
              display="flex"
              justifyContent="space-between"
              alignItems='center'
            >
              <Typography sx={{ fontSize: '14px', color: COLOR_GRAY_BLUE }}>{label}</Typography>
              <Typography
                sx={{ fontSize: '14px', display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {icon && <img src={icon} alt={`${label} icon`} width={20} height={20} />}
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            padding: '10px 12px',
            borderTop: '1px solid rgba(145, 158, 171, 0.24)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '16px'
          }}
        >
          <Typography sx={{ color: COLOR_GRAY_BLUE }}>Total Amount</Typography>
          <Typography sx={{ color: 'rgb(51, 102, 255)' }}>
            {formatNumberWithCommas(totalAmount)} USD
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(BalanceSummary);
