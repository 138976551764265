import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr, withRouter } from '../../../_helpers_';
import {
  Page,
  Scrollbar,
  CustomCircularProgress,
  PaypalButton,
  CustomNumberFormat,
  Label
} from '../../../components';
import { IconArrowLeft } from '@tabler/icons';
import { request } from '../../../_services_';
import clsx from 'clsx';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import {
  Container,
  Stack,
  Typography,
  Box,
  Card,
  Grid,
  Button,
  Checkbox,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@mui/material';
import { styled, withStyles } from '@mui/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import validate from 'validate.js';
import { showLoading, hideLoading } from '../../../_actions_';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils';
import { renderQRCode } from '../../../_helpers_/helper';

const optionStates = [
  { value: 'processing', label: 'Processing', color: 'info' },
  { value: 'success', label: 'Success', color: 'success' },
  { value: 'failed', label: 'Failed', color: 'error' }
];

const PAYMENT_METHODS = [
  { value: 'paypal', label: 'Paypal' },
  { value: 'po', label: 'PO' },
  { value: 'bank_vnd', label: 'Bank VND' }
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: '#1e88e5',
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: '#1565c0',
    borderRadius: '50%',
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 210,
    height: 210,
    background: '#1565c0',
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

const useStyles = (theme) => ({
  root: {
    width: '100%',
    // marginTop: 5,
    overflowX: 'auto',
    borderRadius: 0
  },
  buttonSearch: {
    padding: 15,
    marginRight: -13,
    borderRadius: '0 7px 7px 0'
  },
  head_cell: {
    background: '#ECECEC',
    borderBottom: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  dynamic_col: {
    marginBottom: 0,
    minWidth: '100%',
    tableLayout: 'fixed',
    background: '#fff',
    '&>tbody>tr>td': {
      borderBottom: '1px solid #ebeff3',
      borderTop: 0
    },
    '& td': {
      // height: '85px'
    }
  },
  table: {
    minWidth: 700,
    '&>thead:first-child>tr:first-child>th': {
      borderTop: 0
    },
    '&>th': {
      textTransform: 'uppercase',
      whiteSpace: 'nowrap'
    },
    '&>thead>tr>th': {
      padding: '0px 10px'
    },
    '&>tbody>tr>td': {
      position: 'relative',
      verticalAlign: 'middle',
      padding: '0px 10px',
      height: '65px',
      lineHeight: '1.42857'
    },
    '& p': {
      margin: 0
    },
    '& a': {
      color: '#2c5cc5',
      textDecoration: 'none'
    },
    '& .inline-wrapper': {
      boxShadow: '0 2px 5px 0 rgba(39,49,58,.15)',
      opacity: 1,
      background: '#fff',
      border: '1px solid #ebeff3',
      borderRadius: '20px',
      minHeight: '33px',
      padding: '7px 32px 7px 5px',
      position: 'absolute',
      right: '6px',
      top: '50%',
      transform: 'translateY(-50%)'
      // visibility: 'hidden',
    },
    '& .inline-actions': {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      margin: 0,
      paddingLeft: 0,
      listStyle: 'none',
      '& li': {
        padding: '0 10px',
        display: 'inline-block'
      }
    },
    table_floating: {
      width: '80%',
      float: 'left',
      '& .dynamic_col': {
        maxWidth: 'none'
      }
    },
    cell_entry: {
      whiteSpace: 'nowrap',
      width: '100%'
    }
  }
});

const getAmount = (item) => {
  if (item.old_balance != null && item.new_balance != null) {
    return item.new_balance - item.old_balance;
  }
  return item.amount;
};

const getColor = (item) => {
  if (item.old_balance != null && item.new_balance != null) {
    return item.new_balance - item.old_balance > 0 ? 'green' : 'red';
  }
  return 'black';
};

class MyWalletForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      config: null,
      selectedItems: [],
      total_fund: 0,
      totals: 0,
      isLoading: false,
      queryObject: {
        page: 1,
        limit: 50
      },
      openTopup: false,
      formTopup: {
        values: {
          payment_method: 'paypal',
          transaction_amount: 0,
          transfer_content: ''
        },
        errors: {}
      },
      transaction_code: null
    };
    this.schema = {
      transaction_amount: {
        presence: { allowEmpty: false, message: '^Required' },
        numericality: {
          greaterThan: 0,
          message: '^Invalid amount'
        }
      },
      team_id: {
        presence: { allowEmpty: false, message: '^Required' }
      }
    };
  }

  componentDidMount() {
    this.getData(this.state.queryObject);
  }

  getData = (data) => {
    const { dispatch } = this.props;
    dispatch(showLoading());
    request.get('/api/v1/my_wallet', data).then(
      (res) => {
        if (res.data.success) {
          dispatch(hideLoading());
          this.setState({
            total_fund: res.data.data.total_fund,
            items: res.data.data.histories,
            totals: res.data.data.total
          });
        } else {
          toastr.error(res.data.msg);
          dispatch(hideLoading());
        }
      },
      (err) => {
        toastr.error(err);
        dispatch(hideLoading());
      }
    );
  };

  handlePageChange = (event, page) => {
    const queryObject = Object.assign({}, this.state.queryObject);
    queryObject.page = page + 1;
    this.setState({ queryObject }, () => {
      this.getData(this.state.queryObject);
    });
  };

  handleRowsPerPageChange = (event) => {
    const queryObject = Object.assign({}, this.state.queryObject);
    queryObject.page = 1;
    queryObject.limit = event.target.value;
    this.setState({ queryObject }, () => {
      this.getData(this.state.queryObject);
    });
  };

  handleCheckAll = (event) => {
    let selectedItems;
    if (event.target.checked) {
      selectedItems = this.state.items.map((t) => t.id);
    } else {
      selectedItems = [];
    }
    this.setState({ selectedItems });
  };

  handleCheck = (event, id) => {
    const selectedItems = Object.assign([], this.state.selectedItems);
    const index = selectedItems.indexOf(id);
    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(id);
      }
    } else {
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    this.setState({ selectedItems });
  };

  validateForm = () => {
    const formTopup = Object.assign({}, this.state.formTopup);
    const errors = validate(formTopup.values, this.schema);
    formTopup.isValid = errors ? false : true;
    formTopup.errors = errors || {};
    return formTopup;
  };

  openTopupModal = () => {
    const { dispatch, user } = this.props;
    if (user) {
      dispatch(showLoading());
      request.get('/api/v1/all_configs').then(
        (res) => {
          dispatch(hideLoading());
          if (res.data.success) {
            const formTopup = Object.assign({}, this.state.formTopup);
            formTopup.values['transaction_amount'] = 0;
            formTopup.values['team_id'] = user.team_id
              ? { id: user.team_id.team_id, name: user.team_id.team_search }
              : null;
            this.setState({ formTopup, openTopup: true, config: res.data.data });
          } else {
            toastr.error(res.data.msg);
          }
        },
        (err) => {
          dispatch(hideLoading());
          toastr.error(err);
        }
      );
    }
  };

  onClose = () => {
    this.setState({ openTopup: false });
  };

  handleChange = (event) => {
    const { user } = this.props;
    const formTopup = Object.assign({}, this.state.formTopup);
    const { name, value } = event.target;
    formTopup.values[event.target.name] = event.target.value;
    if ((name == 'payment_method' && value == 'bank_vnd') || name == 'team_id') {
      formTopup.values['transfer_content'] =
        `Team ${formTopup.values.team_id?.id} ${user.shortname}`;
    }
    this.setState({ formTopup });
  };

  onDataProcess = (amount) => {
    const { dispatch } = this.props;
    dispatch(showLoading());
    return new Promise((resolve) => {
      const formTopup = this.validateForm();
      if (formTopup.isValid) {
        const query = {
          transaction_amount: amount,
          team_id: formTopup.values.team_id.id
        };
        request.post('/api/v1/wallet_transactions', query).then(
          (res) => {
            if (res.data.success) {
              this.setState({ transaction_code: res.data.data });
              resolve(true);
              dispatch(hideLoading());
            } else {
              toastr.error(res.data.msg);
              resolve(false);
              dispatch(hideLoading());
            }
          },
          (error) => {
            toastr.error(error?.response?.data?.msg || error.toString());
            resolve(false);
            dispatch(hideLoading());
          }
        );
      } else {
        this.setState({ formTopup });
        resolve(false);
        dispatch(hideLoading());
      }
    });
  };

  onSuccess = (payment) => {
    const query = {
      paymentId: payment.paymentID,
      paymentToken: payment.paymentToken,
      code: this.state.transaction_code
    };
    const { dispatch } = this.props;
    dispatch(showLoading());
    request.put('/api/v1/authorize_topup', query).then(
      (res) => {
        if (res.data.success) {
          this.onClose();
          toastr.success('Topup successfully');
          dispatch(hideLoading());
          this.getData(this.state.queryObject);
        } else {
          toastr.error('Topup unsuccessful');
          this.onClose();
          dispatch(hideLoading());
        }
      },
      (err) => {
        toastr.error(err);
        dispatch(hideLoading());
      }
    );
  };

  render() {
    const { navigate, classes } = this.props;
    const { items, selectedItems, queryObject, totals, formTopup, config } = this.state;

    return (
      <Page title="My Wallet">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div className="d-flex align-items-center">
              <IconArrowLeft cursor={'pointer'} size={24} stroke={2} onClick={() => navigate(-1)} />
              <Typography variant="h4" marginLeft={5}>
                Payment
              </Typography>
            </div>
          </Stack>
          <Scrollbar>
            <div className="d-flex justify-content-center">
              <CardWrapper className="wallet-card">
                <Box sx={{ p: 2 }}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography sx={{ fontSize: '1.2rem', fontWeight: 500, mr: 1 }}>
                        Fund Remaining
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{ fontSize: '2rem', fontWeight: 500, mr: 1, letterSpacing: 3 }}
                      >
                        {this.state.total_fund.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 2
                        })}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ mb: 1.25, mt: 1 }}>
                      <Button
                        variant="outlined"
                        className="button-topup"
                        startIcon={<ExitToAppIcon />}
                        onClick={this.openTopupModal}
                      >
                        Topup to fund
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardWrapper>
            </div>
            <Box fontSize={20} fontWeight="bold" mt={2}>
              Transaction History of Fund
            </Box>
            <div className="mt-2" style={{ border: '1px solid #ccc', borderRadius: 6 }}>
              <Paper
                className={classes.root}
                style={{ position: 'relative', borderRadius: '6px 6px 0px 0px' }}
              >
                <TableContainer style={{ maxHeight: '54vh' }} className={classes.table}>
                  <Table
                    stickyHeader
                    className={clsx(
                      classes.dynamic_col,
                      classes.dynamic_sticky,
                      classes.table,
                      'dynamic_col'
                    )}
                  >
                    <TableHead className={classes.head_white}>
                      <TableRow>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '3rem' }}
                          align="center"
                        >
                          <Checkbox
                            checked={selectedItems.length === items.length}
                            color="secondary"
                            indeterminate={
                              selectedItems.length > 0 && selectedItems.length < items.length
                            }
                            onChange={this.handleCheckAll}
                          />
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '10rem' }}
                        >
                          <span className="line-clamp-1">Time</span>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '8rem' }}
                        >
                          <span className="line-clamp-1">Team ID</span>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '9rem' }}
                        >
                          <span className="line-clamp-1">Amount USD</span>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '10rem' }}
                        >
                          <span className="line-clamp-1">Fund Remaining</span>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '15rem' }}
                        >
                          <span className="line-clamp-1">Description</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.items.map((item, index) => (
                        <TableRow key={item.id} className="cursor-pointer" onClick={() => {}}>
                          <TableCell
                            onClick={(e) => e.stopPropagation()}
                            padding="checkbox"
                            align="center"
                            className={clsx(classes.cell_entry)}
                            style={{ width: '5rem' }}
                          >
                            <Checkbox
                              checked={selectedItems.indexOf(item['id']) !== -1}
                              color="secondary"
                              onChange={(event) => this.handleCheck(event, item['id'])}
                              value="true"
                            />
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <div
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <div className={classes.content_inline}>
                                <span
                                  className="text_2"
                                  style={{
                                    cursor: this.props.onClickRow ? 'pointer' : 'text',
                                    flex: 1,
                                    maxWidth: 'calc(100%)',
                                    wordBreak: 'break-all'
                                  }}
                                >
                                  <div
                                    className={classes.text_ellipsis}
                                    style={{ color: getColor(item) }}
                                  >
                                    {item.payment_date
                                      ? moment(item.payment_date).format('DD/MM/YYYY HH:mm:ss')
                                      : ''}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <div
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <div className={classes.content_inline}>
                                <span
                                  className="text_2"
                                  style={{
                                    cursor: this.props.onClickRow ? 'pointer' : 'text',
                                    flex: 1,
                                    maxWidth: 'calc(100%)',
                                    wordBreak: 'break-all'
                                  }}
                                >
                                  <div
                                    className={classes.text_ellipsis}
                                    style={{ color: getColor(item) }}
                                  >
                                    {item.team_id?.team_search}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <div
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <div className={classes.content_inline}>
                                <span
                                  className="text_2"
                                  style={{
                                    cursor: this.props.onClickRow ? 'pointer' : 'text',
                                    flex: 1,
                                    maxWidth: 'calc(100%)',
                                    wordBreak: 'break-all'
                                  }}
                                >
                                  <div
                                    className={classes.text_ellipsis}
                                    style={{ color: getColor(item) }}
                                  >
                                    {item.amount?.toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                      maximumFractionDigits: 2
                                    })}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <div
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <div className={classes.content_inline}>
                                <span
                                  className="text_2"
                                  style={{
                                    cursor: this.props.onClickRow ? 'pointer' : 'text',
                                    flex: 1,
                                    maxWidth: 'calc(100%)',
                                    wordBreak: 'break-all'
                                  }}
                                >
                                  <div
                                    className={classes.text_ellipsis}
                                    style={{ color: getColor(item) }}
                                  >
                                    {item.new_balance?.toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                      maximumFractionDigits: 2
                                    })}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={clsx(classes.cell_entry)}>
                            <div
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <div className={classes.content_inline}>
                                <span
                                  className="text_2"
                                  style={{
                                    cursor: this.props.onClickRow ? 'pointer' : 'text',
                                    flex: 1,
                                    maxWidth: 'calc(100%)',
                                    wordBreak: 'normal'
                                  }}
                                >
                                  <div
                                    className={classes.text_ellipsis}
                                    style={{ color: getColor(item) }}
                                  >
                                    {item.content}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {this.state.isLoading ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 100,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'rgba(255,255,255,0.8)'
                    }}
                  >
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <CustomCircularProgress />
                    </div>
                  </div>
                ) : null}
              </Paper>
              <TablePagination
                className="list-pagination"
                style={{
                  borderTop: '1px solid #D8D8D8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
                component="div"
                count={totals}
                onPageChange={this.handlePageChange}
                onRowsPerPageChange={this.handleRowsPerPageChange}
                page={queryObject.page - 1}
                rowsPerPage={queryObject.limit}
                rowsPerPageOptions={[15, 20, 50, 100]}
              />
            </div>
          </Scrollbar>
        </Container>
        <BootstrapDialog
          onClose={this.onClose}
          aria-labelledby="modal-form-dialog"
          open={this.state.openTopup}
          maxWidth={'sm'}
          fullWidth
        >
          <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: 2 }}>
            <span>Topup to Fund</span>
            <IconButton
              aria-label="close"
              onClick={this.onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <div className="p-2">
              <div>
                <Box fontSize={15} fontWeight="bold">
                  Team ID <span style={{ color: 'red' }}>*</span>
                </Box>
                <AsyncSelect
                  className="MuiFormControl-marginDense"
                  cacheOptions
                  isDisabled
                  loadOptions={(inputValue) =>
                    loadOptions(inputValue, function loadingData(inputValue) {
                      return new Promise((resolve) => {
                        request.get('/api/v1/teams', { search: inputValue }).then((res) => {
                          if (res.data.success) {
                            resolve(
                              res.data.data.items.map((e) => ({
                                id: e.team_id,
                                name: `Team_${e.team_id}`,
                                type_acc: e.type_acc
                              }))
                            );
                          }
                        });
                      });
                    })
                  }
                  defaultOptions
                  onInputChange={handleInputChange}
                  isSearchable
                  name={'team_id'}
                  onChange={(value) => {
                    var e = {
                      target: {
                        name: 'team_id',
                        value
                      }
                    };
                    this.handleChange(e);
                  }}
                  placeholder={'Team'}
                  menuPortalTarget={document.body}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  valueKey={'id'}
                  value={formTopup?.values.team_id || null}
                  styles={formTopup.errors?.team_id ? errorStyles : customStyles}
                />
                {formTopup.errors?.team_id && (
                  <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>
                    {formTopup.errors.team_id[0]}
                  </span>
                )}
              </div>
              <div className="mt-2">
                <Box fontSize={15} fontWeight="bold">
                  Payment Method
                </Box>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="payment_method"
                  value={formTopup.values.payment_method}
                  onChange={this.handleChange}
                  row
                >
                  <FormControlLabel
                    value="paypal"
                    control={<Radio />}
                    disabled={!formTopup.values.team_id}
                    label="Paypal"
                  />
                  <FormControlLabel value="po" control={<Radio />} disabled label="Payoneer" />
                  <FormControlLabel
                    value="bank_vnd"
                    control={<Radio />}
                    disabled={!formTopup.values.team_id}
                    label="Bank VND"
                  />
                </RadioGroup>
              </div>
              <div className="mt-2">
                <Box fontSize={15} fontWeight="bold">
                  Transaction Amount ($)
                </Box>
                <TextField
                  fullWidth
                  margin="dense"
                  value={formTopup.values.transaction_amount}
                  name="transaction_amount"
                  onChange={this.handleChange}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                    inputComponent: CustomNumberFormat
                  }}
                />
              </div>
              {formTopup.values.payment_method == 'paypal' &&
                (Number.parseFloat(config?.paypal_fee_topup || 0) != 0 ||
                  Number.parseFloat(config?.paypal_fee_per_transaction || 0) != 0) && (
                  <Box fontSize={12} color="#979CA1">
                    Paypal transactions will incur a {config?.paypal_fee_topup}% + $
                    {config?.paypal_fee_per_transaction} processor fee per transaction.
                  </Box>
                )}
              {formTopup.values.payment_method == 'paypal' &&
                Number.parseFloat(config?.paypal_fee_topup || 0) == 0 &&
                Number.parseFloat(config?.paypal_fee_per_transaction || 0) == 0 && (
                  <Box fontSize={12} color="#979CA1">
                    Paypal transactions will not incur processor fee.
                  </Box>
                )}
              {formTopup.values.payment_method == 'bank_vnd' &&
                formTopup.values.transaction_amount > 0 && (
                  <>
                    <div className="mt-2">
                      <Box fontSize={15} fontWeight="bold">
                        Rate
                      </Box>
                      <TextField
                        fullWidth
                        margin="dense"
                        value={config?.rate_vnd}
                        disabled
                        name="rate_vnd"
                        variant="outlined"
                        InputProps={{
                          classes: {
                            notchedOutline: 'notchedOutline'
                          },
                          inputComponent: CustomNumberFormat
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <Box fontSize={15} fontWeight="bold">
                        Transaction Amount (VND)
                      </Box>
                      <TextField
                        fullWidth
                        margin="dense"
                        value={formTopup.values.transaction_amount * config.rate_vnd}
                        disabled
                        name="transaction_amount_vnd"
                        variant="outlined"
                        InputProps={{
                          classes: {
                            notchedOutline: 'notchedOutline'
                          },
                          inputComponent: CustomNumberFormat
                        }}
                      />
                    </div>
                  </>
                )}
              {formTopup.values.payment_method == 'bank_vnd' && (
                <div className="mt-2">
                  <Box fontSize={15} fontWeight="bold">
                    Transfer Content
                  </Box>
                  <TextField
                    fullWidth
                    margin="dense"
                    value={formTopup.values.transfer_content}
                    disabled
                    name="transfer_content"
                    variant="outlined"
                    InputProps={{
                      classes: {
                        notchedOutline: 'notchedOutline'
                      }
                    }}
                  />
                </div>
              )}
              {formTopup.values.payment_method == 'bank_vnd' &&
                formTopup.values.transaction_amount > 0 && (
                  <div className="mt-2">
                    <img
                      src={renderQRCode({
                        amount: formTopup.values.transaction_amount * config.rate_vnd,
                        content: formTopup.values.transfer_content
                      })}
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="mr-2"
              color="secondary"
              variant="outlined"
              onClick={this.onClose}
              style={{ borderRadius: 4 }}
            >
              Close
            </Button>
            {formTopup.values.payment_method == 'paypal' && (
              <PaypalButton
                currency={'USD'}
                height={36}
                amount={Number.parseFloat(formTopup.values.transaction_amount) || 0}
                onSuccess={(payment) => this.onSuccess(payment)}
                onDataProcess={this.onDataProcess}
              />
            )}
          </DialogActions>
        </BootstrapDialog>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(withStyles(useStyles)(withRouter(MyWalletForm)));
