import React, { useState, useEffect } from 'react';
import Page from '../../../components/Page';
import { Box, Button, Container, FormControl, MenuItem, Stack, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import PaymentHeader from '../../../components/payment/payment-header';
import Select from '@mui/material/Select';
import { PaymentTabs, AD_FILTER } from '../../../_constants_';
import BalanceHistory from '../../../components/payment/balance-history';
import { getMyWallet } from '../../../_actions_';
import { useDispatch, useSelector } from 'react-redux';
import BalanceAllocation from '../../../components/payment/balance-allocation';
import AdAccountTransactionHistory from '../../../components/payment/ad-account-transaction';
import RefundHistory from '../../../components/payment/refund-history';

export default function PaymentPage() {
  const [tab, setTab] = useState(PaymentTabs.balance_allocation);
  const [statusAd, setStatusAd] = useState(AD_FILTER[0].value);
  const navigate = useNavigate();
  const location = useLocation();
  const dataWallet = useSelector((state) => state?.payment?.dataWallet);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataWallet) {
      dispatch(getMyWallet());
    }
  }, []);

  // param url
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');

    if (tabParam) {
      switch (parseInt(tabParam, 10)) {
        case PaymentTabs.balance_allocation:
          setTab(PaymentTabs.balance_allocation);
          break;
        case PaymentTabs.add_account:
          setTab(PaymentTabs.add_account);
          break;
        case PaymentTabs.balance_history:
          setTab(PaymentTabs.balance_history);
          break;
        case PaymentTabs.refund:
          setTab(PaymentTabs.refund);
          break;
        default:
          setTab(PaymentTabs.balance_allocation);
      }
    }
  }, [location.search]);

  const handleTabChange = (value) => {
    setTab(value);
    navigate(`?tab=${value}`);
  };

  return (
    <Page title="SpendAds | Payment">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box className="d-flex align-items-center">
            <Typography variant="h4">Payment</Typography>
          </Box>
        </Stack>

        <PaymentHeader totalFund={dataWallet?.total_fund} />

        <Box mt={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid gray',
              minHeight: '56px'
            }}
          >
            <Box display="flex" gap={2}>
              <Button
                variant="text"
                onClick={() => handleTabChange(PaymentTabs.balance_allocation)}
                className={tab === PaymentTabs.balance_allocation ? 'tab-item-active' : 'tab-item'}
              >
                Balance Allocation
              </Button>
              <Button
                variant="text"
                onClick={() => handleTabChange(PaymentTabs.add_account)}
                className={tab === PaymentTabs.add_account ? 'tab-item-active' : 'tab-item'}
              >
                Ad Account Transaction History
              </Button>
              <Button
                variant="text"
                onClick={() => handleTabChange(PaymentTabs.balance_history)}
                className={tab === PaymentTabs.balance_history ? 'tab-item-active' : 'tab-item'}
              >
                Balance History
              </Button>
              <Button
                variant="text"
                onClick={() => handleTabChange(PaymentTabs.refund)}
                className={tab === PaymentTabs.refund ? 'tab-item-active' : 'tab-item'}
              >
                Refund History
              </Button>
            </Box>
            {tab === PaymentTabs.balance_allocation && (
              <Box>
                <FormControl sx={{ mb: 1, minWidth: 240 }} size="small">
                  <Select
                    value={statusAd}
                    onChange={(event) => {
                      setStatusAd(event.target.value);
                    }}
                  >
                    {AD_FILTER.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
          <Box p={1}>
            {tab === PaymentTabs.balance_allocation && <BalanceAllocation statusAd={statusAd} />}
            {tab === PaymentTabs.add_account && <AdAccountTransactionHistory />}
            {tab === PaymentTabs.balance_history && <BalanceHistory />}
            {tab === PaymentTabs.refund && <RefundHistory />}
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
