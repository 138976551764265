import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { authentication } from './authentication';
import { locale } from './locale';
import { alert } from './alert';
import { loading } from './loading';
import { fetchTeam } from './data';
import { notification } from './notification';
import { payment } from './payment';
import { adAccount } from './ad';
import { user } from './user';
import { productLink } from './product_link';

const rootReducer = combineReducers({
  alert,
  authentication,
  locale,
  loadingBar: loadingBarReducer,
  loading,
  fetchTeam,
  notification,
  payment,
  adAccount,
  user,
  productLink
});

export default rootReducer;
