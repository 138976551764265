import * as t from '../_constants_/redux';
import { request } from '../_services_';
import { toastr } from '../_helpers_';

export const updatePayment = (data) => {
  return { type: t.DATA_WALLET, payload: data };
};

export const getMyWallet =
  (query = {}) =>
  async (dispatch) => {
    request
      .get('/api/v1/my_wallet', query)
      .then((res) => {
        const { success, data, msg } = res.data;
        if (success) {
          dispatch({
            type: t.DATA_WALLET,
            payload: data
          });
        } else {
          toastr.error(msg);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };

export const getAdsTransactionHistory = (query) => async (dispatch) => {
  request
    .get('/api/v1/tiktok_ads_account_transactions', query)
    .then((res) => {
      const { success, data, msg } = res.data;
      if (success) {
        dispatch({
          type: t.DATA_TRANSACTION_HISTORY,
          payload: data
        });
      } else {
        toastr.error(msg);
      }
    })
    .catch((err) => {
      toastr.error(err);
    });
};

export const addFundForAdvertiser = (payload) => async (dispatch) => {
  try {
    const res = await request.post_form_data('/api/v1/add_fund_for_advertiser', payload);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.DATA_ADD_FUND_ADVERTISER,
        payload: data
      });
      toastr.success('Add Fund successful!');
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
    dispatch({
      type: t.ERROR,
      payload: {
        key: 'addFundForAdvertiserErrors',
        errors: err.response.data
      }
    });
  }
};

export const withdrawForAdvertiser = (payload) => async (dispatch) => {
  try {
    const res = await request.post_form_data('/api/v1/withdraw_for_advertiser', payload);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.DATA_WITHDRAW_ADVERTISER,
        payload: data
      });
      toastr.success('Withdraw successful!');
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
    dispatch({
      type: t.ERROR,
      payload: {
        key: 'withdrawForAdvertiserErrors',
        errors: err.response.data
      }
    });
  }
};

export const createRefund = (payload) => async (dispatch) => {
  try {
    const res = await request.post_form_data('/api/v1/refund_requests', payload);
    if (res.data.success) {
      dispatch({
        type: t.CREATE_REFUND,
        payload: res.data.data
      });
      toastr.success('Create refund successful!');
    }
  } catch (err) {
    toastr.error(err);
    dispatch({
      type: t.ERROR,
      payload: {
        key: 'createRefundError',
        errors: err.response.data
      }
    });
  }
};

export const getRefundHistory = (query) => async (dispatch) => {
  request
    .get('/api/v1/get_refund_transaction_histories', query)
    .then((res) => {
      const { success, data, msg } = res.data;
      if (success) {
        dispatch({
          type: t.DATA_REFUND_HISTORY,
          payload: data
        });
      } else {
        toastr.error(msg);
      }
    })
    .catch((err) => {
      toastr.error(err);
    });
};

export const getTiktokFinance = (team_id, data) => async (dispatch) => {
  request
    .get(`/api/v1/tiktok_finance_by_team/${team_id}`, data)
    .then((res) => {
      const { success, data, msg } = res.data;
      if (success) {
        dispatch({
          type: t.DATA_TIKTOK_FINANCE,
          payload: data
        });
      } else {
        toastr.error(msg);
      }
    })
    .catch((err) => {
      toastr.error(err);
    });
};
