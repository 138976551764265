import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { ModalForm, ResourceList, DrawerForm } from '../../../components';
import { fields, formField, filters } from './constants';
import { isManager, toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import { showLoading, hideLoading } from '../../../_actions_';
import ServicePopup from './ServicePopup';
import { checkRequired, checkVisible, schemaArray } from '../../../utils';
import { validate } from 'validate.js';

const checkServiceAA1 = (submitData) => {
  let wallet = Number.parseFloat(submitData.values?.wallet_a)
  if (submitData.values.product_id?.code == 'A') {
    wallet += Number.parseFloat(submitData.values?.fee)
  }
  let topup = 0
  submitData.values.accounts?.map((acc) => {
    topup += Number.parseFloat(acc.money || 0)
  })
  if (wallet < topup && submitData.values.product_id?.code == 'A1') {
    return {msg: 'Money topup to account must be equal or less than wallet remaining'}
  }
  return false
}

class ServiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
      isSmallScreen: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const isSmallScreen = window.innerWidth < 850;
    this.setState({ isSmallScreen });
  };

  createService = (ctx) => {
    const { dispatch } = this.props;
    dispatch(showLoading())
    request.get(`/api/v1/team/${ctx.props.user.team_id?.team_id}`).then(res => {
      if (res.data.success) {
        const data = {
          team_id: res.data.data.result,
          extra_field: [],
        }
        dispatch(hideLoading())
        ModalForm.instance.current.openForm({
          title: "Buy Service",
          data,
          customView: (submitData, handleChange, ctxModal) => (
            <ServicePopup 
              submitData={submitData}
              handleChange={handleChange}
              user={this.props.user}
              ctx={ctxModal}
            />
          ),
          multipleActions: true,
          actions: [
            {
              titleAction: 'Save',
              visible: (submitData, ctxModal) => !ctxModal.state.openForm,
              schema: (submitData) => {
                var schema = {
                  product_id: {
                    presence: {allowEmpty: false, message: '^Required'}
                  }
                }
                if (submitData.values.product_id?.code == 'A') {
                  schema['fud_id'] = {
                    presence: {allowEmpty: false, message: '^Required'}
                  }
                } else if (['C', 'D'].includes(submitData.values.product_id?.code)) {
                  schema['num_account'] = {
                    presence: {allowEmpty: false, message: '^Required'},
                    numericality: {
                      greaterThan: 0,
                      message: '^Must be greater than 0'
                    }
                  }
                } else if (submitData.values.product_id?.code == 'B') {
                  schema['package_id'] = {
                    presence: {allowEmpty: false, message: '^Required'},
                  }
                  if (submitData.values.option == 'upgrade_fud'){
                    schema['fud_id'] = {
                      presence: {allowEmpty: false, message: '^Required'},
                    }
                  }
                } else {
                  const keys = submitData.values.product_id?.extra_field?.filter(field => checkVisible(field, submitData.values)).filter(field => checkRequired(field, submitData.values)).map(field => field.key) || []
                  keys.map(key => {
                    schema[key] = {
                      presence: {allowEmpty: false, message: '^Required'}
                    }
                  })
                }
                if (submitData.values.product_id?.dynamic_fee) {
                  schema['fee'] = {
                    presence: {allowEmpty: false, message: '^Required'},
                    numericality: {
                      greaterThan: 0,
                      message: '^Must be greater than 0'
                    }
                  }
                }
                return schema
              },
              onAction: (submitData, ctxModal) => {
                return new Promise((resolve) => {
                  const allKeys = Object.keys(submitData.values)
                  var query = {}
                  if (['A', 'A1'].includes(submitData.values.product_id?.code)) {
                    const fund_remaining = submitData.values.fud_id?.remaining_topup
                    if (fund_remaining < Number.parseFloat(submitData.values.fee)) {
                      toastr.error("Fund is not enough")
                      resolve(false)
                      return
                    }
                    if (submitData.values.product_id?.code == 'A1') {
                      if (!submitData.values.accounts) {
                        toastr.error("Accounts required")
                        resolve(false)
                        return
                      }
                      const inValid = checkServiceAA1(submitData)
                      if (inValid) {
                        toastr.error(inValid.msg)
                        resolve(false)
                        return
                      }
                    }
                    const keys = allKeys.filter(e => e != 'extra_field')
                    keys.map(key => {
                      if (key == 'team_id') {
                        query[key] = submitData.values[key].team_id
                      } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                        query[key] = submitData.values[key]?.id || 0
                      } else {
                        query[key] = submitData.values[key]
                      }
                    })
                    if (submitData.values.product_id?.code == 'A1') {
                      query['extra_field'] = submitData.values.extra_field
                    }
                  } else if (submitData.values.product_id?.code == 'B') {
                    if (submitData.values.option == 'upgrade_fud') {
                      const old_package_money = submitData.values.fud_id?.package_money || 0
                      const new_package_money = submitData.values.package_id?.total_topup_30days || 0
                      if (old_package_money >= new_package_money) {
                        toastr.error("Level is invalid")
                        resolve(false)
                        return
                      }
                    }
                    const keys = allKeys.filter(e => e != 'extra_field')
                    keys.map(key => {
                      if (key == 'team_id') {
                        query[key] = submitData.values[key].team_id
                      } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                        query[key] = submitData.values[key]?.id || 0
                      } else {
                        query[key] = submitData.values[key]
                      }
                    })
                  } else if (['C', 'D'].includes(submitData.values.product_id?.code)) {
                    const keys = allKeys.filter(e => e != 'extra_field')
                    keys.map(key => {
                      if (key == 'team_id') {
                        query[key] = submitData.values[key].team_id
                      } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                        query[key] = submitData.values[key]?.id || 0
                      } else {
                        query[key] = submitData.values[key]
                      }
                    })
                  } else {
                    allKeys.map(key => {
                      if (key == 'team_id') {
                        query[key] = submitData.values[key].team_id
                      } else if (key == 'extra_field') {
                        query[key] = submitData.values[key]
                      } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                        query[key] = submitData.values[key]?.id || 0
                      } else {
                        query[key] = submitData.values[key]
                      }
                    })
                  }
                  if (Object.keys(query).includes('extra_field')) {
                    const field_array = query['extra_field'].find(e => e.type == 'array')
                    if (field_array) {
                      const schema = schemaArray(field_array)
                      let num_errors = 0
                      field_array.value.map(f_value => {
                        const errors = validate(f_value, schema)
                        f_value['_errors'] = errors
                        if (errors) {
                          num_errors += 1
                        }
                      })
                      if (num_errors > 0) {
                        resolve(false)
                        return
                      }
                    }
                  }
                  request.post('/api/v1/services', query).then(res => {
                    if (res.data.success) {
                      toastr.success(`Buy service successfully ${submitData.values.product_id?.code == 'B' ? 'with FUD ' + res.data.data.fud_id : ''}`)
                      if (submitData.values.product_id?.code == 'A' && submitData.values.topup_to_account == 1) {
                        resolve(false)
                        ctxModal.setState({ openForm: true })
                        const data = Object.assign({}, submitData)
                        data.values['product_id'] = res.data.data.product_id
                        data.values['wallet_a'] = res.data.data.wallet_a
                        data.values['note'] = ""
                        data.values['fee'] = res.data.data.product_id.price
                        delete data.values['fud_id']
                        delete data.values['topup_to_account']
                        ctxModal.setState({ submitData: data })
                        return
                      } else {
                        resolve(true)
                        ctx.getListItems(ctx.state.searchQuery)
                      }
                    } else {
                      toastr.error(res.data.msg)
                      resolve(false)
                    }
                  }, err => {
                    resolve(false)
                    toastr.error(err)
                  })
                })
              }
            },
            {
              titleAction: 'Save', // Save A1
              visible: (submitData, ctxModal) => ctxModal.state.openForm,
              schema: (submitData) => {
                var schema = {
                  product_id: {
                    presence: {allowEmpty: false, message: '^Required'}
                  }
                }
                const keys = submitData.values.product_id?.extra_field?.filter(field => checkVisible(field, submitData.values)).filter(field => checkRequired(field, submitData.values)).map(field => field.key) || []
                keys.map(key => {
                  schema[key] = {
                    presence: {allowEmpty: false, message: '^Required'}
                  }
                })
                return schema
              },
              onAction: (submitData, ctxModal) => {
                return new Promise((resolve) => {
                  const allKeys = Object.keys(submitData.values)
                  var query = {}
                  const inValid = checkServiceAA1(submitData)
                  if (inValid) {
                    toastr.error(inValid.msg)
                    resolve(false)
                    return
                  }
                  const keys = allKeys.filter(e => e != 'extra_field')
                  keys.map(key => {
                    if (key == 'team_id') {
                      query[key] = submitData.values[key].team_id
                    } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
                      query[key] = submitData.values[key]?.id || 0
                    } else if(key == 'discount_fee') {
                      return
                    } else {
                      query[key] = submitData.values[key]
                    }
                  })
                  query['extra_field'] = submitData.values.extra_field
                  if (Object.keys(query).includes('extra_field')) {
                    const field_array = query['extra_field'].find(e => e.type == 'array')
                    if (field_array) {
                      const schema = schemaArray(field_array)
                      let num_errors = 0
                      field_array.value.map(f_value => {
                        const errors = validate(f_value, schema)
                        f_value['_errors'] = errors
                        if (errors) {
                          num_errors += 1
                        }
                      })
                      if (num_errors > 0) {
                        resolve(false)
                        return
                      }
                    }
                  }
                  request.post('/api/v1/services', query).then(res => {
                    if (res.data.success) {
                      toastr.success(`Buy service successfully ${submitData.values.product_id?.code == 'B' ? 'with FUD ' + res.data.data.fud_id : ''}`)
                      resolve(true)
                      ctx.getListItems(ctx.state.searchQuery)
                      ctxModal.setState({ openForm: false })
                    } else {
                      toastr.error(res.data.msg)
                      resolve(false)
                    }
                  }, err => {
                    resolve(false)
                    toastr.error(err)
                  })
                })
              }
            }
          ]
        })
      } else {
        toastr.error("An error occurred while fetching data")
        // isLoading = false
      }
    }, err => {
      toastr.error(err)
      // isLoading = false
    })
  }

  render () {
    const { navigate } = this.props;
    const { open, mode, objectId, isSmallScreen } = this.state;

    return (
      <>
        <ResourceList
          rowActive={objectId}
          route="/api/v1/services"
          titlePage="SpendAds | Services"
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.createService(ctx),
              visible: isManager(this.props.user?.role)
            }
          ]}
          title="Service ID"
          fields={fields}
          filters={filters}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
        />
        <DrawerForm 
          width={isSmallScreen ? '80%' : '35%'}
          fields={formField}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/service/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Service Detail"
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ServiceList)