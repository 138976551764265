import { Box, Card, Typography } from '@mui/material';
import { MHidden } from '../@material-extend';
import React from 'react';
import { styled } from '@mui/material/styles';
import Logo from '../Logo';
import { Link as RouterLink } from 'react-router-dom';
import { COLOR_WHITE } from '../../_constants_';

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: 464,
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  left: 0,
  bottom: 0,
  top: 0,
  justifyContent: 'space-between'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  flex: 3,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  color: 'black',
  lineHeight: '18px',
  padding: theme.spacing(6, 12),
  backgroundColor: COLOR_WHITE,
  marginLeft: 500,
  [theme.breakpoints.down('md')]: {
    marginLeft: 'unset',
    padding: theme.spacing(12, 5, 12, 6)
  }
}));

export default function LayoutStatic({ children }) {
  return (
    <RootStyle>
      <MHidden width="mdDown">
        <SectionStyle>
          <Box flex={1} p={2}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
          <Box flex={3}>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </Box>
        </SectionStyle>
      </MHidden>
      <ContentStyle>
        {children}
        <hr />
        <Box>
          <Typography variant="h5" mb={1}>
            SpendAds - The best official TikTok Agency for E-commerce
          </Typography>
          <Typography mb={1}>
            Website:{' '}
            <a href="https://spendads.com/" target={'_blank'}>
              https://spendads.com/
            </a>
          </Typography>
          <Typography mb={1}>WhatsApp: (+84)98110900</Typography>
          <Typography mb={1}>
            Phone number (hotline): <a href="tel:0981109000">0981109000</a>
          </Typography>
          <Typography mb={1}>
            Email: <a href="mailto:support@SpendAds.com">support@SpendAds.com</a>
          </Typography>
        </Box>
      </ContentStyle>
    </RootStyle>
  );
}
