import * as t from '../_constants_/redux';

const initialState = {
  dataRequestTiktokAcc: null,
  listRequests: null,
  dataCreate: null,
  createAdAccountErrors: null,
  dataLastRequest: null,
};

export function adAccount(state = initialState, action) {
  switch (action.type) {
    case t.CREATE_REQUEST_TIKTOK_ACC: {
      return {
        ...state,
        dataRequestTiktokAcc: action.payload,
        createAdAccountErrors: null,
      };
    }
    case t.LIST_REQUEST: {
      return {
        ...state,
        listRequests: action.payload,
      };
    }
    case t.DATA_REQUEST_CREATE: {
      return {
        ...state,
        dataCreate: action.payload,
      };
    }
    case t.LAST_REQUEST_AD: {
      return {
        ...state,
        dataLastRequest: action.payload,
      };
    }
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        errors: null,
      };
    case t.ERROR:
      return {
        ...state,
        [action.payload.key]: action.payload.errors,
      };
    default:
      return state;
  }
}
