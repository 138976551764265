/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearData,
  getAdsTransactionHistory,
  getListRequest,
  getMyWallet,
  withdrawForAdvertiser
} from 'src/_actions_';
import { AD_FILTER } from 'src/_constants_';
import { formatNumberWithCommas } from 'src/_helpers_';
import InputNumberFormatField from '../elements/input-number-format';

const INIT_DATA_FORM = {
  transfer_amount: '',
  ads_acc_tiktok_id: '',
  balance: 0,
  total_balance: 0
};

export default function ModalWithdraw(props) {
  const { onClose, account } = props;
  const accountBalance = account.balance === 0 || account.balance === null ? 0 : account.balance;
  const dataWithdrawAdvertiser = useSelector((state) => state?.payment?.dataWithdrawAdvertiser);
  const withdrawForAdvertiserErrors = useSelector(
    (state) => state?.payment?.withdrawForAdvertiserErrors
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const user = useSelector((state) => state?.authentication?.user);
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = useState({
    ...INIT_DATA_FORM,
    ads_acc_tiktok_id: account.id,
    balance: accountBalance,
    total_balance: account.team_info.total_fund
  });
  const [errorForm, setErrorForm] = useState('');

  useEffect(() => {
    if (account) {
      setDataForm((preData) => ({
        ...preData,
        ads_acc_tiktok_id: account.id,
        balance: accountBalance,
        total_balance: account.team_info.total_fund
      }));
    }
  }, [account]);

  const handleSubmit = () => {
    const payload = {
      ads_acc_tiktok_id: dataForm.ads_acc_tiktok_id,
      cash_amount: dataForm.transfer_amount
    };
    setSubmitLoading(true);
    dispatch(withdrawForAdvertiser(payload));
  };

  const handleClose = () => {
    onClose();
    setDataForm((preData) => ({
      ...preData,
      transfer_amount: '',
      ads_acc_tiktok_id: account.id,
      balance: accountBalance,
      total_balance: account.team_info.total_fund
    }));
    dispatch(getMyWallet());
    dispatch(
      getListRequest({
        page: 1,
        per_page: 50,
        team_id: user?.team_id?.id,
        status: AD_FILTER[0].value
      })
    );
    dispatch(
      getAdsTransactionHistory({
        page: 1,
        per_page: 50,
        team_id: user?.team_id?.id
      })
    );
    dispatch(clearData('dataWithdrawAdvertiser'));
  };

  useEffect(() => {
    if (dataWithdrawAdvertiser) handleClose();
    if (dataWithdrawAdvertiser) setSubmitLoading(false);
  }, [dataWithdrawAdvertiser]);

  useEffect(() => {
    if (withdrawForAdvertiserErrors) {
      setErrorForm(withdrawForAdvertiserErrors.msg.errors);
      dispatch(clearData('withdrawForAdvertiserErrors'));
      setSubmitLoading(false);
    }
  }, [withdrawForAdvertiserErrors]);

  const handleChangeTransferAmount = (event) => {
    const accountBalance = account.balance;
    let newBalance = accountBalance;
    let newTransferAmount = event.target.value ? Number(event.target.value) : '';
    let newTotalBalance = account.team_info.total_fund;

    // Check if value is negative
    if (
      !newTransferAmount ||
      newTransferAmount < 0 ||
      newTransferAmount === '' ||
      isNaN(newTransferAmount)
    ) {
      newTransferAmount = '';
      newBalance = accountBalance;
      newTotalBalance = account.team_info.total_fund;
    } else if (newTransferAmount > accountBalance) {
      // If the value is greater than the account balance
      newBalance = 0;
      newTransferAmount = accountBalance;
      newTotalBalance = account.team_info.total_fund + accountBalance;
    } else {
      // If the value is valid
      newBalance = account.balance - newTransferAmount;
      newTotalBalance = account.team_info.total_fund + newTransferAmount;
    }

    setDataForm((preData) => ({
      ...preData,
      transfer_amount: newTransferAmount,
      balance: newBalance,
      total_balance: newTotalBalance
    }));
  };

  return (
    <Box>
      <Dialog
        onClose={() => handleClose()}
        aria-labelledby="modal-form-dialog"
        open={true}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: '16px 32px' }}>
          <Box display="flex" gap={1}>
            <Box>Withdraw money from Ad account balance to SpendAds balance</Box>
            <Box
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.5
                },
                mr: -2
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={1} p={1}>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Box display="flex">
                <Typography fontWeight={600} flex={2}>
                  Ad Account name
                </Typography>
                <Typography flex={2}>{account?.name}</Typography>
              </Box>
              <Box display="flex">
                <Typography fontWeight={600} flex={2}>
                  Ad Account ID
                </Typography>
                <Typography flex={2}>{account?.advertiser_id}</Typography>
              </Box>
            </Box>
            <hr />
            <Box>
              <Box display="flex" gap={3}>
                <Box flex={1}>
                  <InputNumberFormatField
                    title="Transfer amount"
                    value={dataForm.transfer_amount}
                    onChange={(event) => handleChangeTransferAmount(event)}
                    required
                    isMoneyInput
                    error={errorForm?.cash_amount}
                    disabled={!account.balance}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    marginTop: errorForm?.cash_amount ? '-20px' : '24px'
                  }}
                >
                  <ArrowRightAltIcon color={'primary'} />
                </Box>
                <Box flex={1}>
                  <InputNumberFormatField
                    type={'number'}
                    title="Total balance in Ad account"
                    value={dataForm.balance}
                    disabled
                    isMoneyInput
                  />
                </Box>
              </Box>
            </Box>
            <hr />
            <Box display="flex" gap={2}>
              <Typography fontWeight={600}>Balance:</Typography>
              <Box display="flex" gap={1}>
                <Typography color={'primary'}>
                  {formatNumberWithCommas(dataForm.total_balance, true)}
                </Typography>
                <Typography>USD</Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2"
            color="secondary"
            variant="outlined"
            onClick={handleClose}
            style={{ borderRadius: 4 }}
          >
            Cancel
          </Button>
          <Button
            className="mr-2"
            variant="contained"
            onClick={handleSubmit}
            style={{ borderRadius: 4 }}
            disabled={submitLoading || !dataForm.transfer_amount}
          >
            {submitLoading && (
              <Typography
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></Typography>
            )}
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
