import React, { useCallback, useState } from 'react';
import Page from '../../../components/Page';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import ListAdAccount from '../../../components/ad-accounts/list';
import CreateAdAccount from '../../../components/ad-accounts/create';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

export default function MyAdAccountsPage() {
  const user = useSelector((state) => state?.authentication?.user);
  const [modal, setModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setModal(false);
  }, [setModal])

  return (
    <Page title="SpendAds | List add account">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box className="d-flex align-items-center">
            <Typography variant="h4">List Ad Account</Typography>
          </Box>
          <Box p={'8px 0'}>
            {
              user?.team_id?.id && (
                <>
                  <Button variant="contained" startIcon={<AddIcon />} onClick={() => setModal(true)}>
                    New
                  </Button>
                  {modal && (
                    <CreateAdAccount 
                      team_id={user?.team_id?.id}
                      handleCloseModal={handleCloseModal}
                    />
                  )}
                </>
              )
            }
          </Box>
        </Stack>
        <Box mt={3}>
          <ListAdAccount />
        </Box>
      </Container>
    </Page>
  );
}
