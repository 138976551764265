import * as t from '../_constants_/redux';

const initialState = {
  listProductLink: null,
  dataCreateSuccess: null,
  createErrors: null,
  dataEditSuccess: null,
  editErrors: null
};

export function productLink(state = initialState, action) {
  switch (action.type) {
    case t.LIST_PRODUCT_LINK: {
      return {
        ...state,
        listProductLink: action.payload
      };
    }
    case t.CREATE_PRODUCT_LINK: {
      return {
        ...state,
        dataCreateSuccess: action.payload,
        createErrors: null
      };
    }
    case t.EDIT_PRODUCT_LINK: {
      return {
        ...state,
        dataEditSuccess: action.payload,
        editErrors: null
      };
    }
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        errors: null
      };
    case t.ERROR:
      return {
        ...state,
        [action.payload.key]: action.payload.errors
      };
    default:
      return state;
  }
}
