import TeamUser from '../TeamSummary/TeamUser';

export const fields = [
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => `Team_${item.team_id}`,
    width: '100px'
  },
  {
    name: 'number_acc',
    label: 'Number of account',
    width: '150px',
    renderValue: (item) => `${item?.ads_account_number}`
  },
  {
    name: 'total_fund',
    label: 'Total fund',
    renderValue: (item) =>
      item.total_fund?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }),
    width: '120px'
  },
  {
    name: 'spent',
    label: 'Spent',
    renderValue: (item) =>
      item.total_fund_to_now?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }),
    width: '130px'
  }
];

export const adFields = [
  {
    name: 'account_name',
    label: 'Account name',
    renderValue: (item) => `Team_${item.team_id}`,
    width: '100px'
  },
  {
    name: 'account_id',
    label: 'Account ID',
    width: '100px',
    renderValue: (item) => `${item.team_id}`
  },
  {
    name: 'account_status',
    label: 'Account status',
    renderValue: () => 'Active',
    width: '120px'
  },
  {
    name: 'business_name',
    label: 'Business center name',
    renderValue: () => 'ABC Business Center',
    width: '130px'
  },
  {
    name: 'business_id',
    label: 'Business ID',
    renderValue: (item) => `${item.team_id}`,
    width: '130px'
  },
  {
    name: 'time_zone',
    label: 'Time zone',
    renderValue: () => 'America/New_York',
    width: '130px'
  },
  {
    name: 'balance',
    label: 'Balance',
    renderValue: (item) => `$${item.team_id}`,
    width: '130px'
  }
];

export const formFields = [
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id}`
  },
  // {
  //   type: 'text',
  //   name: 'total_fund_to_now',
  //   label: 'Number of account',
  //   disabled: true,
  //   renderValue: (item) => {
  //     console.log(item);
  //     console.log((item?.ads_account_number));
  //   }
  // },
  {
    type: 'text',
    name: 'total_topup_to_wallet',
    disabled: true,
    label: 'Total fund',
    renderValue: (item) =>
      item.total_fund?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }),
  },
  {
    type: 'text',
    name: 'total_acc_rental_fee',
    disabled: true,
    label: 'Spent',
    renderValue: (item) =>
      item.total_fund_to_now?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }),
  },
  // {
  //   type: 'number',
  //   name: 'total_fund_to_now',
  //   label: 'Total Fund',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'total_topup_to_wallet',
  //   label: 'Sum Service A',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'wallet_a',
  //   label: 'Wallet Remaining',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'total_acc_rental_fee',
  //   label: 'Sum Service B',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'total_acc_creation_insurance_fee',
  //   label: 'Sum Service C',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'total_acc_deposit',
  //   label: 'Sum Service D',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'total_others',
  //   label: 'O - Others',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'refund',
  //   label: 'R - Refund',
  //   disabled: true
  // },
  // {
  //   type: 'number',
  //   name: 'total_fund',
  //   label: 'Fund Remaining',
  //   disabled: true
  // },
  // {
  //   type: 'text',
  //   name: 'platform_id',
  //   label: 'Platform',
  //   renderValue: (item) => item.platform_id?.title,
  //   disabled: true
  // },
  // {
  //   type: 'text',
  //   name: 'product_url',
  //   label: 'Product Url',
  //   disabled: true
  // },
  // {
  //   type: 'text',
  //   name: 'type_acc',
  //   label: 'Type Acc',
  //   disabled: true
  // },
  {
    type: 'custom',
    name: 'team_user',
    label: 'Team User',
    renderValue: (item, ctx) => <TeamUser user={ctx.props.user} />
  }
];
