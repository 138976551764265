import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Link, FormHelperText, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { FILE_ALLOW, MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MB } from '../../_constants_/validate';
import { getImageUrl } from 'src/_helpers_';

const UploadSingleImage = ({
  title,
  description,
  required = false,
  onChange,
  fileAllow = FILE_ALLOW,
  error,
  className = '',
  defaultFile,
  onDeleteFileDefault,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    event.target.value = null; 
    if (file && !fileAllow.includes(file.type)) {
      setFileError(
        `Some files are not allowed. Please upload only ${fileAllow.map((ext) => `.${ext.split('/').pop()}`).join(', ')}`
      );
      setSelectedFile(null);
    } else if (file.size > MAX_FILE_SIZE_BYTES) {
      setFileError(`File size exceeds ${MAX_FILE_SIZE_MB}MB: ${file.name}`);
    } else if (file !== undefined) {
      setFileError('');
      setSelectedFile(file);
      onDeleteFileDefault(defaultFile)

      if (onChange) {
        onChange(file);
      }
    }
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
    setFileError('');

    if (onChange) {
      onChange(null);
    }
  };

  useEffect(() => {
    if (defaultFile) setSelectedFile(null);
  }, [defaultFile])

  return (
    <Box className={className}>
      <Box display="flex" gap={0.5} alignItems="center">
        <Typography fontWeight={600}>{title}</Typography>
        {required ? (
          <Typography sx={{ color: 'red' }}>*</Typography>
        ) : (
          <Typography>(Optional)</Typography>
        )}
      </Box>

      {description && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>
      )}

      <Button
        variant="outlined"
        component="label"
        startIcon={<AttachFileIcon />}
        sx={{ marginBottom: 1 }}
      >
        Choose file
        <input
          type="file"
          hidden
          accept={fileAllow.map((ext) => `.${ext.split('/').pop()}`).join(', ')}
          onChange={handleFileUpload}
        />
      </Button>

      {selectedFile && (
        <Box mt={1} display="flex" alignItems="center" gap={1} justifyContent={'space-between'}>
          <Link
            href={URL.createObjectURL(selectedFile)}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'blue' }}
          >
            {selectedFile.name}
          </Link>
          <IconButton onClick={handleFileDelete} aria-label="delete file">
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      {defaultFile && (
        <Box mt={1} display="flex" alignItems="center" gap={1} justifyContent={'space-between'}>
          <Link
            href={getImageUrl(defaultFile.image_path)}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'blue' }}
          >
            {defaultFile.image_path}
          </Link>
          <IconButton onClick={() => onDeleteFileDefault(defaultFile)} aria-label="delete file">
            <DeleteIcon />
          </IconButton>
        </Box>
      )}

      <FormHelperText sx={{ color: 'red' }}>{fileError || error}</FormHelperText>
    </Box>
  );
};

export default UploadSingleImage;
