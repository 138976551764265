// ROLE
export const ROLE = {
  OWNER: 'owner',
  TEAMLEAD: 'teamlead',
  SELLER: 'seller',
}

// color
export const BG_GREEN = '#ebf8f2';
export const COLOR_TEXT = '#212b36';
export const COLOR_WHITE = '#fff';
export const BG_COLOR_GREEN = '#00ab55';
export const BG_COLOR_GRAY = '#f9f9f9';
export const BG_COLOR_BLUE = '#edf1ff';
export const COLOR_GRAY_BLUE = '#8a8e9b';
export const COLOR_RED = '#dc3545';

export const PAGINATE_ARR = [15, 20, 50, 100];

export const PaymentMethod = {
  paypal: 'paypal',
  bank_vnd: 'bank_vnd',
  payoneer: 'po',
  pingpong: 'pingpong'
};

export const PaymentTabs = {
  balance_allocation: 1,
  add_account: 2,
  balance_history: 3,
  refund: 4
};

export const placeRegistration = [{ value: 'VN', label: 'Việt Nam' }];

export const recipientMethods = {
  bank_tranfer: 'bank_vnd',
  paypal: 'paypal',
  payoneer: 'payoneer',
  pingpong: 'pingpong'
};

export const productLinkStatus = {
  in_review: 'In review',
  process: 'Process',
  accepted: 'Accepted'
};

export const submitLinkStatus = {
  warming: 'Warning',
  process: 'Process'
};

//----------------------- Select Option ---------------------------------
export const productLinkOptions = [
  {
    value: 0,
    label: 'All status'
  },
  { value: 'Process', label: 'Process', color: 'warning' },
  { value: 'Approved', label: 'Approved', color: 'success' },
  { value: 'Cancel', label: 'Cancel', color: 'error' },
  { value: 'Warning', label: 'Warning', color: 'warning' }
];

export const targetMarketOption = [
  { value: 1, label: 'Việt Nam' },
  { value: 2, label: 'SEA' },
  { value: 3, label: 'US-UK' }
];

export const refundReasonOptions = [
  { value: 'Permanent service discontinuation', label: 'Permanent service discontinuation' },
  { value: 'Temporarily stop using services', label: 'Temporarily stop using services' },
  { value: 'Other', label: 'Other' }
];

export const receiveMethodOption = [
  { value: 'bank_vnd', label: 'Bank tranfer' },
  { value: 'paypal', label: 'PayPal' },
  { value: 'payoneer', label: 'Payoneer' },
  { value: 'pingpong', label: 'Pingpong' }
];

// ------------------ AD ACCOUNT -----------------------
export const AD_FILTER = [
  {
    value: 0,
    label: 'All status'
  },
  { value: 'process', label: 'Process' },
  { value: 'in_review', label: 'In review' },
  { value: 'approved', label: 'Approved' },
  { value: 'not_approved', label: 'Not approved' },
  { value: 'suspended', label: 'Suspended' },
  { value: 'contract_has_not_taken_effect', label: 'Contract has not taken effect' },
  { value: 'disabled', label: 'Disabled' }
];
//AD list display
export const adStatus = [
  {
    value: 0,
    label: 'All status'
  },
  { value: 'Process', label: 'Process', color: 'warning' },
  { value: 'In review', label: 'In review', color: 'warning' },
  { value: 'Approved', label: 'Approved', color: 'success' },
  { value: 'Not approved', label: 'Not approved', color: 'error' },
  { value: 'Suspended', label: 'Suspended', color: 'error' },
  {
    value: 'Contract has not taken effect',
    label: 'Contract has not taken effect',
    color: 'error'
  },
  { value: 'Disabled', label: 'Disabled', color: 'error' }
];

export const STATUS_ENABLED_ACTION = 'STATUS_ENABLE';
