import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Container,
  Stack,
  Typography,
  TablePagination,
  Checkbox,
  TextField,
  Grid,
  Tooltip,
  InputAdornment,
  Box
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { request } from '../_services_';
import {
  ModalForm,
  Scrollbar,
  Page,
  CustomCircularProgress,
  MultiInputPicker,
  DatePicker
} from '../components';
import { toastr } from 'src/_helpers_';
import clsx from 'clsx';
import { MHidden } from '../components/@material-extend';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
const useStyles = (theme) => ({
  root: {
    // marginTop: 5,
    overflowX: 'auto',
    borderRadius: 0
  },
  buttonSearch: {
    padding: 10,
    marginRight: 0,
    borderRadius: '0 7px 7px 0'
  },
  dynamic_col: {
    marginBottom: 0,
    minWidth: '100%',
    tableLayout: 'fixed',
    background: '#fff',
    '&>tbody>tr>td': {
      borderBottom: '1px solid #ebeff3',
      borderTop: 0
    },
    '& td': {
      // height: '85px'
    }
  },
  paper_no_pagination: {
    borderRadius: '0px 0px 6px 6px'
  },
  head_cell: {
    background: '#ECECEC',
    borderBottom: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  table: {
    minWidth: 700,
    '&>thead:first-child>tr:first-child>th': {
      borderTop: 0
    },
    '&>th': {
      textTransform: 'uppercase',
      whiteSpace: 'nowrap'
    },
    '&>thead>tr>th': {
      padding: '0px 10px'
    },
    // '&>thead>tr': {
    //   verticalAlign: 'top'
    // },
    '&>tbody>tr>td': {
      position: 'relative',
      verticalAlign: 'middle',
      padding: '0px 10px',
      height: 54,
      lineHeight: '1.42857'
    },
    '& p': {
      margin: 0
    },
    '& a': {
      color: '#2c5cc5',
      textDecoration: 'none'
    },
    '& .inline-wrapper': {
      boxShadow: '0 2px 5px 0 rgba(39,49,58,.15)',
      opacity: 1,
      background: '#fff',
      border: '1px solid #ebeff3',
      borderRadius: '20px',
      minHeight: '33px',
      padding: '7px 32px 7px 5px',
      position: 'absolute',
      right: '6px',
      top: '50%',
      transform: 'translateY(-50%)'
      // visibility: 'hidden',
    },
    '& .inline-actions': {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      margin: 0,
      paddingLeft: 0,
      listStyle: 'none',
      '& li': {
        padding: '0 10px',
        display: 'inline-block'
      }
    },
    table_floating: {
      width: '80%',
      float: 'left',
      '& .dynamic_col': {
        maxWidth: 'none'
      }
    },
    cell_entry: {
      whiteSpace: 'nowrap',
      width: '100%'
    }
  },
  head_cell_no_checkbox: {
    padding: '8px 10px !important'
  }
});

class ResourceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      items: [],
      isLoading: false,
      totals: 0,
      searchQuery: {
        page: 1,
        limit: 15
      },
      sort: {
        field: null,
        type: 'asc'
      }
    };
  }

  componentDidMount() {
    const defaultFilters = this.props.defaultFilters || {};
    const query = {
      ...this.state.searchQuery,
      ...defaultFilters
    };
    this.setState({ isLoading: true });
    request.get(this.props.route, query).then(
      (res) => {
        if (res.data.success) {
          this.setState({
            items: res.data.data.items,
            totals: res.data.data.total,
            isLoading: false
          });
        } else {
          toastr.error(res.data.msg);
          this.setState({ isLoading: false });
        }
      },
      (err) => {
        toastr.error(err);
        this.setState({ isLoading: false });
      }
    );
  }

  getListItems = (query) => {
    const { sort } = this.state;
    const defaultFilters = this.props.defaultFilters || {};
    const queryObj = {
      ...query,
      ...defaultFilters,
      ...(sort?.field ? { sort: sort.field, type_sort: sort.type } : {})
    };
    this.setState({ isLoading: true });
    request.get(this.props.route, queryObj).then(
      (res) => {
        if (res.data.success) {
          this.setState({
            items: res.data.data.items,
            totals: res.data.data.total,
            isLoading: false
          });
        } else {
          toastr.error(res.data.msg);
          this.setState({ isLoading: false });
        }
      },
      (err) => {
        toastr.error(err);
        this.setState({ isLoading: false });
      }
    );
  };

  loadOptions = (inputValue, loadingData) => {
    return loadingData(inputValue);
  };

  handleInputChange = (newValue) => {
    return newValue;
  };

  handleCheckAll = (event) => {
    let selectedItems;
    if (event.target.checked) {
      selectedItems = this.state.items.map((t) => t[this.props.valueKey || 'id']);
    } else {
      selectedItems = [];
    }
    this.setState({ selectedItems });
  };

  handleCheck = (event, id) => {
    const selectedItems = Object.assign([], this.state.selectedItems);
    const index = selectedItems.indexOf(id);
    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(id);
      }
    } else {
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    this.setState({ selectedItems });
  };

  onQueryChange = (event) => {
    const searchQuery = Object.assign({}, this.state.searchQuery);
    searchQuery[event.target.name] = event.target.value;
    this.setState({ searchQuery });
  };

  handlePageChange = (event, page) => {
    const searchQuery = Object.assign({}, this.state.searchQuery);
    searchQuery.page = page + 1;
    this.setState({ searchQuery }, () => {
      this.getListItems(this.state.searchQuery);
    });
  };

  handleRowsPerPageChange = (event) => {
    const searchQuery = Object.assign({}, this.state.searchQuery);
    searchQuery.page = 1;
    searchQuery.limit = event.target.value;
    this.setState({ searchQuery }, () => {
      this.getListItems(this.state.searchQuery);
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.reload != this.props.reload && this.props.reload) {
      const defaultFilters = this.props.defaultFilters || {};
      const query = {
        ...this.state.searchQuery,
        ...defaultFilters
      };
      this.getListItems(query);
      this.props.setReLoad && this.props.setReLoad(false);
    }
  }

  render() {
    const { selectedItems, items, searchQuery, sort } = this.state;
    const { classes, fields, actions, filters } = this.props;
    return (
      <Page
        title={
          this.props.notification?.count > 0
            ? `(${this.props.notification?.count}) ${this.props.titlePage || 'SpendAds'}`
            : this.props.titlePage || 'SpendAds'
        }
      >
        <ModalForm />
        <Box sx={{ width: '100%', padding: '24px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              {this.props.title || 'List'}
            </Typography>
            {actions && actions.length > 0 && (
              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {actions
                  .filter((e) => e.visible === true)
                  .map((ac, index) => (
                    <Button
                      key={index}
                      variant={ac.variant || 'contained'}
                      startIcon={ac.icon}
                      endIcon={ac.endIcon}
                      size={ac.size || 'small'}
                      onClick={() => ac.action(this)}
                    >
                      {ac.text}
                    </Button>
                  ))}
              </Stack>
            )}
          </Stack>
          <div className="card-container">
            <Grid container style={{
              padding: this.props.invisibleSearch ? '0' : '4px 8px',
              borderBottom: this.props.invisibleSearch ? 'none' :'1px solid #ccc'
            }}>
              <Grid item xs={12} sm={12}>
                <div className="d-flex align-items-center" style={{ gap: 5 }}>
                  {!this.props.invisibleSearch && (
                    <TextField
                      name="query"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      label=""
                      placeholder="Search"
                      onChange={this.onQueryChange}
                      value={searchQuery.query}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.getListItems(searchQuery);
                        }
                      }}
                      style={{ marginTop: 4, width: '30%' }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              variant="contained"
                              className={classes.buttonSearch}
                              onClick={() => this.getListItems(this.state.searchQuery)}
                            >
                              Search
                            </Button>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                  {filters?.length > 0 && (
                    <MHidden width="lgDown">
                      {filters.map((filter) => {
                        if (filter.type == 'input_picker') {
                          return (
                            <MultiInputPicker
                              onDelete={(click) => (this.handleDeleteMultiPicker = click)}
                              valueKey={filter.valueKey || 'id'}
                              getOptionLabel={(item) =>
                                filter.getOptionLabel
                                  ? filter.getOptionLabel(item)
                                  : filter.labelKey
                                    ? item[filter.labelKey]
                                    : item.name
                              }
                              onChange={(items) => {
                                const searchQuery = Object.assign({}, this.state.searchQuery);
                                if (items.length > 0) {
                                  searchQuery[filter.key] = items
                                    .map((item) => item[filter.valueKey || 'id'])
                                    .join(',');
                                } else {
                                  delete searchQuery[filter.key];
                                }
                                this.setState({ searchQuery }, () =>
                                  this.getListItems(this.state.searchQuery)
                                );
                              }}
                              loadOptions={(inputValue) => this.getOptions(inputValue, filter)}
                              noOptionsMessage="No results found"
                              renderAriaLabel={(selectedItems) =>
                                filter.renderAriaLabel
                                  ? filter.renderAriaLabel(selectedItems)
                                  : `${selectedItems.length} item(s) selected`
                              }
                              defaultAriaLabel={filter.defaultAriaLabel}
                            />
                          );
                        } else if (filter.type == 'date_picker') {
                          return (
                            <DatePicker
                              placeholder={filter.placeholder || ''}
                              onChange={(value) => {
                                const searchQuery = Object.assign({}, this.state.searchQuery);
                                if (value.length > 0) {
                                  searchQuery[filter.key] = JSON.stringify(value);
                                } else {
                                  delete searchQuery[filter.key];
                                }
                                this.setState({ searchQuery }, () =>
                                  this.getListItems(this.state.searchQuery)
                                );
                              }}
                            />
                          );
                        }
                      })}
                    </MHidden>
                  )}
                </div>
              </Grid>
            </Grid>
            <Paper
              className={clsx(
                classes.root,
                this.props.invisiblePagination ? classes.paper_no_pagination : ''
              )}
              style={{ position: 'relative' }}
            >
              <TableContainer style={{ maxHeight: '220px' }} className={classes.table}>
                <Table
                  stickyHeader
                  className={clsx(
                    classes.dynamic_col,
                    classes.dynamic_sticky,
                    classes.table,
                    'dynamic_col',
                    'table-list'
                  )}
                >
                  <TableHead className={classes.head_white}>
                    <TableRow>
                      {!this.props.invisibleCheckbox && (
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={
                            this.props.invisibleCheckbox
                              ? { width: '15px', padding: 0 }
                              : { width: '54px', textAlign: 'left' }
                          }
                          align="center"
                        >
                          <Checkbox
                            // sx={this.props.invisibleCheckbox && {display: 'none'}}
                            checked={selectedItems.length === items.length}
                            color="secondary"
                            indeterminate={
                              selectedItems.length > 0 && selectedItems.length < items.length
                            }
                            onChange={this.handleCheckAll}
                          />
                        </TableCell>
                      )}
                      {fields?.map((field, i) => (
                        <TableCell
                          key={i}
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell,
                            this.props.invisibleCheckbox ? classes.head_cell_no_checkbox : ''
                          )}
                          style={{ width: field.width || '12rem' }}
                          align={field.align || 'left'}
                        >
                          <Tooltip title={field.label} arrow placement="top">
                            <a
                              style={{
                                cursor: field.sort ? 'pointer' : 'normal',
                                color: sort.name == field.name ? 'green' : 'black'
                              }}
                              onClick={() => {
                                if (!field.sort) return;
                                var sortConfig = Object.assign({}, this.state.sort);
                                if (sortConfig.name != field.name) {
                                  sortConfig = {
                                    field: field.sortName || field.name,
                                    type: 'asc',
                                    name: field.name
                                  };
                                } else {
                                  sortConfig = {
                                    field: field.sortName || field.name,
                                    type: sortConfig.type == 'asc' ? 'desc' : 'asc',
                                    name: field.name
                                  };
                                }
                                this.setState({ sort: sortConfig }, () => {
                                  this.getListItems(searchQuery);
                                });
                              }}
                            >
                              <span className="line-clamp-3" style={{ paddingRight: '2px' }}>
                                {field.label}
                              </span>
                              {sort.name == field.name ? (
                                sort.type == 'asc' ? (
                                  <ArrowDownwardIcon fontSize={'small'} />
                                ) : (
                                  <ArrowUpwardIcon fontSize={'small'} />
                                )
                              ) : null}
                            </a>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.items.map((item, index) => (
                      <TableRow
                        key={item[this.props.valueKey || 'id']}
                        className={`cursor-pointer ${item[this.props.valueKey || 'id'] == this.props.rowActive ? 'row-active' : ''}`}
                        onClick={() => {
                          if (this.props.onClickRow) {
                            this.props.onClickRow(item);
                          }
                        }}
                      >
                        {!this.props.invisibleCheckbox && (
                          <TableCell
                            onClick={(e) => e.stopPropagation()}
                            padding="checkbox"
                            align="center"
                            className={clsx(classes.cell_entry)}
                            style={
                              this.props.invisibleCheckbox
                                ? { width: '15px', padding: 0 }
                                : { width: '54px', textAlign: 'left' }
                            }
                          >
                            <Checkbox
                              sx={this.props.invisibleCheckbox && { display: 'none' }}
                              checked={
                                selectedItems.indexOf(item[this.props.valueKey || 'id']) !== -1
                              }
                              color="secondary"
                              onChange={(event) =>
                                this.handleCheck(event, item[this.props.valueKey || 'id'])
                              }
                              value="true"
                            />
                          </TableCell>
                        )}
                        {fields?.map((field) => (
                          <TableCell
                            key={field.name}
                            className={clsx(classes.cell_entry)}
                            align={field.align || 'left'}
                          >
                            <div
                              className="fnu-view"
                              style={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <div className={classes.content_inline}>
                                <span
                                  className="text_2"
                                  style={{
                                    cursor: this.props.onClickRow ? 'pointer' : 'text',
                                    flex: 1,
                                    maxWidth: 'calc(100%)',
                                    wordBreak: 'normal'
                                  }}
                                >
                                  <div
                                    className={classes.text_ellipsis}
                                    style={field.renderColor && { color: field.renderColor(item) }}
                                  >
                                    {field.renderValue ? field.renderValue(item) : item[field.name]}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {this.state.isLoading ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255,255,255,0.8)'
                  }}
                >
                  <div className="p-3 d-flex align-items-center justify-content-center">
                    <CustomCircularProgress />
                  </div>
                </div>
              ) : null}
            </Paper>
            {!this.props.invisiblePagination && (
              <TablePagination
                className="list-pagination"
                style={{
                  borderTop: '1px solid #D8D8D8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
                component="div"
                count={this.state.totals}
                onPageChange={this.handlePageChange}
                onRowsPerPageChange={this.handleRowsPerPageChange}
                page={searchQuery.page - 1}
                rowsPerPage={searchQuery.limit}
                rowsPerPageOptions={[15, 20, 50, 100]}
              />
            )}
          </div>
        </Box>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, notification } = state;
  const { user } = authentication;
  return {
    user,
    notification
  };
}

const connectedList = connect(mapStateToProps)(withStyles(useStyles)(ResourceList));
export { connectedList as ResourceList };
