import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import {
  TextField, Radio, RadioGroup, FormControl, FormControlLabel, Box, Button, IconButton
} from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, handleInputChange, loadOptions } from '../../../utils'
import { fields, formFields } from './constants';
import { ModalForm, Uploader, ResourceList, CustomNumberFormat, DrawerForm } from '../../../components'
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_'
import { validate } from 'validate.js';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { IconTrash } from '@tabler/icons';

const formsSchema = {
  domain: { 
    presence: { message: '^Required' },
  },
  platform: {
    presence: { message: '^Required' }
  },
  page_link: {
    presence: { message: '^Required' }
  },
  page_name: {
    presence: { message: '^Required' }
  },
}

class WebPageInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mode: 'view',
      objectId: null
    }
  }

  newWebPage = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New WebPage",
      data: {
        domains: [
          {}
        ],
        pages: [
          {
            page_link: '',
            page_name: ''
          }
        ]
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Product Type <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncCreatableSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/product_types', { name: inputValue, type: 'rac' }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({ id: e.product_type_id, name: e.name })))
                    } else {
                      resolve([])
                    }
                  }, error => {
                    resolve([])
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"product_type_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "product_type_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Product Type"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.product_type_id || null}
              styles={submitData.errors?.product_type_id ? errorStyles : customStyles}
              getNewOptionData={(inputValue, optionLabel) => ({
                platform_id:inputValue,
                name: 'Create \"' + inputValue + '\"...',
                __isNew__: true
              })}
              onCreateOption={(inputValue) => {
                request.post('/api/v1/product_types', { name: inputValue }).then(res => {
                  if (res.data.success) {
                    var e = {
                      target: {
                        name: "product_type_id",
                        value: res.data.data
                      },
                    };
                    handleChange(e)
                  } else {
                    toastr.error(res.data.msg)
                  }
                }, err => toastr.error(err))
              }}
            />
            {submitData.errors?.product_type_id ? <span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{submitData.errors.product_type_id[0]}</span> : null}
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Average Monthly Spending <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              value={submitData.values.spent_per_month}
              name="spent_per_month"
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
              error={submitData.errors?.spent_per_month}
              helperText={submitData.errors?.spent_per_month ? submitData.errors.spent_per_month[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Number of accounts you need <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              value={submitData.values.total_acc_request}
              name="total_acc_request"
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
              error={submitData.errors?.total_acc_request}
              helperText={submitData.errors?.total_acc_request ? submitData.errors.total_acc_request[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Account Timezone <span style={{ color: 'red' }}>*</span>
            </Box>
            {/* <AsyncCreatableSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/timezones', { name: inputValue }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({id: e.code, name: e.name})))
                    } else {
                      resolve([])
                    }
                  }, error => {
                    resolve([])
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"request_acc_timezone"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "request_acc_timezone",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Timezone"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.request_acc_timezone || null}
              styles={submitData.errors?.request_acc_timezone ? errorStyles : customStyles}
              getNewOptionData={(inputValue, optionLabel) => ({
                platform_id:inputValue,
                name: 'Create \"' + inputValue + '\"...',
                __isNew__: true
              })}
              onCreateOption={(inputValue) => {
                request.post('/api/v1/timezones', { name: inputValue }).then(res => {
                  if (res.data.success) {
                    var e = {
                      target: {
                        name: "request_acc_timezone",
                        value: res.data.data
                      },
                    };
                    handleChange(e)
                  } else {
                    toastr.error(res.data.msg)
                  }
                }, err => toastr.error(err))
              }}
            /> */}
            <AsyncSelect 
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  resolve([
                    {id: 'gmt-7', name: 'GMT -7'},
                    {id: 'gmt+7', name: 'GMT +7'} 
                  ])
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"request_acc_timezone"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "request_acc_timezone",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Timezone"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.request_acc_timezone || null}
              styles={submitData.errors?.request_acc_timezone ? errorStyles : customStyles}
            />
            {submitData.errors?.request_acc_timezone ? <span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{submitData.errors.request_acc_timezone[0]}</span> : null}
          </div>
          
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Have you ever created Ad account at another Agency before?
              Bạn có từng tạo tài khoản ở Agency khác trước kia chưa?
              <span style={{ color: 'red' }}>*</span>
            </Box>
            <FormControl>
              <RadioGroup
                row
                name="exists_at_another_agency"
                value={submitData.values.exists_at_another_agency}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {submitData.errors?.exists_at_another_agency ? <div><span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{submitData.errors.exists_at_another_agency[0]}</span></div> : null}
          </div>
          {submitData.values.exists_at_another_agency == "1" &&
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Please screenshot the account created by another agency in your BM.
              Hãy chụp ảnh tài khoản bạn đã từng tạo ở Agency khác trong BM của bạn {submitData.values.exists_at_another_agency == "1" && <span style={{ color: 'red' }}>*</span>}
            </Box>
            <Uploader
              className={submitData.errors?.files ? 'form-upload-error' : ''}
              multiple={true}
              accept='image/*'
              uploadOnPicked
              handleChange={handleChange}
              submitData={submitData}
            />
            {submitData.errors?.files ? <span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{submitData.errors.files[0]}</span> : null}
          </div>}
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight="bold">
            ____________________
            <p>DOMAIN &amp; PLATFORM INFO</p>
            <p>1. If your website has been used to create ad account at another HongKong agencies, please tell us in advance to have further solution.</p>
            <p>2. Only fill domain (e.g tiki.vn). DO NOT fill sub-domain (e.g sanpham.tiki.vn) or folders (e.g tiki.vn/sanpham)</p>
            <p>1. Nếu domain của bạn từng dùng để tạo tài khoản Invoice với Agency khác, vui lòng thông báo với chúng tôi để có giải pháp lách FB hiệu quả.</p>
            <p>2. Chỉ ghi Domain chính (VD: tiki.vn). Ko ghi sub-domain (VD: sanpham.tiki.vn). Ko ghi trang con (VD: tiki.vn/sanpham)</p>
            </Box>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              <Box fontSize={15} fontWeight='bold'>
                {`Domains`} <span style={{ color: 'red' }}>*</span>
              </Box>
            </div>
            {submitData?.values?.domains?.map((item, index) =>
              <div className='d-flex' key={index}>
                <div className='d-flex align-items-center' style={{ gap: '5px', width: '94%' }}>
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      margin='dense'
                      name='domain'
                      onChange={(event) => {
                        item.domain = event.target.value
                        const errors = validate(item, formsSchema);
                        item.isValid = errors ? false : true;
                        item.errors = errors || {}; 
                        const e = {
                          target: {
                            name: 'domains',
                            value: [...submitData.values.domains]
                          }
                        }
                        handleChange(e)
                      }}
                      value={item.domain || ''}
                      variant="outlined"
                      placeholder='https://'
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        }
                      }}
                      error={item.errors?.domain}
                      helperText={item.errors?.domain ? item.errors.domain[0] : ''}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <AsyncCreatableSelect
                      className="MuiFormControl-marginDense"
                      cacheOptions
                      loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                        return new Promise(resolve => {
                          request.get('/api/v1/platforms', { name: inputValue }).then(res => {
                            if (res.data.success) {
                              resolve(res.data.data.items)
                            } else {
                              resolve([])
                            }
                          }, error => {
                            resolve([])
                          })
                        })
                      })}
                      defaultOptions
                      onInputChange={handleInputChange}
                      isSearchable
                      name='platform'
                      onChange={(value) => {
                        item.platform = value
                        const errors = validate(item, formsSchema);
                        item.isValid = errors ? false : true;
                        item.errors = errors || {}; 
                        var e = {
                          target: {
                            name: "domains",
                            value: [...submitData.values.domains]
                          },
                        };
                        handleChange(e)
                      }}
                      placeholder={`Platform of domain ${index + 1}`}
                      menuPortalTarget={document.body}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ platform_id }) => platform_id}
                      valueKey={"platform_id"}
                      value={item?.platform || null}
                      styles={item.errors?.platform ? errorStyles : customStyles}
                      getNewOptionData={(inputValue, optionLabel) => ({
                        platform_id:inputValue,
                        name: 'Create \"' + inputValue + '\"...',
                        __isNew__: true
                      })}
                      onCreateOption={(inputValue) => {
                        request.post('/api/v1/platforms', { name: inputValue }).then(res => {
                          if (res.data.success) {
                            var e = {
                              target: {
                                name: "platform",
                                value:res.data.data
                              },
                            };
                            handleChange(e)
                          } else {
                            toastr.error(res.data.msg)
                          }
                        }, err => toastr.error(err))
                      }}
                    />
                    {item.errors?.platform ? <span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{item.errors.platform[0]}</span> : null}
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-center' style={{ width: '6%' }}>
                  <IconButton color="primary" aria-label="upload picture" component="label" 
                    onClick={() => {
                      const domains = Object.assign([], submitData.values.domains)
                      if(domains.length >= 2){
                        domains.splice(index, 1)
                      } else {
                        domains[0] = {} 
                      }

                      const event = {
                        target: {
                          name: 'domains',
                          value: domains
                        }
                      }
                      handleChange(event)
                    }}
                    >
                    <IconTrash color="red" width={16} height={16} />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
          <Button onClick={() => {
              const domains = Object.assign([], submitData.values.domains)
              domains.push({})

              handleChange({ target: { name: 'domains', value: domains } })
            }}
            disabled={ 
              submitData.values.domains &&
              !submitData.values?.domains[submitData.values?.domains?.length - 1]?.platform?.id
            }
          >
            Add
          </Button>
          
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Can your domain change DNS temporarily?
              <small><i>Domain của bạn có thể chỉnh sửa DNS tạm thời không?</i></small>
              <span style={{ color: 'red' }}>*</span>
            </Box>
            <FormControl>
              <RadioGroup
                row
                name="change_dns"
                value={submitData.values.change_dns}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight="bold">
            <p>___________</p>
            <p>FANPAGE INFO:</p>
            <p>- Have you ever create ad account with these fanpages at another Agency before? If yes, please let us know to have further solution before submitting.</p>
            <p>- Nếu bạn đã từng dùng fanpage ở Agency khác, vui lòng thông báo cho chúng tôi để có cách lách hiệu quả.(Bạn nên dùng Page cổ kháng để chạy Ad cho ổn định)</p>
            </Box>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              <Box fontSize={15} fontWeight='bold'>
                {`Pages`} <span style={{ color: 'red' }}>*</span>
              </Box>
            </div>
            {submitData?.values?.pages?.map((item, index) => 
              <div className='d-flex' key={index}>
                <div className='d-flex align-items-center' style={{ gap: '5px', width: '94%' }}>
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      margin='dense'
                      name="page_link"
                      onChange={(event) => {
                        item.page_link = event.target.value
                        const errors = validate(item, formsSchema);
                        item.isValid = errors ? false : true;
                        item.errors = errors || {}; 
                        const e = {
                          target: {
                            name: 'pages',
                            value: [...submitData.values.pages]
                          }
                        }
                        handleChange(e)
                      }}
                      value={item.page_link || ''}
                      variant="outlined"
                      placeholder='https://'
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        }
                      }}
                      error={item.errors?.page_link}
                      helperText={item.errors?.page_link ? item.errors.page_link[0] : ''}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      placeholder={`Page ${index + 1} - Name`}
                      margin='dense'
                      name="page_name"
                      onChange={(event) => {
                        item.page_name = event.target.value
                        const errors = validate(item, formsSchema);
                        item.isValid = errors ? false : true;
                        item.errors = errors || {}; 
                        const e = {
                          target: {
                            name: 'pages',
                            value: [...submitData.values.pages]
                          }
                        }
                        handleChange(e)
                      }}
                      value={item.page_name || ''}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        }
                      }}
                      error={item.errors?.page_name}
                      helperText={item.errors?.page_name ? item.errors.page_name[0] : ''}
                    />
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-center' style={{ width: '6%' }}>
                  <IconButton color="primary" aria-label="upload picture" component="label" 
                    onClick={() => {
                      const pages = Object.assign([], submitData.values.pages)
                      if(pages.length >= 2){
                        pages.splice(index, 1)
                      } else {
                        pages[0] = {} 
                      }
                      
                      const event = {
                        target: {
                          name: 'pages',
                          value: pages
                        }
                      }
                      handleChange(event)
                    }}
                    >
                    <IconTrash color="red" width={16} height={16}/>
                  </IconButton>
                </div>
              </div>
            )}
          </div>
          <Button onClick={() => {
              const pages = Object.assign([], submitData.values.pages)

              pages.push({})

              handleChange({ target: { name: 'pages', value: pages } })
            }}
            disabled={ 
              submitData.values.pages &&
              !submitData.values?.pages[submitData.values.pages.length - 1]?.page_name
            }
          >
            Add
          </Button>
          <div className='d-flex' style={{ marginTop: 10, gap: 10 }}>
            <div style={{ width: '50%' }}>
              <Box fontSize={15} fontWeight='bold'>
                BM ID <span style={{ color: 'red' }}>*</span>
              </Box>
              <TextField
                fullWidth
                margin='dense'
                name="bm_id"
                onChange={handleChange}
                value={submitData.values.bm_id}
                variant="outlined"
                type="number"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                error={submitData?.errors?.bm_id?true:false}
                helperText={submitData.errors?.bm_id ? submitData.errors.bm_id[0] : ''}
              />
            </div>
            <div style={{ width: '50%' }}>
              <Box fontSize={15} fontWeight='bold'>
                BM Name <span style={{ color: 'red' }}>*</span>
              </Box>
              <TextField
                fullWidth
                margin='dense'
                name="bm_name"
                onChange={handleChange}
                value={submitData.values.bm_name}
                variant="outlined"
                type="text"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                error={submitData?.errors?.bm_name ? true : false}
                helperText={submitData.errors?.bm_name ? submitData.errors.bm_name[0] : ''}
              />
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BM ID 2 (preventive)
              ID của BM thứ 2 để dự phòng nếu BM chính bị lỗi
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="bm2_id"
              onChange={handleChange}
              value={submitData.values.bm2_id}
              variant="outlined"
              type="number"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15}>
            ____________________
            <p style={{fontWeight: "bold"}}>Lưu ý từ Facebook:</p>
            <p>- Bắt đầu từ ngày 09 tháng 12 năm 2022, tất cả các tài khoản quảng cáo được tạo ra bằng cách liên kết trực tiếp với Business Manager của khách hàng.</p>
            <p>- Yêu cầu BM phải còn hoạt động và cần được xác minh danh tính.</p>
            <p>- Vui lòng share quyền quản trị acc trên BM của bạn vào email support@drebest.com.</p>
            </Box>
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Other Note
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="other_note"
              placeholder="Other note..."
              value={submitData.values.other_note}
              variant="outlined"
              onChange={handleChange}
              multiline
              minRows={3}
              maxRows={5}
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
        </div>
      ),
      action: {
        schema: (submitData) => {
          var schema = {
            spent_per_month: { presence: { message: '^Required' } },
            request_acc_timezone: { presence: { message: '^Required' } },
            product_type_id: { presence: { message: '^Required' } },
            exists_at_another_agency: { presence: { message: '^Required' } },
            bm_id: { presence: { message: '^BM ID Required' } },
            bm_name: { presence: { message: '^BM Name Required' } },
            total_acc_request: { presence: { message: '^Required' } },
          }
          if (submitData.values.exists_at_another_agency == "1") {
            schema['files'] = { presence: { message: '^Required' } }
          }
          // if (this.props.user?.is_owner) {
          //   schema['team_id'] = { presence: { message: '^Required' } }
          // }
          return schema
        },
        titleAction: 'Save',
        onAction: (submitData) => {
          return new Promise((resolve) => {
            var data = {}
            var keys = Object.keys(submitData.values)
            keys.map(key => {
              if (['domains', 'pages'].includes(key)){
                if(key == 'domains'){
                  data[key] = submitData.values[key].map(({ domain, platform }) => ({ domain, platform }))
                } else {
                  data[key] = submitData.values[key].map(({ page_link, page_name }) => ({ page_link, page_name }))
                }
              } else if (typeof submitData.values[key] === 'object') {
                if (['product_type_id'].includes(key)) {
                  data[key] = submitData.values[key].id
                } else if (key === 'request_acc_timezone') {
                  data[key] = submitData.values[key].name
                } else if (['platform1', 'platform2'].includes(key)) {
                  data[key] = submitData.values[key].platform_id
                } else {
                  data[key] = submitData.values[key]
                }
              } else if (key !== "agree_term") {
                data[key] = submitData.values[key]
              }
            })
            request.post('/api/v1/signup_weblist', data).then(res => {
              if (res.data.success) {
                toastr.success("register webpage info success")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
                resolve(true)
              } else {
                toastr.success(res.data.msg)
                resolve(false)
              }
            }, error => {
              toastr.error(error?.response?.data?.msg || error.toString())
              resolve(false)
            })
          })
        }
      }

    })
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      // if (key == 'request_acc_timezone') {
      //   query[key] = formState.changes[key].name
      // } else if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
      //   query[key] = formState.changes[key].id
      // } else {
      //   query[key] = formState.changes[key]
      // }
      if (['domains', 'pages'].includes(key)){
        if(key == 'domains'){
          query[key] = formState.changes[key].map(({ domain, platform }) => ({ domain, platform }))
        } else {
          query[key] = formState.changes[key].map(({ page_link, page_name }) => ({ page_link, page_name }))
        }
      } else if (typeof formState.changes[key] === 'object') {
        if (['product_type_id'].includes(key)) {
          query[key] = formState.changes[key].id
        } else if (key === 'request_acc_timezone') {
          query[key] = formState.changes[key].name
        } else if (['platform1', 'platform2'].includes(key)) {
          query[key] = formState.changes[key].platform_id
        } else {
          query[key] = formState.changes[key]
        }
      } else if (key !== "agree_term") {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/signup_web/${formState.values.sin_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  handleCancel = (ctx, formState) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  render() {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          route="/api/v1/signup_weblist"
          titlePage="SpendAds | Web - Page Info"
          actions={[
            {
              text: 'Register website',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newWebPage(ctx),
              visible: true
            }
          ]}
          title="Web - Page Info"
          fields={fields}
          valueKey="sin_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.sin_id })}
          invisibleCheckbox={true}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/signup_web/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Webpage Info Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(WebPageInfo);