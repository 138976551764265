import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { CustomNumberFormat, ModalForm, ResourceList } from '../../../components';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import moment from 'moment';
import NumberFormat from 'react-number-format';

const getAmount = (item) => {
  if (item.old_balance != null && item.new_balance != null) {
    return item.new_balance - item.old_balance
  }
  return item.amount
}

const getColor = (item) => {
  if (item.old_balance != null && item.new_balance != null) {
    return item.new_balance - item.old_balance > 0 ? 'green' : 'red'
  }
  return 'black'
}

const PAYMENT_METHODS = [
  {value: 'paypal', label: 'Paypal'},
  {value: 'po', label: 'PO'},
  {value: 'bank_vnd', label: 'Bank VND'},
]

const convert_payment_method = (code) => {
  if (!code) {
    return ''
  }

  let label = ''
  label = PAYMENT_METHODS.find(e => e.value == code)?.label

  if (!label) {
    let words = code.split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    label = words.join(' ');
  }
  
  return label
}

const fields = [
  {
    name: 'topup_id',
    label: 'Topup ID',
    renderValue: (item) => `Topup ${item.id}`,
    width: '6rem',
  },
  {
    name: 'payment_date',
    label: 'Transaction Time',
    renderValue: (item) => item.payment_date ? moment(item.payment_date).format('DD/MM/YYYY HH:mm:ss') : '',
    width: '10rem'
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_search,
    sort: true,
    width: '6rem'
  },
  {
    name: 'payment_method',
    label: 'Payment Method',
    renderValue: (item) => convert_payment_method(item.payment_method),
    width: '10rem'
  },
  {
    name: 'sent_to',
    label: 'Send to',
    renderValue: (item) => <div dangerouslySetInnerHTML={{__html: item.sent_to}}/>,
    width: '12rem'
  },
  {
    name: 'apply_rate',
    label: 'Apply Rate',
    width: '8rem',
    renderValue: (item) => item.apply_rate 
  },

  {
    name: 'amount_vnd',
    label: 'Total Money VND',
    width: '10rem',
    renderValue: (item) => {
      if (item.payment_method == 'bank_vnd') {
        return(item.amount_vnd?.toLocaleString({ style: 'currency', currency: '', maximumFractionDigits: 2 }))
      }
    },
    align: 'right',
  },
  {
    name: 'amount',
    label: 'Amount USD',
    width: '8rem',
    align: 'right',
    renderValue: (item) => item.amount?.toLocaleString({ style: 'currency', currency: '', maximumFractionDigits: 2 })
  },
  // {
  //   name: 'new_balance',
  //   label: 'Fund Remaining',
  //   width: '8rem',
  //   renderValue: (item) => item.new_balance?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 })
  // },
]

class TransactionHistoryList extends Component {

  render () {
    const { user } = this.props;
    return (
      <ResourceList 
        route="/api/v1/wallet_histories"
        titlePage="SpendAds | Total Fund"
        actions={[]}
        title="Total Fund"
        fields={fields}
        valueKey="id"
        defaultFilters={{team_id: user ? user.team_id?.team_id : 0, kind: 'income'}}
        // onClickRow={(item) => navigate(`/fud/${item.fud_id}`)}
      />
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TransactionHistoryList)