/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InputField from '../elements/input';
import SelectSearch from '../elements/select-search';
import UploadFile from '../elements/upload-file';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearData,
  createRequestAd,
  getDataRequestCreate,
  getLastRequest,
  getListRequest,
  getTeams
} from '../../_actions_';
import UploadSingleImage from '../elements/upload-file-single';
import { placeRegistration } from '../../_constants_';

export default function CreateAdAccount({ team_id, handleCloseModal }) {
  const INIT_DATA_FORM = {
    bc_name: '',
    bc_id: '',
    time_zone: '',
    number_account: 1,
    team_id: team_id,
    ad_company_name: '',
    ad_company_url: '',
    bussiness_document: '',
    bussiness_license_number: null,
    additional_document: [],
    post_code: '',
    registered_area: 'VN',
    industry: '',
    billing_address: ''
  };

  const [dataForm, setDataForm] = useState({ ...INIT_DATA_FORM, team_id: team_id });
  const [errorForm, setErrorForm] = useState('');
  const [reuseFiles, setReuseFiles] = useState({
    last_bussiness_document: '',
    last_additional_document: []
  });

  const dispatch = useDispatch();
  const dataTeamOption = useSelector((state) => state?.user?.dataTeamOption);
  const dataCreate = useSelector((state) => state?.adAccount?.dataCreate);
  const dataReuse = useSelector((state) => state?.adAccount?.dataLastRequest);
  const dataRequestTiktokAcc = useSelector((state) => state?.adAccount?.dataRequestTiktokAcc);
  const createAdAccountErrors = useSelector((state) => state?.adAccount?.createAdAccountErrors);

  // hooks
  useEffect(() => {
    if (!dataTeamOption) {
      dispatch(getTeams());
    }
    if (!dataCreate) {
      dispatch(getDataRequestCreate());
    }
    if (!dataReuse && dataForm.team_id) {
      dispatch(getLastRequest(dataForm.team_id));
    }
  }, []);

  useEffect(() => {
    if (createAdAccountErrors) {
      setErrorForm(createAdAccountErrors.msg.errors);
      dispatch(clearData('createAdAccountErrors'));
    }
  }, [createAdAccountErrors]);

  useEffect(() => {
    if (dataRequestTiktokAcc) {
      dispatch(
        getListRequest({
          page: 1,
          limit: 50,
          team_id: team_id
        })
      );
      handleCloseModal();
      dispatch(clearData('dataRequestTiktokAcc'));
      dispatch(clearData('dataLastRequest'));
    }
  }, [dataRequestTiktokAcc]);

  const handleGetDataFill = () => {
    let FILL_DATA = {
      bc_name: dataReuse?.bc_name,
      bc_id: dataReuse?.bc_id,
      time_zone: dataReuse?.time_zone,
      number_account: 1,
      team_id: dataReuse?.team_id ?? team_id,
      ad_company_name: dataReuse?.ad_company_name,
      ad_company_url: dataReuse?.ad_company_url,
      bussiness_license_number:
        dataReuse?.bussiness_license_number !== 'null' ? dataReuse?.bussiness_license_number : '',
      post_code: dataReuse?.post_code,
      registered_area: dataReuse?.registered_area,
      industry: dataCreate.industry.filter((item) => item.label === dataReuse?.industry)?.[0]
        ?.value,
      billing_address: dataReuse?.billing_address
    };

    if (dataReuse?.bussiness_document_rel) {
      FILL_DATA = { ...FILL_DATA, last_bussiness_document: dataReuse?.bussiness_document_rel.id };
      setReuseFiles((preState) => ({
        ...preState,
        last_bussiness_document: dataReuse?.bussiness_document_rel
      }));
    }

    if (dataReuse?.additional_documents_rel) {
      FILL_DATA = {
        ...FILL_DATA,
        last_additional_document: dataReuse?.additional_documents_rel.map((item) => item.id)
      };
      setReuseFiles((preState) => ({
        ...preState,
        last_additional_document: dataReuse?.additional_documents_rel
      }));
    }

    return { ...FILL_DATA };
  };

  const handleChangeDataForm = (key, value) => {
    setDataForm({ ...dataForm, [key]: value });
  };

  const handleClose = () => {
    handleCloseModal();
    setDataForm(INIT_DATA_FORM);
    if (dataReuse) dispatch(clearData('dataLastRequest'));
    if (errorForm) setErrorForm('');
  };

  const btnSubmit = useMemo(() => {
    const isValid =
      dataForm.bc_name &&
      dataForm.bc_id &&
      dataForm.time_zone &&
      dataForm.number_account &&
      dataForm.team_id &&
      dataForm.ad_company_name &&
      dataForm.ad_company_url &&
      dataForm.industry &&
      dataForm.billing_address &&
      dataForm.registered_area &&
      dataForm.post_code;

    return (
      <Button
        variant="contained"
        disabled={!isValid}
        onClick={isValid ? () => dispatch(createRequestAd(dataForm)) : null}
        style={{ borderRadius: 4 }}
      >
        Save
      </Button>
    );
  }, [dataForm]);

  const handleOnDeleteFileDefault = useCallback(
    (key, file) => {
      if (key === 'last_bussiness_document') {
        setDataForm((preState) => ({
          ...preState,
          last_bussiness_document: ''
        }));
        setReuseFiles((preState) => ({
          ...preState,
          last_bussiness_document: null
        }));
      }
      if (key === 'last_additional_document') {
        const newValue = reuseFiles.last_additional_document.filter((item) => item.id !== file.id);
        setDataForm((preState) => ({
          ...preState,
          last_additional_document: newValue.map((item) => item.id)
        }));
        setReuseFiles((preState) => ({
          ...preState,
          last_additional_document: newValue
        }));
      }
    },
    [reuseFiles]
  );

  return (
    <Dialog aria-labelledby="modal-form-dialog" open={true} maxWidth={'sm'} fullWidth>
      <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: 2 }}>
        <Box>New Ads Account</Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={() => setDataForm(handleGetDataFill())}>
            Reuse Last Data
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <InputField
            title="Business center name"
            placeholder="Please input the business center name"
            value={dataForm.bc_name}
            onChange={(event) => handleChangeDataForm('bc_name', event.target.value)}
            required
            error={errorForm.bc_name}
          />

          <InputField
            type={'number'}
            title="Business center ID"
            placeholder="Please input the business center ID"
            value={dataForm.bc_id}
            onChange={(event) => handleChangeDataForm('bc_id', event.target.value)}
            required
            error={errorForm.bc_id}
          />

          <SelectSearch
            title={'Time zone'}
            placeholder={'Select a time zone'}
            value={dataForm.time_zone}
            onChange={(newValue) => {
              handleChangeDataForm('time_zone', newValue ? newValue.value : '');
            }}
            options={dataCreate?.time_zone ?? []}
            required
            error={errorForm.time_zone}
          />

          <InputField
            type={'number'}
            title="Postal code"
            placeholder="Please input Postal code"
            value={dataForm.post_code}
            onChange={(event) => handleChangeDataForm('post_code', event.target.value)}
            required
            error={errorForm.post_code}
          />

          <SelectSearch
            title={'Team ID'}
            placeholder={'Team ID'}
            value={dataForm.team_id}
            onChange={(newValue) => {
              handleChangeDataForm('team_id', newValue ? newValue.value : '');
            }}
            options={dataTeamOption ?? []}
            required
            error={errorForm.team_id}
            disabled
          />

          <InputField
            title="Company name"
            placeholder="Please input company name"
            value={dataForm.ad_company_name}
            onChange={(event) => handleChangeDataForm('ad_company_name', event.target.value)}
            required
            error={errorForm.ad_company_name}
          />

          <InputField
            title="Company website"
            placeholder="https://"
            value={dataForm.ad_company_url}
            onChange={(event) => handleChangeDataForm('ad_company_url', event.target.value)}
            required
            error={errorForm.ad_company_url}
          />
          <SelectSearch
            title={'Place of registration'}
            placeholder={'Select a place of registration'}
            value={dataForm.registered_area}
            onChange={(newValue) => {
              handleChangeDataForm('registered_area', newValue ? newValue.value : '');
            }}
            options={placeRegistration}
            required
            error={errorForm.registered_area}
          />

          <SelectSearch
            title={'Industry'}
            placeholder={'Select a industry'}
            value={dataForm.industry}
            onChange={(newValue) => {
              handleChangeDataForm('industry', newValue ? newValue.value : '');
            }}
            options={dataCreate?.industry ?? []}
            required
            error={errorForm.industry}
          />

          <InputField
            title="Advertiser billing address"
            placeholder="Enter your stress address"
            value={dataForm.billing_address}
            onChange={(event) => handleChangeDataForm('billing_address', event.target.value)}
            required
            error={errorForm.billing_address}
          />

          <UploadSingleImage
            title="Business document"
            description="Business license refers to a certificate that proves the legal existence of your company. Upload a PNG, JPG, JPEG, or PDF or file."
            onChange={(files) => handleChangeDataForm('bussiness_document', files)}
            defaultFile={reuseFiles.last_bussiness_document}
            onDeleteFileDefault={(file) =>
              handleOnDeleteFileDefault('last_bussiness_document', file)
            }
          />

          <InputField
            type={'number'}
            title="Business license number (Optional)"
            placeholder="Please input the certificate no."
            value={dataForm.bussiness_license_number}
            onChange={(event) =>
              handleChangeDataForm('bussiness_license_number', event.target.value)
            }
            error={errorForm.bussiness_license_number}
          />

          <UploadFile
            title="Additional document"
            description="Consult with the sales team and provide an advertiser invoice as a supporting document."
            onChange={(files) => handleChangeDataForm('additional_document', files)}
            defaultFiles={reuseFiles.last_additional_document}
            onDeleteFileDefault={(file) =>
              handleOnDeleteFileDefault('last_additional_document', file)
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          className="mr-2"
          color="secondary"
          variant="outlined"
          onClick={handleClose}
          style={{ borderRadius: 4 }}
        >
          Close
        </Button>
        {btnSubmit}
      </DialogActions>
    </Dialog>
  );
}
